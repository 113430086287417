import HelpIcon from '@mui/icons-material/Help';
import { Divider, IconButton, Stack, Box, Tooltip, Typography } from "@mui/material"

interface FormHeaderProps {
  title: string
  subtitle: string
  hasHelper?: boolean
}

export const FormHeader = ({ title, subtitle, hasHelper }: FormHeaderProps) => {
  return (
    <>
      {hasHelper ? (
        <Stack direction='row' justifyContent="space-between" alignItems="center" spacing='2rem' sx={{ pt: 3 }}>
          <Typography sx={{ fontSize: '1.9rem' }} variant='h6' textAlign="left">
            {title}
          </Typography>
          <Tooltip title={subtitle}>
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Box sx={{ pt: 3 }}>
          <Typography textAlign='left' variant="h5">
            {title}
          </Typography>
          <Typography sx={{ fontSize: '0.8rem', marginTop: '0' }} variant='subtitle1' textAlign="left">
            {subtitle}
          </Typography>
        </Box>
      )}
      <Divider sx={{ mt: 1, mb: 2, borderColor: 'rgba(0, 0, 0, 0.54)' }} />
    </>
  )
}
