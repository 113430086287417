export const getPathByRole = (role: string) => {
  switch (role) {
    case "designer":
      return '/designer'
    case "manufacturer":
      return '/manufacturer'
    case "admin":
    default:
      return '/broker'
  }
}
