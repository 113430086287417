import { Box, CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getSecondaryStructure } from '../../../api/SecondaryStructureQueries';
import { useAuth0 } from '@auth0/auth0-react';
import SVG from 'react-inlinesvg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    minHeight: 400,
    maxHeight: '100%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: '16px'
};

interface SequenceViewerProps {
    onClose: () => void;
    open: boolean;
    sequence: string;
}

function SecondaryStructureViewer({ onClose, sequence, open }: SequenceViewerProps) {
    const { getAccessTokenSilently } = useAuth0();

    const [loading, setLoading] = useState(false);
    const [svg, setSvg] = useState("")
    const [dotBracket, setDotBracket] = useState("")
    const [error, setError] = useState("")

    const handleClose = () => {
        onClose();
    };

    const getSecondaryStructureForSequence = async () => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        const response = await getSecondaryStructure(accessToken, sequence)
        if (response) {
            if (response.error) {
                setError(response.error)
            } else {
                setSvg(response.svg)
                setDotBracket(response.dot_bracket)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getSecondaryStructureForSequence()
    }, [sequence])

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={{ ...style }}>
                {loading ?
                    <Stack direction={'column'} justifyContent='center' alignItems='center' sx={{ height: '400px' }}>
                        <CircularProgress />
                    </Stack>
                    :
                    <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                        <Typography
                            variant='h6' sx={{ pt: 3 }}>Secondary Structure</Typography>
                        {error ?
                            <Typography variant='body1'>{error}</Typography> :
                            <>
                                <Typography
                                    variant='body1'
                                    textAlign='center'
                                    sx={{ px: 2 }}
                                >
                                    {dotBracket}
                                </Typography>
                                <SVG
                                    src={svg}
                                    width="600px"
                                    height="auto"
                                    title="Secondary Structure"
                                />
                            </>
                        }
                    </Stack>
                }

            </Box>
        </Modal>
    );
}
export default SecondaryStructureViewer;
