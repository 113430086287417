import { Box, Typography } from "@mui/material"

const EmptyWindow = () => {
	return (
		<Box sx={{ width: '100%' }}>
			<Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>
				Start by creating or selecting a construct
			</Typography>
		</Box>
	)
}

export default EmptyWindow
