
const dna_bases = ['A', 'T', 'C', 'G'];
const aa_chars = ['A', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'Y', 'X', 'B', 'Z', 'J', '*'];


const hasInvalidCharacters = (sequence: string, seqType: 'dna' | 'aa'): string | undefined => {

        if (seqType === 'dna') {
            const search_bases = sequence.split("").filter((char: string) => !dna_bases.includes(char.toUpperCase()));
            if (search_bases.length > 0) {
                return search_bases.join(", ") + " are not valid DNA bases."
            }
        }
        else if (seqType === 'aa') {
            const search_aa = sequence.split("").filter((char: string) => !aa_chars.includes(char.toUpperCase()));
            if (search_aa.length > 0) {
                return search_aa.join(", ") + " are not valid Amino Acids."
            }
        }

}

export default hasInvalidCharacters
