import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Box, Button, IconButton, Modal, Stack, SxProps, Theme, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { FormInputText, FormInputTextType } from "../../../components/FormComponents";
import { Run } from "../../../models/Run";

interface RejectModalInterface {
  open: boolean,
  onClose: () => void
  sx: SxProps<Theme>
  submit: (justification: string) => Promise<void>
}

const defaultJustification = "Your construct was reviewed by a R3 administrator and rejected."
const schema = z.object({
  justification: z.string()
    .min(1, { message: "Justification is required" })
})

type FormFields = z.infer<typeof schema>;

export const RejectModal = ({ open, onClose, sx, submit }: RejectModalInterface) => {
  const { control, handleSubmit } = useForm<FormFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {
      justification: defaultJustification
    },
  });

  const submitForm = async (data: FormFields) => {
    await submit(data.justification)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={sx}>
        <IconButton sx={{ position: 'absolute', top: '30px', right: '30px' }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
        <Typography
          color="text.subtitle2"
          gutterBottom
        >
          Do you want to provide a justification for your construct rejection?
        </Typography>

        <Box sx={{
          pt: 2,
          maxWidth: 750
        }}>
          <form noValidate onSubmit={handleSubmit((data: FormFields) => submitForm(data))}>
            <FormInputText
              name="justification"
              size="small"
              control={control}
              label="Justification"
              required={true}
              type={FormInputTextType.Text}
              multiline
            />
            <Stack direction="row-reverse">
              <Button
                sx={{ mt: 2 }}
                variant='contained'
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
