import {
  Stack,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import RunCard from "./RunCard";
import { Run } from "../../../models/Run";
import { useEffect, useState } from "react";
import { FilterBar } from "./FilterBar";


function BrokerDashboardPage({runProps, loadingRuns}: {runProps: Run[], loadingRuns: boolean}) {
  const [runs, setRuns] = useState<Run[]>(runProps);
  const [filteredRuns, setFilteredRuns] = useState<Run[]>(runProps);
  const [filter, setFilter] = useState<string>("All");

  useEffect(() => {
    if (filter !== "All") {
      const filterRuns = runs.filter((run) => run.status === filter);
      setFilteredRuns(filterRuns)
      }
    else{
      setFilteredRuns(runs)
    }
  }, [filter, runs])


  return (
    <Stack direction="column" alignItems="center">
      <FilterBar filterStatus={(e:any) => setFilter(e.target.value)} />
      <Grid sx={{ p: 5, pl:20, pr:20 }} container direction="row" alignItems="center">
        {loadingRuns
          ? Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={4} key={index}>
                <Skeleton sx={{m:2}} variant="rectangular" height={400} />
              </Grid>
            ))
          : filteredRuns.length > 0 && filteredRuns.map((run: Run, index:number) => {
              return (
                <Grid item sm={6} md={4} key={index}>
                  <RunCard currentRun={run} runs={runs} setRuns={setRuns} />
                </Grid>
              );
            })}
      </Grid>
      {filteredRuns.length === 0 && <Typography sx={{alignSelf: 'center'}}variant="h5">No runs found</Typography>}
    </Stack>
  );
}

export default BrokerDashboardPage;
