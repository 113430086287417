import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useMemo, useState } from "react";
import { getAvailableTemplatesForUser } from "../../../api/TemplateQueries";
import { FormInputSelect } from "../../../components/FormComponents/FormInputSelect";
import { Template } from "../../../models/Template";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Process, ProcessOutput } from "../../../models/Process";
import { Button, Card, CardActions, CardContent, Grid, IconButton, Popover, Stack, Typography } from "@mui/material";
import ReplayIcon from '@mui/icons-material/Replay';
import {
  FormInputText,
  FormInputTextType,
} from "../../../components/FormComponents/FormInputText";
import { LoadingButton } from "@mui/lab";
import { createProcess, getProcess, updateProcess } from "../../../api/ProcessQueries";
import { showAlert } from "../../../components/Alert/alertSlice";
import store from "../../../store"
import { useNavigate, useParams } from "react-router-dom";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";
import TemplateViewer from "../../../components/TemplateViewer/TemplateViewer";

function EditProcessPage() {
  const { id } = useParams()
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate()
  const [loadingProcess, setLoadingProcess] = useState<boolean>(true)
  const [templates, setTemplates] = useState<Template[] | undefined>(undefined)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>(undefined)
  const [creatingProcess, setCreatingProcess] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const isCreatePage = useMemo(() => id === "new", [id])

  const processOutputs = useMemo(() => {
    return Object.values(ProcessOutput)
      .map((value: string) => ({
        value: value,
        label: getConstantDisplayName(value)
      }))
  }, [])

  const schema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    output: z.string().min(1, { message: "Output is required" }),
    description: z.string().min(1, { message: "Description is required" })
  });

  const getAllDnaTemplates = async () => {
    const accessToken = await getAccessTokenSilently();
    const templates: Template[] = await getAvailableTemplatesForUser(accessToken);
    if (templates) {
      setTemplates(templates)
    }
    return templates
  };

  const getProcessById = async (id: string) => {
    const accessToken = await getAccessTokenSilently();
    const process = await getProcess(id, accessToken);
    if (process) {
      setValue("name", process.name)
      setValue("output", process.output)
      if (process.description) setValue("description", process.description)
      if (process.template) setSelectedTemplate(process.template)
    }
    setLoadingProcess(false)
  };

  useEffect(() => {
    setLoadingProcess(true)
    getAllDnaTemplates()
    if (!isCreatePage && id) {
      getProcessById(id)
    } else {
      setLoadingProcess(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  type FormFields = z.infer<typeof schema>;

  const { control, setValue, handleSubmit } = useForm<FormFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
    defaultValues: {},
  });

  const processRequest = async (data: FormFields) => {
    setCreatingProcess(true);
    const accessToken = await getAccessTokenSilently();

    if (selectedTemplate) {
      const createProcessDTO: Process = {
        name: data.name,
        output: data.output as ProcessOutput,
        description: data.description,
        templateId: selectedTemplate.id!
      };

      if (isCreatePage) {
        const result = await createProcess(accessToken, createProcessDTO)
        if (result) {
          store.dispatch(showAlert({ message: 'Process created successfully!', severity: 'success' }))
          navigate("/manufacturer")
        }
      } else {
        if (id) {
          const result = await updateProcess(accessToken, createProcessDTO, id)
          if (result) {
            store.dispatch(showAlert({ message: 'Process updated successfully!', severity: 'success' }))
            navigate("/manufacturer")
          }
        }
      }
    }
  };

  const onError = (errors: any) => {
    console.error(errors);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>
          {isCreatePage ? "Create a Process" : "Edit Process"}
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          {isCreatePage ?
            "Fill in the parameters for your process and create it." :
            "Edit your process and update it"
          }
        </Typography>
      </Stack >
      {!loadingProcess && (
        <form
          noValidate
          onSubmit={handleSubmit(processRequest, onError)}
        >
          <Grid container direction='row'>
            <Grid item md={4} />
            <Grid item md={4}>
              <Stack direction="column" spacing={2} sx={{ p: 2 }}>
                <FormInputText
                  name="name"
                  size="small"
                  control={control}
                  label="Process name"
                  required={true}
                  type={FormInputTextType.Text}
                />
                <FormInputText
                  name="description"
                  control={control}
                  multiline={true}
                  rows={6}
                  placeholder="The description helps designers understand what your process entails"
                  label="Process description"
                  required={true}
                  type={FormInputTextType.Text}
                />
                <FormInputSelect
                  name="output"
                  size="small"
                  control={control}
                  label="Process output"
                  required={true}
                  options={processOutputs}
                />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1">Select a template:</Typography>
                  <IconButton
                    size="small"
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={() => getAllDnaTemplates()}
                  >
                    <ReplayIcon />
                  </IconButton>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1, fontSize: '10px' }}>Click to update template list</Typography>
                  </Popover>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={4} />
          </Grid>

          {(templates && isCreatePage) && (
            <Stack direction="row" justifyContent="space-evenly" alignItems="end" sx={{ padding: '0 16px' }}>
              <Typography sx={{ fontSize: '0.8rem' }} variant="body1">If you don't find any template that matches you process, try creating a custom template.</Typography>
              <Button onClick={() => window.open('/templates?create=true', '_blank')} sx={{ width: '300px' }} variant="contained">Create custom template</Button>
            </Stack>
          )}

          <Stack direction="column" >
            <Grid container columnSpacing={{ md: 3 }} rowSpacing={{ md: 2 }} sx={{ p: 2 }} justifyContent='center'>
              {templates && templates.map((item: Template, index: number) => {
                return (
                  <Grid item xs={3} key={index}>
                    <Card>
                      <CardContent>
                        <TemplateViewer template={item} sx={{ height: '400px' }} />
                      </CardContent>
                      <CardActions>
                        <Stack direction="row" spacing={2} justifyContent='space-evenly' sx={{ width: '100%', p: 2 }}>
                          <Button
                            variant={selectedTemplate?.id !== item.id ? "outlined" : "contained"}
                            sx={{ mt: 2 }}
                            onClick={() => setSelectedTemplate(item)}>
                            {selectedTemplate?.id !== item.id ? "Select Template" : "Template Selected"}
                          </Button>
                        </Stack>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>

            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: '100%' }}
            >
              <LoadingButton
                loading={creatingProcess}
                sx={{ maxWidth: '300px', mt: 5, mb: 5 }}
                variant="contained"
                disabled={!selectedTemplate}
                type="submit"
              >
                {isCreatePage ? "Create" : "Update"}
              </LoadingButton>
            </Stack>
          </Stack>
        </form>

      )
      }
    </>
  );
}

export default EditProcessPage;

