import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AlertState {
  message: string;
  severity: "success" | "error" | "info";
  open: boolean;
  duration: number;
}

type ShowAlertAction = PayloadAction<{
  message: string;
  severity: AlertState["severity"];
  duration?: number | undefined
}>;

const initialState: AlertState = {
  message: "",
  severity: "info",
  open: false,
  duration: 3000
};
const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action: ShowAlertAction) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.duration = action.payload.duration ?? 3000
      state.open = true
    },
    closeAlert: (state) => {
      state.open = false;
    },
  },
});

export const { showAlert, closeAlert } = alertSlice.actions;
export const alertState = (state: { alert: AlertState }) => state.alert;
export default alertSlice.reducer;
