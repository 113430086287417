import { Stack, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import './ProductComponents'

interface ProductComponentProps {
  name: string,
  isEmpty: boolean,
  componentColor: string | undefined
}


const ProductComponent = ({ name, isEmpty, componentColor }: ProductComponentProps) => {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Stack
            direction="row"
            spacing={2}
            className={isEmpty ? "component empty" : "component"}
            sx={{width: '100%'}}
            onClick={() => {
                searchParams.set('component', name);
                setSearchParams(searchParams);
            }}
        >
            <div style={{ margin: "5px", backgroundColor: componentColor, minWidth: "10px", maxHeight: "10px" }}></div>
            <Typography variant="body2">
                {name}
            </Typography>
        </Stack>
    )
}

export default ProductComponent
