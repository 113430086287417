import { Box, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Product } from "../../models/Product"
import createSequenceAndAnnotations from "../../utils/createSequenceAndAnnotations"

interface FooterProps {
  product?: Product
}
export const Footer = ({ product }: FooterProps) => {
  const [basePairs, setBasePairs] = useState<number>(0)
  const [gcContent, setGcContent] = useState<number>(0)

  useEffect(() => {
    if (product) {
      
      const { sequence, annotations } = product.components.length ===0 ? { sequence: product.fullSequence, annotations: [] } : createSequenceAndAnnotations(product.components)
      
      if (sequence && sequence.length > 0) {
        const filterSeq = sequence.split("").filter((bp: string) => bp.toUpperCase() === "C" || bp.toUpperCase() === "G")
        const calculateGcContent = Math.round(filterSeq.length / sequence.length * 100)
        setGcContent(calculateGcContent)
      }

      const basePairs = !sequence ? 0 : sequence.length
      setBasePairs(basePairs)
    }

  }, [product])

    return (
        <div style={{ marginTop: '35px', height: '25px' }}>
            <Box
                sx={{
                    bgcolor: 'secondary.light',
                    fontWeight: 'bold',
                    height: 'inherit',
                    zIndex: 99,
                    bottom: 0
                }}
            >
                <Stack direction="row" sx={{ float: 'right', mr: 2 }} spacing={2}>
                    <Typography variant="body2">{basePairs} bp</Typography>
                    <Typography variant="body2">{gcContent}% GC content</Typography>
                </Stack>
            </Box>
        </div>
    )
}
