import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
    Grid,
} from '@mui/material';
import './AllManufacturersPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Manufacturer } from "../../../models/Manufacturer";
import { getManufacturers } from "../../../api/ManufacturerQueries";
import { useNavigate } from "react-router-dom";


function AllManufacturersPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()


    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])

    const getAllManufacturers = async () => {
        const accessToken = await getAccessTokenSilently()
        const manufacturers = await getManufacturers(accessToken)
        if (manufacturers) {
            setManufacturers(manufacturers)
        }
    }

    useEffect(() => {
        getAllManufacturers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Grid
                sx={{ p: 5 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign='center' variant="h5">
                        All Manufacturers
                    </Typography>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Stack direction='column' sx={{ p: 5 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Institution</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Phone nummber</TableCell>
                                <TableCell align="center">Address</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {manufacturers.map((manufacturer, index) => (
                                <TableRow key={index} className="manufacturer-row" >
                                    <TableCell component="th" scope="row">
                                        {manufacturer.institutionName}
                                    </TableCell>
                                    <TableCell align="center">{manufacturer.email}</TableCell>
                                    <TableCell align="center">{manufacturer.phoneNumber}</TableCell>
                                    <TableCell align="center">{manufacturer.address}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}

export default AllManufacturersPage
