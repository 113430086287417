import { showAlert } from "../components/Alert/alertSlice";
import { SpliceSitePredicitonResult } from "../models/SpliceSitePrediction";
import store from '../store/';

export const predictSpliceSitePredictions = async (
    accessToken: string,
    input: {
        sequence: string,
        donorReliability: number,
        acceptorReliability: number,
        donorSize: number,
        acceptorSize: number
    }
): Promise<SpliceSitePredicitonResult | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/splicing/analyze`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(input)
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while running the prediction', severity: 'error' }))
    }
}
