import {
  Stack,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import ProcessCard from "./ProcessCard";
import { useState } from "react";
import { Process } from "../../../models/Process";



function ProcessDash({ processesProps, loadingProcesses }: {processesProps: Process[], loadingProcesses: boolean}) {
  const [processes, setProcesses] = useState<Process[]>(processesProps);

  return (
    <Stack direction="column" alignItems="center">
      <Grid sx={{ p: 5 }} container direction="row" alignItems="center">
        {loadingProcesses
          ? Array.from(new Array(2)).map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton sx={{ m: 2 }} variant="rectangular" height={200} />
            </Grid>
          ))
          :
          processes.length ? processes.map((process: Process, index: number) => {
            return (
              <Grid item xs={6} key={index}>
                <ProcessCard
                  currentProcess={process}
                  processes={processes}
                  setProcesses={setProcesses}
                  template={process.template}
                />
              </Grid>
            );

          })
            :
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="body1" align="center">
                No processes found. Create a process using the button on the bottom right of the page.
              </Typography>
            </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default ProcessDash;
