import { createSlice } from "@reduxjs/toolkit";
import UserPreferences from "../../models/UserPreferences";

/*
 * This slice will be responsible for store some preferences
 * set by the navbar, which includes role context. 
 */

const initialState: UserPreferences = {
  selectedRole: null,
  roles: []
}
const navBarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    }
  }
})

export const { setSelectedRole, setRoles } = navBarSlice.actions
export const selectRoles = (state: { navBar: UserPreferences }) => state.navBar.roles
export const selectRole = (state: { navBar: UserPreferences }) => state.navBar.selectedRole

export default navBarSlice.reducer
