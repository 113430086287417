import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SeqViz from "seqviz";
import { Highlight, Product } from "../../models/Product";
import createSequenceAndAnnotations from "../../utils/createSequenceAndAnnotations";

interface SequenceViewerProps {
  product?: Product;
  highlights: Array<Highlight> | null;
}

function SequenceViewer({ product, highlights }: SequenceViewerProps) {
  const [selection, setSelection] = useState<Highlight | undefined>(undefined);
  const sequenceAndAnnotations = useMemo(() => {
    if (product?.fullSequence && product.fullSequence.length > 0)
      return {
        sequence: product.fullSequence,
        annotations: product.annotations,
      };

    if (product) return createSequenceAndAnnotations(product?.components);

    return { sequence: "", annotations: [] };
  }, [product]);

  const [highlightRegion, setHighlights] = useState<Array<Highlight> | null>(
    null
  );

  // Clears the SeqViz selection and highlight if the user clicks outside of the highlight range
  const clearHighlightSelect = (data: any) => {
    if (highlights) {
      const highlightData = highlights[0];

      if (
        !(data.start > highlightData.start && data.start < highlightData.end)
      ) {
        setHighlights(null);
        setSelection(undefined);
      }
    }
  };


  useEffect(() => {
    setHighlights(highlights);
    // Add a selection over the highlight region if a highlight exists
    setSelection(highlights ? highlights[0] : undefined);
  }, [highlights]);

  return (
    <>
      {!sequenceAndAnnotations?.sequence && (
        <Typography sx={{ p: 2 }} variant="body2" textAlign='center'>
          Fill in at least one parameter to see the construct
        </Typography>
      )}
      {sequenceAndAnnotations?.sequence !== "" && (
        <SeqViz
          seqType="dna"
          name={product?.name}
          viewer="linear"
          style={{ height: "100%" }}
          seq={sequenceAndAnnotations?.sequence}
          annotations={sequenceAndAnnotations?.annotations}
          highlights={highlightRegion ?? undefined}
          onSelection={(data: any) => clearHighlightSelect(data)}
          selection={selection}
        />
      )}
    </>
  );
}

export default SequenceViewer;
