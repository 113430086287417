import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../models/Product";
import { RootState } from "../../store";

/*
 * This slice will be responsible for store the product
 * that will be rendered and manipulated by the viewer. 
 */


interface ProductState {
  product: Product | undefined
}

const initialState: ProductState = {
  product: undefined
}

const productsSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProduct: (state, action) => {
      state.product = action.payload
    },
  }
})

export const { setProduct } = productsSlice.actions
export const selectProduct = (state: RootState) => state.productPage.product 

export default productsSlice.reducer
