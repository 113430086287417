import React, { useState } from "react";
import {
    Button,
    MobileStepper,
    Paper,
    Stack,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";


interface CarouselProps {
    components: Array<JSX.Element>
}

function Carousel({ components }: CarouselProps) {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = components.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Stack direction="column" sx={{ height: '100%', pb: 5, width: "50%" }}>
            <Paper sx={{ height: 'inherit' }}>
                {components[activeStep]}
            </Paper>
            <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        Next
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                        Back
                    </Button>
                }
            />
        </Stack>
    )
}

export default Carousel
