import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { RunStatus } from "../../../models/Run";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";

export interface FilterBarProps {
  filterStatus: (event: SelectChangeEvent<string>) => void;
}

export const FilterBar = ({ filterStatus }: FilterBarProps) => {
  return (
    <FormControl sx={{ m: 2 }}>
      <InputLabel id="run-filter-label">Filter Run By Status</InputLabel>
      <Select
        labelId="run-filter-label"
        label="Filter Run By Status"
        sx={{ width: "200px" }}
        defaultValue="All"
        onChange={filterStatus}
      >
        <MenuItem value="All">All</MenuItem>
        {Object.keys(RunStatus).map((status) => {
          return (
            <MenuItem key={status} value={status}>
              {getConstantDisplayName(status)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
