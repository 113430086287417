import { Box, Stack } from "@mui/material"
import { useRoutes, type RouteObject } from "react-router-dom"
import Toolbar from "../Toolbar/Toolbar"
import "./Window.css"

/*
 *  Window is an abstract parent component for managing Toolbar, Canvas and
 *  Interpretation Panel component.
 */

export interface WindowProps {
  routes: RouteObject[]
}

export default function Window({ routes }: WindowProps) {
  const elementByRoute = useRoutes(routes) as React.ReactElement | null
  return (
    <Stack direction="row" className="window">
      <Toolbar />
      <Box className="box-overflow">
        {elementByRoute}
      </Box>

    </Stack>
  )
}
