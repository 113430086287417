import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Auth0ProviderWithRedirectCallback from './components/Auth0/Auth0ProviderWithRedirectCallback';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store'
import "./index.css"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={process.env.REACT_APP_FRONTEND_URI}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        useRefreshTokens={true}
      >
        <Provider store={store}>
        <App />
        </Provider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);
