import React, { useState, useEffect, useMemo } from "react";
import { CodonSelectionCriteriaParameter, FrameshiftParameter, GcContentParameter, KentParameters, NucleotideRepeatsParameter, SequenceParameter, UTRParameter } from '../../pages/DesignerPages/OptimizationPage/OptimizationParametersComponent'
import { IconButton, TextField, Box, Button, Select, MenuItem, FormControl, Stack, Slider, Paper, Chip, Popover, Typography, InputLabel } from '@mui/material';
import { Add, Delete } from '@mui/icons-material/';
import "./KentParamatersForm.css"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAuth0 } from "@auth0/auth0-react";
import { getCodonSelectionInputs } from "../../api/OptimizeQueries";

export interface CodonSelectionInputs {
    // 9606 : {
    //     HEK293 : [ Codon Usage Kazusa ],
    // }
    [organism: string]: { [cellType: string]: string[] }
}

const parameterLabelNames = {
    CodonSelectionCriteria: "Optimize codons",
    GcContent: "GC Content",
    NucleotideRepeats: "Nucleotide repeats",
    Sequence: "Sequence",
    Frameshift: "Frameshift"
}


type KentParametersFormProps = {
    readOnly: boolean
    kentParameters: KentParameters
    updateKentParameters: (parameters: KentParameters) => void
};

const KentParametersForm = (props: KentParametersFormProps) => {
    const { getAccessTokenSilently } = useAuth0();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [codonSelectionInputs, setCodonSelectionInputs] = useState<CodonSelectionInputs>();


    const getCodonSelectionOptions = async () => {
        const accessToken = await getAccessTokenSilently()
        const codonSelectionInputs: CodonSelectionInputs | undefined = await getCodonSelectionInputs(accessToken)
        if (codonSelectionInputs) {
            setCodonSelectionInputs(codonSelectionInputs)
        }
    }

    useEffect(() => {
        getCodonSelectionOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNucleotideRepeatsRows = () => {
        return props.kentParameters
            .filter((parameter): parameter is NucleotideRepeatsParameter => {
                return parameter.name === "NucleotideRepeats";
            })
            .map((parameter: any, index: number) => {
                return (
                    <Stack direction="row" spacing={3} className="form-row" alignItems="center">
                        {getHelpPopover("nucleotidePopOver", "Sets target values for single nucleotide repeats, such as 'minimise repeats of 5 or more As'")}
                        <div>
                            Optimize for the number of nucleotide repeats:
                        </div>
                        <FormControl size="small" disabled={props.readOnly}>
                            <Select
                                data-cy={`nucleotideRepeats-${index}`}
                                value={getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id) === 'min' || getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id) === 'max' ? getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id) : 'target'}
                                onChange={(e) => setParameterWithId("NucleotideRepeats", parameter.id, "target", e.target.value)}
                            >
                                <MenuItem data-cy={`select-option-min-${index}`} value={"min"}>Minimum</MenuItem>
                                <MenuItem data-cy={`select-option-max-${index}`} value={"max"}>Maximum</MenuItem>
                                <MenuItem data-cy={`select-option-target-${index}`} value={"target"}>Target</MenuItem>
                            </Select>
                        </FormControl>
                        {getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id) !== 'min' && getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id) !== 'max' &&
                            <TextField
                                data-cy={`consecutive-nucleotides-target-input-${index}`}
                                disabled={props.readOnly}
                                value={getFieldValueByNameAndId("NucleotideRepeats", "target", parameter.id)}
                                onChange={(e) => setParameterWithId("NucleotideRepeats", parameter.id, "target", e.target.value)}
                                label="Target"
                                size="small"
                                type="number"
                                variant="outlined"
                                autoComplete="off"
                                inputProps={{
                                    step: 1
                                }}
                                className="form-input" />
                        }

                        <TextField
                            disabled={props.readOnly}
                            value={getFieldValueByNameAndId("NucleotideRepeats", "repeats", parameter.id)}
                            onChange={(e) => setParameterWithId("NucleotideRepeats", parameter.id, "repeats", parseInt(e.target.value))}
                            label="Num of repeats"
                            size="small"
                            type="number"
                            variant="outlined"
                            autoComplete="off"
                            className="form-input" />
                        <FormControl size="small" disabled={props.readOnly}>
                            <Select
                                data-cy={`nucleotideBase-${index}`}
                                value={getFieldValueByNameAndId("NucleotideRepeats", "nucleotide", parameter.id)}
                                onChange={(e) => setParameterWithId("NucleotideRepeats", parameter.id, "nucleotide", e.target.value)}
                            >
                                <MenuItem data-cy={`select-option-a-${index}`} value={"A"}>A</MenuItem>
                                <MenuItem data-cy={`select-option-t-${index}`} value={"T"}>T</MenuItem>
                                <MenuItem data-cy={`select-option-c-${index}`} value={"C"}>C</MenuItem>
                                <MenuItem data-cy={`select-option-g-${index}`} value={"G"}>G</MenuItem>
                                <MenuItem data-cy={`select-option-g-${index}`} value={"U"}>U</MenuItem>
                            </Select>
                        </FormControl>

                        <IconButton onClick={() => removeParameterByNameAndId("NucleotideRepeats", parameter.id)} disabled={props.readOnly}>
                            <Delete />
                        </IconButton>
                    </Stack>
                )
            })
    }

    const getSequenceRows = () => {
        return props.kentParameters
            .filter((parameter): parameter is SequenceParameter => {
                return parameter.name === "Sequence";
            })
            .map((parameter: any, index: number) => {
                return (
                    <Stack direction="row" spacing={3} className="form-row" alignItems="center" key={parameter.id}>
                        {getHelpPopover("avoidEnforce#" + parameter.id, "Defines subsequences to avoid or enforce, such as 'avoid the subsequence GGATCC'")}
                        <div>
                            Optimize to enforce/avoid the following subsequence:
                        </div>
                        <TextField
                            disabled={props.readOnly}
                            value={getFieldValueByNameAndId("Sequence", "codon_subseq", parameter.id)}
                            onChange={(e) => setParameterWithId("Sequence", parameter.id, "codon_subseq", e.target.value)}
                            label="Sequence"
                            size="small"
                            type="text"
                            variant="outlined"
                            autoComplete="off"
                            className="form-input" />
                        <FormControl size="small" disabled={props.readOnly}>
                            <Select
                                data-cy={`sequenceSelect-${index}`}
                                value={getFieldValueByNameAndId("Sequence", "mode", parameter.id)}
                                onChange={(e) => { setParameterWithId("Sequence", parameter.id, "mode", e.target.value) }
                                }
                            >
                                <MenuItem data-cy={`select-option-forbidden-${index}`} value={'forbidden'}>Forbidden</MenuItem>
                                <MenuItem data-cy={`select-option-enforce-${index}`} value={'enforce'}>Enforce</MenuItem>

                            </Select>
                        </FormControl>
                        <IconButton onClick={() => removeParameterByNameAndId('Sequence', parameter.id)} disabled={props.readOnly}>
                            <Delete />
                        </IconButton>
                    </Stack>
                )
            })
    }

    const removeParameterByNameAndId = (parameterName: "Sequence" | "NucleotideRepeats", id: number) => {
        const newKentParameters = [...props.kentParameters]
        const index = newKentParameters.findIndex(parameter => parameter.name === parameterName && parameter.id === id)
        if (index !== -1) {
            newKentParameters.splice(index, 1);
            props.updateKentParameters(newKentParameters)
        }
    }

    const removeParameterByName = (parameterName: string) => {
        const newKentParameters = props.kentParameters.filter(parameter => parameter.name !== parameterName);
        props.updateKentParameters(newKentParameters)
    }

    const addSequenceParameter = () => {
        const newKentParameters = [...props.kentParameters]
        const allSequenceParameters = newKentParameters.filter(param => param.name === 'Sequence')
        const newId = allSequenceParameters.length + 1
        newKentParameters.push({
            name: "Sequence",
            weight: 0.5,
            codon_subseq: "",
            mode: "forbidden",
            id: newId
        })
        props.updateKentParameters(newKentParameters)
    }

    const addCodonSelectionCriteriaParameter = () => {
        const newKentParameters = [...props.kentParameters]
        newKentParameters.push({
            name: "CodonSelectionCriteria",
            weight: 0.5,
            organism: '',
            cell_type: '',
            parset_name: '',
            mode: "min"
        })
        props.updateKentParameters(newKentParameters)
    }

    const addNucleotideRepeatsParameter = () => {
        const newKentParameters = [...props.kentParameters]
        const allRepeatsParameters = newKentParameters.filter(param => param.name === 'NucleotideRepeats')
        const newId = allRepeatsParameters.length + 1
        newKentParameters.push({
            name: "NucleotideRepeats",
            weight: 0.5,
            target: "max",
            nucleotide: "T",
            repeats: 2,
            id: newId
        })
        props.updateKentParameters(newKentParameters)
    }

    const addGcContentParameter = () => {
        const newKentParameters = [...props.kentParameters]
        newKentParameters.push({
            name: "GcContent",
            weight: 0.5,
            target: "min",

        })
        props.updateKentParameters(newKentParameters)
    }

    const addFrameshiftParameter = () => {
        const newKentParameters = [...props.kentParameters]
        newKentParameters.push({
            name: "Frameshift",
            weight: 0.5,
            target: "min",

        })
        props.updateKentParameters(newKentParameters)
    }

    const parameterIsAlreadyAdded = (parameterName: string) => {
        const parameter = props.kentParameters.find(parameter => parameter.name === parameterName);
        return !!parameter
    }

    const setParameterWithoutId = (parameterName: string, fieldName: string, fieldValue: string | number) => {
        const newParameters = [...props.kentParameters]
        const parameter: any = newParameters.find(parameter => parameter.name === parameterName);
        if (parameter) {
            parameter[fieldName] = fieldValue
            
            // Special case, clean up the other fields if the dependency changes
            if (parameterName === "CodonSelectionCriteria" && fieldName === "organism") {
                parameter.cell_type = ''
                parameter.parset_name = ''
            }
            if (parameterName === "CodonSelectionCriteria" && fieldName === "cell_type") {
                parameter.parset_name = ''
            }

        } else {
            const newParameter: any = { "name": parameterName }
            newParameter[fieldName] = fieldValue
            newParameters.push(newParameter)
        }

        props.updateKentParameters(newParameters)
    }

    const setParameterWithId = (parameterName: 'Sequence' | 'NucleotideRepeats', id: number, fieldName: string, fieldValue: string | number) => {
        const newParameters = [...props.kentParameters]
        const parameter: any = props.kentParameters.find(parameter => parameter.name === parameterName && parameter.id === id);
        if (parameter) {
            parameter[fieldName] = fieldValue
        } else {
            const newParameter: any = { "name": parameterName, "id": id }
            newParameter[fieldName] = fieldValue
            newParameters.push(newParameter)
        }
        props.updateKentParameters(newParameters)
    }

    const getFieldValueByNameAndId = (paramName: 'Sequence' | 'NucleotideRepeats', fieldName: string, id: number) => {
        const parameter: any = props.kentParameters.find(parameter => parameter.name === paramName && parameter.id === id);
        if (parameter) {
            return parameter[fieldName]
        } else {
            return ""
        }
    }

    const getFieldValueByName = (paramName: string, fieldName: string) => {
        const parameter: any = props.kentParameters.find(parameter => parameter.name === paramName);
        if (parameter) {
            return parameter[fieldName]
        } else {
            return ""
        }
    }

    const getLabelText = (parameter: SequenceParameter | CodonSelectionCriteriaParameter | GcContentParameter | NucleotideRepeatsParameter | FrameshiftParameter, parmeterNumber: number | undefined) => {
        const labelName = parameterLabelNames[parameter.name as keyof typeof parameterLabelNames]
        if (parameter.name === 'Sequence' || parameter.name === 'NucleotideRepeats') {
            return `${labelName} #${parmeterNumber}`
        }
        return labelName
    }

    const organismOptions = useMemo(() => {
        if (codonSelectionInputs) {
            return Object.keys(codonSelectionInputs)
        }
        return []
    }, [codonSelectionInputs])

    const selectedOrganism = getFieldValueByName("CodonSelectionCriteria", "organism")
    const cellTypeOptions = useMemo(() => {
        if (codonSelectionInputs && selectedOrganism) {
            return Object.keys(codonSelectionInputs[selectedOrganism])
        }
        return []
    }, [codonSelectionInputs, selectedOrganism])

    const selectedCellType = getFieldValueByName("CodonSelectionCriteria", "cell_type")
    const parsetNameOptions = useMemo(() => {
        if (codonSelectionInputs && selectedOrganism && selectedCellType) {
            const options = codonSelectionInputs[selectedOrganism][selectedCellType]
            if (options) {
                return options
            }
            else return []
        }
        return []
    }, [codonSelectionInputs, selectedOrganism, selectedCellType])

    const getSliders = () => {
        return props.kentParameters.filter((p): p is Exclude<KentParameters[number], UTRParameter> => p.name !== 'UTRs' && p.name !== 'Sequence').map((parameter, index) => {
            let parameterNumber;
            if (parameter.name === "NucleotideRepeats" || parameter.name === "Sequence") {
                // If created in the UI, the id is the number. If from a preset, there's no id
                if (parameter.id) {
                    parameterNumber = parameter.id
                } else {
                    // If not, count how many there are up to this point
                    const parametersUpToThisPoint = props.kentParameters.slice(0, index)
                    const nucleotideRepeatsParameters = parametersUpToThisPoint.filter(param => param.name === parameter.name)
                    parameterNumber = nucleotideRepeatsParameters.length + 1
                }

            }
            return (
                <Stack spacing={1} direction="column" alignItems="center">
                    <Slider
                        disabled={props.readOnly}
                        orientation="vertical"
                        valueLabelDisplay="auto"
                        defaultValue={parameter.weight}
                        step={0.1}
                        marks
                        min={0.1}
                        max={1}
                        onChange={(e, value) => {
                            if (parameter.name === 'Sequence' || parameter.name === 'NucleotideRepeats') {
                                setParameterWithId(parameter.name, parameter.id, "weight", Number(value.toString()))
                            } else {
                                setParameterWithoutId(parameter.name, "weight", Number(value.toString()))
                            }
                        }}
                    />
                    <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={getLabelText(parameter, parameterNumber)} />
                </Stack>

            )
        })
    }

    const getHelpPopover = (id: string, text: string) => {
        return (
            <>
                <IconButton id={id} size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <HelpOutlineIcon fontSize='small' />
                </IconButton>
                <Popover
                    open={Boolean(anchorEl) && anchorEl?.id === id}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography variant="body2" sx={{ p: 2 }}>
                        {text}
                    </Typography>
                </Popover>
            </>
        )
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {parameterIsAlreadyAdded("CodonSelectionCriteria") &&
                <Stack direction="row" spacing={3} className="form-row" alignItems="center">
                    {getHelpPopover("codonPopOver", "Defines a codon scoring criteria based on organism, tissue and database. It can be used to select codons that either minimize or maximise this scoring")}
                    <div>
                        Optimize codons:
                    </div>
                    <FormControl size="small" disabled={props.readOnly}>
                        <InputLabel id="organism-label">Organism</InputLabel>
                        <Select
                            label="Organism"
                            sx={{ minWidth: 150 }}
                            value={getFieldValueByName("CodonSelectionCriteria", "organism")}
                            onChange={(e) => setParameterWithoutId("CodonSelectionCriteria", "organism", e.target.value)}
                        >
                            {organismOptions.map((organism) => {
                                return <MenuItem key={organism} value={organism}>{organism}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl size="small" disabled={props.readOnly || !getFieldValueByName("CodonSelectionCriteria", "organism")}>
                        <InputLabel id="cell-type-label">Cell type</InputLabel>
                        <Select
                            label="Cell type"
                            sx={{ minWidth: 150 }}
                            value={getFieldValueByName("CodonSelectionCriteria", "cell_type")}
                            onChange={(e) => setParameterWithoutId("CodonSelectionCriteria", "cell_type", e.target.value)}
                        >
                            {cellTypeOptions.map((cellType) => {
                                return <MenuItem key={cellType} value={cellType}>{cellType}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl size="small" disabled={props.readOnly || !getFieldValueByName("CodonSelectionCriteria", "organism") || !getFieldValueByName("CodonSelectionCriteria", "cell_type")}>
                        <InputLabel id="parset-name-label">Parset name</InputLabel>
                        <Select
                            label="Parset name"
                            sx={{ minWidth: 150 }}
                            value={getFieldValueByName("CodonSelectionCriteria", "parset_name")}
                            onChange={(e) => setParameterWithoutId("CodonSelectionCriteria", "parset_name", e.target.value)}
                        >
                            {parsetNameOptions.map((parsetName) => {
                                return <MenuItem key={parsetName} value={parsetName}>{parsetName}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl size="small" disabled={props.readOnly}>
                        <Select
                            data-cy={'codonSelection'}
                            value={getFieldValueByName("CodonSelectionCriteria", "mode") === 'min' || getFieldValueByName("CodonSelectionCriteria", "mode") === 'max' ? getFieldValueByName("CodonSelectionCriteria", "mode") : 'target'}
                            onChange={(e) => { setParameterWithoutId("CodonSelectionCriteria", "mode", e.target.value) }
                            }
                        >
                            <MenuItem data-cy={'select-option-min'} value={'min'}>Minimum</MenuItem>
                            <MenuItem data-cy={'select-option-max'} value={'max'}>Maximum</MenuItem>
                            <MenuItem data-cy={'select-option-target'} value={'target'}>Target</MenuItem>

                        </Select>
                    </FormControl>
                    {(getFieldValueByName("CodonSelectionCriteria", "mode") !== 'min' && getFieldValueByName("CodonSelectionCriteria", "mode") !== 'max') &&
                        <TextField
                            data-cy={'codon-selection-target-input'}
                            disabled={props.readOnly}
                            value={getFieldValueByName("CodonSelectionCriteria", "mode")}
                            onChange={(e) => setParameterWithoutId("CodonSelectionCriteria", "mode", e.target.value)}
                            label="Codon Speed"
                            size="small"
                            type="number"
                            variant="outlined"
                            autoComplete="off"
                            inputProps={{
                                step: 0.1,
                                max: 1
                            }}
                            className="form-input" />}
                    <IconButton disabled={props.readOnly} onClick={() => removeParameterByName("CodonSelectionCriteria")}>
                        <Delete />
                    </IconButton>
                </Stack>}
            {parameterIsAlreadyAdded("GcContent") &&
                <Stack direction="row" spacing={3} className="form-row" alignItems="center">
                    {getHelpPopover("GCPopOver", "Sets a target GC content, such as '45%' or 'minimum'")}
                    <div>
                        Optimize for a GC content of:
                    </div>
                    <FormControl size="small" disabled={props.readOnly}>
                        <Select
                            data-cy={'gcRepeats'}
                            value={getFieldValueByName("GcContent", "target") === 'min' || getFieldValueByName("GcContent", "target") === 'max' ? getFieldValueByName("GcContent", "target") : 'target'}
                            onChange={(e) => { setParameterWithoutId("GcContent", "target", e.target.value) }
                            }
                        >
                            <MenuItem data-cy={'select-option-min'} value={'min'}>Minimum</MenuItem>
                            <MenuItem data-cy={'select-option-max'} value={'max'}>Maximum</MenuItem>
                            <MenuItem data-cy={'select-option-target'} value={'target'}>Target</MenuItem>

                        </Select>
                    </FormControl>
                    {(getFieldValueByName("GcContent", "target") !== 'min' && getFieldValueByName("GcContent", "target") !== 'max') &&
                        <TextField
                            disabled={props.readOnly}
                            value={getFieldValueByName("GcContent", "target")}
                            onChange={(e) => setParameterWithoutId("GcContent", "target", e.target.value)}
                            label="Target"
                            size="small"
                            type="number"
                            variant="outlined"
                            autoComplete="off"
                            className="form-input" />
                    }
                    <IconButton disabled={props.readOnly} onClick={() => removeParameterByName("GcContent")}>
                        <Delete />
                    </IconButton>
                </Stack>}
                {parameterIsAlreadyAdded("Frameshift") &&
                <Stack direction="row" spacing={3} className="form-row" alignItems="center">
                    {getHelpPopover("FrameshiftPopOver", "Control the inclusion of ribosomal frameshift sites which can lead to the production of out-of-frame translation products")}
                    <div>
                        Adjust frameshift rate to:
                    </div>
                    <FormControl size="small" disabled={props.readOnly}>
                        <Select
                            data-cy={'frameshift-target'}
                            value={getFieldValueByName("Frameshift", "target") === 'min' || getFieldValueByName("Frameshift", "target") === 'max' ? getFieldValueByName("Frameshift", "target") : 'target'}
                            onChange={(e) => { setParameterWithoutId("Frameshift", "target", e.target.value) }
                            }
                        >
                            <MenuItem data-cy={'select-option-min'} value={'min'}>Minimum</MenuItem>
                            <MenuItem data-cy={'select-option-max'} value={'max'}>Maximum</MenuItem>
                            <MenuItem data-cy={'select-option-target'} value={'target'}>Target</MenuItem>

                        </Select>
                    </FormControl>
                    {(getFieldValueByName("Frameshift", "target") !== 'min' && getFieldValueByName("Frameshift", "target") !== 'max') &&
                        <TextField
                            disabled={props.readOnly}
                            value={getFieldValueByName("Frameshift", "target")}
                            onChange={(e) => setParameterWithoutId("Frameshift", "target", e.target.value)}
                            label="Target"
                            size="small"
                            type="number"
                            variant="outlined"
                            autoComplete="off"
                            className="form-input" />
                    }
                    <IconButton disabled={props.readOnly} onClick={() => removeParameterByName("Frameshift")}>
                        <Delete />
                    </IconButton>
                </Stack>}
                
            {getNucleotideRepeatsRows()}
            {getSequenceRows()}
            <Stack direction="row" justifyContent="left" spacing={3} sx={{ mt: 2, mb: 2 }}>
                <Button
                    variant="outlined"
                    size="small"
                    disabled={props.readOnly || parameterIsAlreadyAdded("CodonSelectionCriteria")}
                    startIcon={<Add />}
                    className="form-row"
                    onClick={addCodonSelectionCriteriaParameter}>
                    Codon Selection Criteria Parameter
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    disabled={props.readOnly || parameterIsAlreadyAdded("GcContent")}
                    startIcon={<Add />}
                    className="form-row"
                    onClick={addGcContentParameter}>
                    GC Content Parameter
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    disabled={props.readOnly || parameterIsAlreadyAdded("Frameshift")}
                    startIcon={<Add />}
                    className="form-row"
                    onClick={addFrameshiftParameter}>
                    Frameshift Parameter
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    disabled={props.readOnly}
                    startIcon={<Add />}
                    className="form-row"
                    onClick={addNucleotideRepeatsParameter}>
                    Consecutive Nucleotide Parameter
                </Button>
                <Button
                    disabled={props.readOnly}
                    variant="outlined"
                    size="small"
                    startIcon={<Add />}
                    className="form-row"
                    onClick={addSequenceParameter}>
                    Sequence Parameter
                </Button>
            </Stack>
            {props.kentParameters.filter(p => p.name !== 'Sequence').length !== 0 &&
                <>
                    <Paper className="importance-text">
                        Most important
                    </Paper>
                    <Stack sx={{ height: 300 }} spacing={4} direction="row" justifyContent="center" >
                        {getSliders()}
                    </Stack>
                    <Paper className="importance-text">
                        Least important
                    </Paper>
                </>
            }
        </Box>
    )
}

export default KentParametersForm;
