import { useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { TemplateComponentType } from '../../models/TemplateComponent';
import { TemplateComponent } from "../../models/TemplateComponent";
import OptionViewer from './OptionViewer';

interface ComponentViewerProps {
    open: boolean
    setOpen: (value: boolean) => void
    component: TemplateComponent
}

export default function ComponentViewer({ open, setOpen, component }: ComponentViewerProps) {
    const getTypeDescription = (type: TemplateComponentType) => {
        switch (type) {
            case 'USER_INPUT':
                return 'User defined sequence'
            case 'OPTIONS_OR_USER_INPUT':
                return 'User defined sequence or one from a set of options'
            case 'FIXED':
                return 'A fixed sequence'
            case 'FIXED_OPTIONS':
                return 'A sequence from a set of options'
            case 'SEQUENCE_TBD':
                return 'Sequence to be determined later'
            default:
                return ''
        }
    }

    const renderOptions = useMemo(() => {
        switch (component.type) {
            case 'OPTIONS_OR_USER_INPUT':
            case 'FIXED_OPTIONS':
                return <OptionViewer options={component.options} />
            case 'FIXED':
                return <OptionViewer options={[{ name: component.name, value: typeof component.value == 'string' ? component.value : "" }]} />
            case 'USER_INPUT':
            case 'SEQUENCE_TBD':
            default:
                return <></>
        }

    }, [component])


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                bgcolor: 'background.paper',
                border: '1px solid #000',
                boxShadow: 24,
                p: 7,
                borderRadius: '16px'
            }}>
                <Stack direction='column' justifyContent='left' alignItems='start' spacing={3}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h6" component="h2">
                            View component
                        </Typography>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                    <Stack justifyContent="space-between">
                        <Typography variant="body2" sx={{ marginTop: '0.5rem !important' }}><b>Name:</b> {component.name}.</Typography>
                        <Typography variant="body2" sx={{ marginTop: '0.5rem !important' }}><b>Description:</b> {component.description}.</Typography>
                        <Typography variant="body2" sx={{ marginTop: '0.5rem !important' }}><b>Type:</b> {getTypeDescription(component.type)}.</Typography>
                        {(component.type === "USER_INPUT" || component.type === "OPTIONS_OR_USER_INPUT") &&
                            <>
                                <Typography variant="body2" sx={{ marginTop: '0.5rem !important' }}><b>Is optimizable:</b> {component.isOptimizable ? "Yes" : "No"}.</Typography>
                                <Typography variant="body2" sx={{ marginTop: '0.5rem !important' }}><b>Is optional:</b> {component.isOptional ? "Yes" : "No"}.</Typography>
                            </>
                        }
                    </Stack>
                    {renderOptions}
                </Stack>
            </Box>
        </Modal >
    )
}
