import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    TextField,
    Typography,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Table,
    Paper,
    TableHead,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Papa from 'papaparse';
import { createCQAReport, getCQAReport } from "../../../api/ManufacturerQueries";
import { CQAReport } from "../../../models/CQAReport";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CriticalQualityAttributes } from "../../../models/CQAReport";
import { CQAList } from "../../../utils/CQAs";
import { showAlert } from "../../../components/Alert/alertSlice";
import { useDispatch } from "react-redux";
import { Run, RunStatus } from "../../../models/Run";
import { getManufacturerRuns } from "../../../api/RunQueries";

interface ReportsPageLocationState {
    currentRunId: string
}

function ReportsPage() {
    let { id } = useParams();
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { currentRunId } = location.state as ReportsPageLocationState ?? { currentRunId: "" }

    const { getAccessTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState(false)

    const [date, setDate] = useState<string>("")
    const [runReference, setRunReference] = useState<string>(currentRunId)
    const [cqaValues, setCQAValues] = useState<CriticalQualityAttributes[]>([])
    const [runs, setRuns] = useState<Run[] | undefined>(undefined)

    const getReport = async () => {
        const accessToken = await getAccessTokenSilently()
        const record: CQAReport = await getCQAReport(accessToken, id!)
        if (record) {
            setFields(record)
        }
    }

    const getAllRuns = async () => {
        const accessToken = await getAccessTokenSilently();
        const allRuns: Run[] | undefined = await getManufacturerRuns(accessToken);

        if (allRuns && allRuns.length > 0) {
            const filteredRuns = allRuns.filter((run: Run) => run.status !== RunStatus.DRAFTED)
            setRuns(filteredRuns)
        }
    }

    useEffect(() => {
        if (id) {
            getReport()

        }
        else {
            getAllRuns()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const setFields = (record: CQAReport) => {
        setDate(new Date(record.date).toISOString().split('T')[0])
        setCQAValues(record.criticalQualityAttributes)
    }


    const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            parseCSV(file);
        }
    };

    const parseCSV = async (file: File) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results: any) => {
                const cqaValues: CriticalQualityAttributes[] = []
                results.data.forEach((row: any) => {
                    const cqaName = row["CQA/KPI"];
                    const method = row["Analytical method"];
                    const value = row["Value"];

                    const cqaInList = CQAList.find((cqa => cqa.name === cqaName && cqa.analyticalMethods.includes(method)))

                    if (cqaInList) {
                        const cqaValueIndex = cqaValues.findIndex((cqa => cqa.name === cqaName))
                        if (cqaValueIndex === -1) {
                            cqaValues.push({
                                name: cqaName,
                                values: [{
                                    analythicalMethod: method,
                                    value: value
                                }]
                            })
                        } else {
                            cqaValues[cqaValueIndex].values.push({
                                analythicalMethod: method,
                                value: value
                            })
                        }
                    }
                })
                setCQAValues(cqaValues)
            },
        });
    }

    const setCQAValue = (name: string, method: string, value: string) => {
        const newValues = [...cqaValues]
        const valueIndex = newValues.findIndex(cqa => cqa.name === name);
        if (valueIndex !== -1) {
            const methodIndex = newValues[valueIndex].values.findIndex(methodValue => methodValue.analythicalMethod === method);
            if (methodIndex !== -1) {
                newValues[valueIndex].values[methodIndex].value = value
            } else {
                newValues[valueIndex].values.push({ analythicalMethod: method, value })
            }
        } else {
            newValues.push({ name, values: [{ analythicalMethod: method, value }] })
        }
        setCQAValues(newValues)
    }

    const getCQAValue = (name: string, method: string) => {
        const valueIndex = cqaValues.findIndex(cqa => cqa.name === name);
        if (valueIndex !== -1) {
            const methodIndex = cqaValues[valueIndex].values.findIndex(methodValue => methodValue.analythicalMethod === method);
            if (methodIndex !== -1) {
                return cqaValues[valueIndex].values[methodIndex].value
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    const getForm = () => {
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ height: '100%' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>CQA/KPI</TableCell>
                                <TableCell align="center">Minimum threshold</TableCell>
                                <TableCell align="center">Analytical method</TableCell>
                                <TableCell align="center">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {CQAList.map((cqa) => (
                                <Fragment>
                                    <TableRow>
                                        <TableCell rowSpan={cqa.analyticalMethods.length + 1}>
                                            {cqa.name}
                                        </TableCell>
                                        <TableCell align="center" rowSpan={cqa.analyticalMethods.length + 1}>
                                            {cqa.minimumThreshold}
                                        </TableCell>
                                    </TableRow>
                                    {cqa.analyticalMethods.map((method) => (
                                        <TableRow>
                                            <TableCell align="center">{method}</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    id={cqa.name + method}
                                                    size="small"
                                                    variant="outlined"
                                                    autoComplete="off"
                                                    value={getCQAValue(cqa.name, method)}
                                                    disabled={id !== undefined}
                                                    onChange={(e) => setCQAValue(cqa.name, method, e.target.value)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    }

    const upload = async () => {
        setUploading(true)
        const accessToken = await getAccessTokenSilently()
        const record: CQAReport = {
            runReference,
            date: new Date(date),
            criticalQualityAttributes: cqaValues
        }
        const response = await createCQAReport(accessToken, record)

        if (response) {
            dispatch(showAlert({ message: "You report was successfully uploaded", severity: "success" }))

        }
        setUploading(false)
    }

    const ableToUpload = useMemo(() => {
        return date && runReference
    }, [date, runReference])


    return (
        <Stack>
            <Stack direction="row" sx={{ width: '100%' }}>
                <Button
                    onClick={() => navigate(-1)}
                    variant="outlined"
                    sx={{ ml: 5, mt: 2 }}
                    startIcon={<ChevronLeftIcon />}
                >
                    Back to list
                </Button>
            </Stack>

            <Typography textAlign='center' variant="h5" sx={{ mt: 5, mb: 2 }}>
                {!id ? "Submit CQA Report" : "View CQA Report"}
            </Typography>

            <Typography variant="subtitle1" textAlign="center">
                {!id ? "Fill in the fields below. You can upload a CSV file to pre-fill them." : "Here is your submitted Run Report."}
            </Typography>

            <Stack direction='row' spacing={4} style={{ margin: 0, marginTop: 15 }} alignItems="center" justifyContent="center">
                <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    type="date"
                    value={date}
                    disabled={id !== undefined}
                    onChange={(e) => setDate(e.target.value)}
                    autoComplete="off"
                />
                {!id && runs && runs.length > 0 &&
                    <FormControl size="small">
                        <InputLabel>Run</InputLabel>
                        <Select
                            size="small"
                            value={runReference}
                            label="Run"
                            onChange={(e: any) => setRunReference(e.target.value)}
                            sx={{ maxWidth: '300px', minWidth: '300px' }}

                        >
                            {runs && runs.map((run: Run) => {
                                return (
                                    <MenuItem value={run.id}>{run.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                }
                {!id && runs && runs.length === 0 &&
                    <Typography variant="body2">No runs available. Create a run in the portal via the broker role.</Typography>
                }

                {!id &&
                    <label htmlFor="file-upload-button">
                        <input
                            type="file"
                            id="file-upload-button"
                            accept=".csv"
                            multiple={false}
                            style={{ display: "none" }}
                            onChange={handleFileInput}
                        />
                        <Stack direction='column' spacing={1} alignItems="center">
                            <Button variant="outlined" size="small" component="span">
                                Upload CSV
                            </Button>
                            <a
                                href="/CQA_report.csv"
                                download={"CQA_report.csv"}
                            >
                                <Typography sx={{ fontSize: 10, textTransform: "uppercase", color: 'rgb(20, 183, 219)', textDecoration: 'underline rgba(20, 183, 219, 0.4)' }}>
                                    Download sample CSV
                                </Typography>
                            </a>
                        </Stack>
                    </label>}
            </Stack>

            <Stack direction="column" sx={{ overflow: 'auto', flex: 1, p: 5 }}>
                {getForm()}
            </Stack>

            {!id &&
                <Stack direction="row" justifyContent="end" sx={{ px: 5, mb: 5 }}>
                    <LoadingButton
                        variant="contained"
                        disabled={!ableToUpload}
                        loading={uploading}
                        onClick={upload}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            }
        </Stack>
    );
}

export default ReportsPage
