import { Grid, Stack } from "@mui/material";
import { useMemo, useReducer, useState } from "react";
import ProductComponents from "../ProductComponents/ProductComponents";
import ManufacturabilityChecker from "../ManufacturabilityChecker/ManufacturabilityChecker";
import reducer, { ProductViewerActions } from "./ProductViewerReducer";
import './ProductViewer.css'
import { useSelector } from "react-redux";
import { selectProduct } from "../ProductWindow/ProductsSlice";


const ProductViewer = () => {

  const [, dispatch] = useReducer(reducer, { autosave: false });
  const [highlights, setHighlights] = useState<any>(null);
  const product = useSelector(selectProduct)


  const updateAutoSave = (autosave: boolean) => {
    dispatch({ type: ProductViewerActions.SET_AUTO_SAVE, payload: { autosave } })
  }

  const prepareHighlight = (data: any | null) => {
    if (data === null) {
      setHighlights(undefined)
    }
    const splitStr = data.split("-").map((item: any) => parseInt(item));
    const newHighlights = [{ start: splitStr[0], end: splitStr[1] }]
    setHighlights(newHighlights);
  }

  const constructHasTBDcomponent = useMemo(() => {
    if (product) {
      return product.run!.process!.template!.components.some((component) => component.type === 'SEQUENCE_TBD')
    }
    return false
  }, [product])

  return (
    <Stack direction='column' spacing={2} alignItems='center' sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item md={9} sx={{ display: 'flex', flexDirection: 'column', borderRight: 1, borderColor: 'grey.200' }}>
          {/* <Stack direction="row" spacing={2} alignItems='center' sx={{ visibility: state.autosave ? 'visible' : 'hidden' }}>
          <Typography variant="caption">Autosaving...</Typography>
          <CircularProgress size={20} />
        </Stack> */}
          <ProductComponents updateAutoSave={updateAutoSave} highlights={highlights} />
        </Grid>
        <Grid item md={3} sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', height: '100%' }}>
          <ManufacturabilityChecker highlightSequence={prepareHighlight} constructHasTBDcomponent={constructHasTBDcomponent}/>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ProductViewer
