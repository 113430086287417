import { Manufacturer } from "./Manufacturer";
import { Template } from "./Template";

export enum ProcessOutput {
    DRUG_SUBSTANCE='DRUG_SUBSTANCE',
    FORMULATED_DRUG_PRODUCT='FORMULATED_DRUG_PRODUCT',
  }

export interface Process {
    id?: string;
    name: string;
    output: ProcessOutput;
    description?: string;
    template?: Template;
    templateId: string;
    manufacturer?: Manufacturer;
    manufacturerId?: string;
}