export const getRandomColors = () => {
    let colors = []
    for (let i = 0; i < 50; i++) {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let j = 0; j < 6; j++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        colors.push(color)
    }
    return colors;
}

const colors = ['#065535', '#ffd700', '#ffa500', '#ff0000', '#00ffff', '#d3ffce', '#fa8072', '#003366', '#ffb6c1', '#800080', ...getRandomColors()]

export const getComponentsColors = (labels: Array<string>) => {

    const componentColors = new Map<string, string>(labels.map((item, index) => {
        return (
            [item, colors[index]]
        )
    }))

    return componentColors
}
