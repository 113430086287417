import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Box,
    Typography,
    Stack,
    Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Designer } from "../../../models/Designer";
import { FormInputText, FormInputTextType } from "../../../components/FormComponents";
import { getMe, updateMe } from "../../../api/DesignerQueries";



function ProfilePage() {
    const { getAccessTokenSilently } = useAuth0();
    const [saving, setSaving] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [designer, setDesigner] = useState<Designer | null>(null)


    const schema = z.object({
        firstName: z.string().min(1, { message: "First name is required" }),
        lastName: z.string().min(1, { message: "Last name is required" }),
        email: z.string().min(1, { message: "Email is required" }),
        institution: z.string().min(1, { message: "Institution is required" }),
    })

    type FormFields = z.infer<typeof schema>;

    const { control, handleSubmit, reset } = useForm<FormFields>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {},
    });

    const getUser = async () => {
        const accessToken = await getAccessTokenSilently()
        const designer: Designer = await getMe(accessToken)
        if (designer) {
            setDesigner(designer)
            reset({
                firstName: designer.firstName,
                lastName: designer.lastName,
                email: designer.email,
                institution: designer.institution
            })
        }
    }


    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const save = async (data: FormFields) => {
        setSaving(true)
        const accessToken = await getAccessTokenSilently()
        const designer = await updateMe(accessToken, {
            firstName: data.firstName,
            lastName: data.lastName,
            institution: data.institution
        })
        if (designer) {
            setDesigner(designer)
        }
        setSaving(false)
        setIsEditing(false)
    }

    const allFieldsSet = useMemo(() => {
        return designer && designer.firstName && designer.lastName && designer.institution
    }, [designer])

    return (
        <>
            <Typography variant="h6" textAlign="center" sx={{ mt: 5 }}>
                Profile information
            </Typography>
            <Box display="flex" justifyContent='center' sx={{ mt: 2 }}>
                {isEditing || (designer && !allFieldsSet) ?
                    <form noValidate onSubmit={handleSubmit((data: FormFields) => save(data))} style={{ width: '80%' }}>
                        <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={2} >
                            <FormInputText
                                name="firstName"
                                size="small"
                                control={control}
                                label="First name"
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <FormInputText
                                name="lastName"
                                size="small"
                                control={control}
                                label="Last name"
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <FormInputText
                                name="email"
                                size="small"
                                control={control}
                                label="Email"
                                disabled={true}
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <FormInputText
                                name="institution"
                                size="small"
                                control={control}
                                label="Institution"
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                                <LoadingButton
                                    variant="contained"
                                    loading={saving}
                                    type="submit"
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </form>

                    :
                    <Stack direction='column' spacing={3} sx={{ ml: 5, mt: 5, width: 400 }}>
                        <Typography variant="body1">First name: {designer ? designer.firstName : ""}</Typography>
                        <Typography variant="body1">Last name: {designer ? designer.lastName : ""}</Typography>
                        <Typography variant="body1">Email: {designer ? designer.email : ""}</Typography>
                        <Typography variant="body1">Institution: {designer ? designer.institution : ""}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                            <Button
                                variant="outlined"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                        </Box>
                    </Stack>
                }
            </Box>
        </>
    );
}

export default ProfilePage
