import React, { useEffect, useState } from "react";
import { Box, Button, TableBody, TableCell, TableHead, TableRow, TableContainer, Table, Paper, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { deleteInput, getAllOptimizationsForUser } from "../../../api/OptimizeQueries"
import { useAuth0 } from "@auth0/auth0-react";
import { OptimizationInput } from "../../../models/Optimization";
import { Link } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import './OptimizationsListPage.css'


function OptimizationsListPage() {
    const { getAccessTokenSilently } = useAuth0();

    const [loadingOptimizations, setLoadingOptimizations] = useState(true)
    const [optimizationInputs, setOptimizationInputs] = useState<OptimizationInput[]>([]);
    const [deletingOptimizationId, setDeletingOptimizationId] = useState("true")

    
    const getAllSeqOptInputs = async () => {
        setLoadingOptimizations(true)
        const accessToken = await getAccessTokenSilently()
        const optimizations: OptimizationInput[] = await getAllOptimizationsForUser(accessToken)
        if (optimizations) {
            setOptimizationInputs(optimizations);
        }
        setLoadingOptimizations(false)
    }

    const deleteOptimization = async (id: string) => {
        setDeletingOptimizationId(id)
        const accessToken = await getAccessTokenSilently()
        const response = await deleteInput(accessToken, id)
        if (response) {
            const newOptimzations = optimizationInputs.filter(input => input.id !== id)
            setOptimizationInputs(newOptimzations);
        }
        setDeletingOptimizationId("")
    }

    useEffect(() => {
        getAllSeqOptInputs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const addElipsisToString = (stringToModify: string) => {
        if (stringToModify.length < 20) {
            return stringToModify
        }
        return `${stringToModify.substring(0, 20)}...`
    }


    const optimizationInputRows = () => {
        return optimizationInputs.map((optimization: OptimizationInput, index) => {
            return (
                <TableRow
                    component={Link} to={optimization.id! === deletingOptimizationId ? '#' : optimization.id!}
                    key={index}
                    className='optimization-row'
                >
                    <TableCell component="th" scope="row">
                        {optimization.id}
                    </TableCell>
                    <TableCell align="center">{new Date(optimization.date).toLocaleString()}</TableCell>
                    <TableCell align="center">{optimization.sequenceType}</TableCell>
                    <TableCell align="center">{addElipsisToString(optimization.sequence)}</TableCell>
                    <TableCell align="center">{optimization.result?.status}</TableCell>
                    <TableCell align="center">
                        <IconButton 
                            size="small" 
                            onClick={(e) => { e.preventDefault(); deleteOptimization(optimization.id!) }} 
                            disabled={optimization.id! === deletingOptimizationId}>
                            <Delete fontSize="small"/>
                        </IconButton>
                    </TableCell>

                </TableRow>
            )
        })
    }

    return (
            <Box sx={{ pr: 10, pl: 10, pb: 5 }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Stack direction='column' spacing={1}>
                        <Typography variant='h6' align="left">Codon optimization</Typography>
                        <Typography variant='subtitle1' align="left">Powered by University of Kent</Typography>
                    </Stack>
                    <Button variant="contained" sx={{ m: 5 }} component={Link} to={'new'}>
                        Create new optimization
                    </Button>
                </Stack>
                {!loadingOptimizations && optimizationInputs.length !== 0 &&
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell align="center">Date</TableCell>
                                    <TableCell align="center">Sequence type</TableCell>
                                    <TableCell align="center">Sequence</TableCell>
                                    <TableCell align="center">Result status</TableCell>
                                    <TableCell align="center">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {optimizationInputRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                {loadingOptimizations &&
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                }
            </Box>
    );
}


export default OptimizationsListPage;
