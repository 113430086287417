import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';

export const getRole = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/users/role`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your user', severity: 'error' }))
    }
}