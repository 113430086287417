import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { SxProps, FormControl, FormHelperText } from "@mui/material";
import { ChangeEvent } from "react";
import { FieldSize } from ".";

interface ComponentSx {
    formControl?: SxProps
    textField?: SxProps
    helper?: SxProps
}

export enum FormInputTextType {
    Text = 'text',
    Number = 'number',
    Date = 'date',
}

interface FormInputTextProps {
    childrenSx?: ComponentSx;
    control: any;
    disabled?: boolean;
    helperText?: string
    label: string;
    multiline?: boolean;
    name: string;
    placeholder?: string
    required: boolean;
    rows?: number;
    size?: FieldSize;
    type: FormInputTextType
}

export const FormInputText = ({ childrenSx, name, size, control, label, required, helperText, placeholder, type, disabled, multiline, rows }: FormInputTextProps) => {

    const processEvent = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (type) {
            case FormInputTextType.Number:
                return event.target.value !== "" ? parseFloat(event.target.value) : undefined;
            case FormInputTextType.Date:
                return event.target.value !== "" ? new Date(event.target.value) : undefined;
            case FormInputTextType.Text:
            default:
                return event.target.value;
        }
    }

    const processValue = (value: string | number | Date | undefined) => {
        if (value && type === FormInputTextType.Date) {
            value = value as Date;
            return value.toISOString().split('T')[0];
        } else {
            return value;
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { value, onChange, onBlur, ref },
                fieldState: { error },
            }) => (
                <FormControl sx={{ width: '100%', ...childrenSx?.formControl }}>
                    <TextField
                        autoComplete="off"
                        disabled={disabled}
                        error={Boolean(error)}
                        helperText={helperText}
                        InputLabelProps={{ shrink: type === FormInputTextType.Date ? true : undefined }} // This is because if not the label overrides the "mm/dd/yy" placeholder
                        inputRef={ref}
                        label={label}
                        multiline={multiline}
                        onBlur={onBlur}
                        onChange={(event) => onChange(processEvent(event))}
                        placeholder={placeholder}
                        required={required}
                        rows={rows}
                        size={size}
                        sx={childrenSx?.textField}
                        type={type}
                        value={processValue(value)}
                    />
                    <FormHelperText sx={childrenSx?.helper}>
                        {error?.message ?? ''}
                    </FormHelperText>
                </FormControl>
            )}
        />
    );
};
