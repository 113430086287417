import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Box,
    Typography,
    Stack,
    Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormInputText, FormInputTextType } from "../../../components/FormComponents";
import { getMe, updateMe } from "../../../api/ManufacturerQueries";
import { Manufacturer } from "../../../models/Manufacturer";


function ProfilePage() {
    const { getAccessTokenSilently } = useAuth0();
    const [saving, setSaving] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [manufacturer, setManufacturer] = useState<Manufacturer | null>(null)


    const schema = z.object({
        email: z.string().min(1, { message: "Email is required" }),
        institutionName: z.string().min(1, { message: "Institution name is required" }),
    })

    type FormFields = z.infer<typeof schema>;

    const { control, handleSubmit, reset } = useForm<FormFields>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {},
    });

    const getUser = async () => {
        const accessToken = await getAccessTokenSilently()
        const manufacturer: Manufacturer = await getMe(accessToken)
        if (manufacturer) {
            setManufacturer(manufacturer)
            reset({
                email: manufacturer.email,
                institutionName: manufacturer.institutionName
            })
        }
    }


    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const save = async (data: FormFields) => {
        setSaving(true)
        const accessToken = await getAccessTokenSilently()
        const manufacturer = await updateMe(accessToken, {
            institutionName: data.institutionName
        })
        if (manufacturer) {
            setManufacturer(manufacturer)
        }
        setSaving(false)
        setIsEditing(false)
    }

    const allFieldsSet = useMemo(() => {
        return manufacturer && manufacturer.institutionName
    }, [manufacturer])

    return (
        <>
            <Typography variant="h6" textAlign="center" sx={{ mt: 5 }}>
                Profile information
            </Typography>
            <Box display="flex" justifyContent='center' sx={{ mt: 2 }}>
                {isEditing || (manufacturer && !allFieldsSet) ?
                    <form noValidate onSubmit={handleSubmit((data: FormFields) => save(data))} style={{ width: '80%' }}>
                        <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={2} >
                            <FormInputText
                                name="email"
                                size="small"
                                control={control}
                                label="Email"
                                disabled={true}
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <FormInputText
                                name="institutionName"
                                size="small"
                                control={control}
                                label="Institution name"
                                required={true}
                                type={FormInputTextType.Text}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                                <LoadingButton
                                    variant="contained"
                                    loading={saving}
                                    type="submit"
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </form>

                    :
                    <Stack direction='column' spacing={3} sx={{ ml: 5, mt: 5, width: 400 }}>
                        <Typography variant="body1">Email: {manufacturer ? manufacturer.email : ""}</Typography>
                        <Typography variant="body1">Institution name: {manufacturer ? manufacturer.institutionName : ""}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                            <Button
                                variant="outlined"
                                onClick={() => setIsEditing(true)}
                            >
                                Edit
                            </Button>
                        </Box>
                    </Stack>
                }
            </Box >
        </>
    );
}

export default ProfilePage
