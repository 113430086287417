import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';
import { Product } from "../models/Product";

export const createProduct = async (accessToken: string, product: Product): Promise<Product | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(product)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while creating your construct', severity: 'error' }))
    }
}


export const getProducts = async (accessToken: string): Promise<Array<Product> | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your constructs', severity: 'error' }))
    }
}

export const getProductById = async (accessToken: string, productId: string, dispatchError = true): Promise<Product | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        }

        if (dispatchError) {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your construct', severity: 'error' }))
    }
}

export const updateProduct = async (accessToken: string, product: Partial<Product>, productId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(product)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while updating your construct', severity: 'error' }))
    }
}
interface JustificationBody {
    justification: string
}
export const rejectProduct = async (accessToken: string, productId: string, justificationBody: JustificationBody) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}/reject`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(justificationBody)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while requesting a reject of your construct', severity: 'error' }))
    }
}

export const reviewProduct = async (accessToken: string, productId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}/review`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while requesting a review of your construct', severity: 'error' }))
    }
}

export const reserveProduct = async (accessToken: string, productId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}/reserve`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while reserving your construct', severity: 'error' }))
    }
}


export const deleteProduct = async (accessToken: string, productId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/${productId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while deleting your construct', severity: 'error' }))
    }
}

export const getAllProducts = async (accessToken: string): Promise<Array<Product> | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/constructs/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting constructs', severity: 'error' }))
    }
}
