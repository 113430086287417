import { Designer } from "../models/Designer";
import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';


export const updateMe = async (accessToken: string, designer: Partial<Designer>) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/designers/me`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(designer)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while updating your user', severity: 'error' }))
    }
}


export const getMe = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/designers/me`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your user', severity: 'error' }))
    }
}

export const getAllDesigners = async (accessToken: string): Promise<Designer[] | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/designers`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting designers', severity: 'error' }))
    }
}