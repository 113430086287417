import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material"
import ProcessDash from "../ProcessPage/ProcessDash"
import ProfilePage from "../ProfilePage/ProfilePage"
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Manufacturer } from "../../../models/Manufacturer";
import { getMe } from "../../../api/ManufacturerQueries";
import { Process } from "../../../models/Process";
import { ProcessTable } from "../ProcessPage/ProcessTable";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";

function DesignerHomePage(){
    const [activeTab, setActiveTab] = useState(0);
    const [loadingProcesses, setLoadingProcesses] = useState(true);
    const [processes, setProcesses] = useState<Process[]>([]);
    const { getAccessTokenSilently } = useAuth0();


    const getCurrentManufacturer = async () => {
        setLoadingProcesses(true)
        const accessToken = await getAccessTokenSilently();
        const manufacturer: Manufacturer = await getMe(accessToken);
        if (manufacturer) {
        setProcesses(manufacturer.processes)
        console.log(manufacturer.processes)
        }
        setLoadingProcesses(false)
    };

    useEffect(() => {
        getCurrentManufacturer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTabChange = (event:any, newValue:number) => {
        setActiveTab(newValue);
      };
      const renderComponent = () => {
        switch (activeTab) {
          case 0:
            return <ProcessTable loadingProcesses={loadingProcesses} processes={processes} />;
          case 1:
            return <ProcessDash loadingProcesses={loadingProcesses} processesProps={processes} />;
        }
      };
    return(
        <Box justifyContent='center'>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5, mb: 5}}>Manufacturer home</Typography>
            <Grid container spacing={2}>
            <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
                <Link to="/process/new">
                <Button variant="contained">Create Process <AddIcon sx={{ ml: 1 }} /></Button>
                </Link>
            </Box>
                <Grid item xs={4}>
                    <ProfilePage />
                </Grid>
                <Grid item xs={8}>
                <Typography variant="h6" textAlign="center" sx={{ mt: 5, ml:5}}>Process Dashboard</Typography>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="List View" />
                    <Tab label="Grid View" />
                </Tabs>
                <Box sx={{ marginTop: 2, width: '90%'}}>
                    {renderComponent()}
                </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DesignerHomePage