import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
    Grid,
} from '@mui/material';
import './AllProductsPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import { Product } from "../../../models/Product";
import { getAllProducts } from "../../../api/ProductQueries";


function AllProductsPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const [products, setProducts] = useState<Product[]>([])

    const getProducts = async () => {
        const accessToken = await getAccessTokenSilently()
        const products = await getAllProducts(accessToken)
        if (products) {
            setProducts(products)
        }
    }

    useEffect(() => {
        getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Grid
                sx={{ p: 5 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign='center' variant="h5">
                        All Constructs
                    </Typography>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Stack direction='column' sx={{ p: 5 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Id</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Designer</TableCell>
                                <TableCell align="center">Designer institution</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.map((product, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center" component="th" scope="row">
                                        {product.id}
                                    </TableCell>
                                    <TableCell align="center">{product.name}</TableCell>
                                    <TableCell align="center">{`${product.designer?.firstName} ${product.designer?.lastName}`}</TableCell>
                                    <TableCell align="center">{product.designer?.institution}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}

export default AllProductsPage
