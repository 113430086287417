import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    TextField
  } from '@mui/material';
import { Process } from '../../../models/Process';
import { getConstantDisplayName } from '../../../utils/getDisplayNames';
import { useState } from 'react';

export const ProcessTable = ({ processes, loadingProcesses }: {processes: Process[], loadingProcesses: boolean}) => {
    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterText, setFilterText] = useState('');  

    const handleChangePage = (event:any, newPage:any) => {
        setPage(newPage);
      };

    const handleChangeRowsPerPage = (event:any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const filteredRows = processes.filter(row => row.name.toLowerCase().includes(filterText.toLowerCase()));
      const currentRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return(
        <Paper>
        <TextField
            label="Search Processes"
            variant="outlined"
            margin="normal"
            sx={{margin:2, width: '50%'}}
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
        />
        <TableContainer component={Paper}>
        {!loadingProcesses && <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Output</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Template Name</TableCell>
              <TableCell>Template Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((process:Process, index:number) => (
              <TableRow key={index}>
                <TableCell>{process.name}</TableCell>
                <TableCell>{getConstantDisplayName(process.output)}</TableCell>
                <TableCell>{process.description}</TableCell>
                <TableCell>{process.template?.name}</TableCell>
                <TableCell>{process.template?.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </TableContainer>
      <TablePagination
        component="div"
        count={processes.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
       />
     </Paper>
    )
}

export default ProcessTable;