import { useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import EmptyWindow from "./EmptyWindow"
import ProductViewer from "./ProductViewer"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Button, Stack, Tooltip } from "@mui/material";
import ProductGeneralStep from "./ProductSteps/ProductGeneralStep";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "./ProductsSlice";
import { getProductById } from "../../api/ProductQueries";
import { useAuth0 } from "@auth0/auth0-react";
import ProductDrugSubstanceStep from "./ProductSteps/ProductDrugSubstanceStep";
import ProductDrugProductStep from "./ProductSteps/ProductDrugProductStep";
import { Product } from "../../models/Product";
import ProductReserveStep from "./ProductSteps/ProductReserveStep";

interface ProductWindowProps {
  update?: boolean
  forManufacturer?: boolean
  setUpdate?: (update: boolean) => void
  idFromProps?: string
}

const steps = ['General', 'DNA', 'Drug substance', 'Drug product', 'Reserve'];

const getCompletedStepsForProduct = (product: Product | undefined) => {
  const completedSteps = {
    'General': false,
    'DNA': false,
    'Drug substance': false,
    'Drug product': false,
    'Reserve': false
  }
  if (!product) return completedSteps

  if (product.name && product.quantity && product.run && parseInt(product.quantity) >= product.run.quantity.min
    && parseInt(product.quantity) <= product.run.quantity.max) completedSteps['General'] = true

    if (product.run?.modifiedNucOptions.length !== 0) {
    completedSteps['Drug substance'] = product.modifiedNucleotides !== null
  } else {
    completedSteps['Drug substance'] = true
  }
  if (product.run?.capMethodOptions.length !== 0) {
    completedSteps['Drug substance'] = product.cappingMethod !== null
  } else {
    completedSteps['Drug substance'] = true
  }
  if (product.run?.formulation) {
    completedSteps['Drug product'] = product.formulation !== null
  } else {
    completedSteps['Drug product'] = true
  }
  if (product.runIsReserved) completedSteps['Reserve'] = true

  completedSteps['DNA'] = true
  for (const component of product.components) {
    const correspondingTemplateComponent = product.run!.process!.template!.components.find(templateComponent => templateComponent.name === component.name)!
    if (!correspondingTemplateComponent.isOptional && component.sequence === '') {
      completedSteps['DNA'] = false
      break;
    }
  }

  return completedSteps
}

const ProductWindow = ({ update, setUpdate, idFromProps, forManufacturer }: ProductWindowProps) => {
  let { id } = useParams()
  if (!id) {
    id = idFromProps
  }
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams()

  const product = useSelector(selectProduct)

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(getCompletedStepsForProduct(product));

  const isProductSelected = useMemo(() => {
    return id !== undefined
  }, [id])

  const dispatchGlobal = useDispatch()

  const getStepSelected = () => {
    const stepFromUrl = searchParams.get('step')
    const stepIndex = steps.findIndex(step => step === stepFromUrl)
    if (stepIndex === -1) {
      return 0
    }
    return stepIndex
  }

  useEffect(() => {
    if (id) {
      setActiveStep(getStepSelected())
      setProductInStore(id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (update && id && setUpdate) {
      setProductInStore(id)
      setUpdate(false)
    }
    if (id && !setUpdate) {
      // This is a read only view, used when viewing a product from a run
      setProductInStore(id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  useEffect(() => {
    setCompletedSteps(getCompletedStepsForProduct(product))
  }, [product])


  const setProductInStore = async (id: string) => {
    const accessToken = await getAccessTokenSilently()
    const productRetrieved = await getProductById(accessToken, id)
    dispatchGlobal(setProduct(productRetrieved))
  }


  const handleStep = (step: number) => () => {
    selectStep(step);
  };

  const handleNext = () => {
    selectStep(activeStep + 1);
  };

  const handleBack = () => {
    selectStep(activeStep - 1);
  };

  const selectStep = (step: number) => {
    setActiveStep(step)
    setSearchParams({ step: steps[step] })
  }

  const reserveStepIsEnabled = useMemo(() => {
    for (const step in completedSteps) {
      if (step !== "Reserve" && !completedSteps[step as keyof typeof completedSteps]) return false
    }
    return true
  }, [completedSteps])

  return (
    !isProductSelected ? <EmptyWindow /> :
      <Stack spacing={1} direction="column" alignItems="center" sx={{ width: '100%', height: '100%', backgroundColor: 'white', overflow: 'auto' }}>
        <Stepper nonLinear activeStep={activeStep} sx={{ width: '90%', pb: 3, pt: 3 }}>
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={completedSteps[label as keyof typeof completedSteps]}
              disabled={label === "Reserve" ? !reserveStepIsEnabled : false}
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: activeStep === index ? 'primary' : 'grey.500',
                }
              }}
            >
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Stack direction='row' spacing={4} sx={{ pb: 4 }}>
          <Button
            variant='outlined'
            size="small"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Tooltip title={activeStep === steps.length - 2 && !reserveStepIsEnabled ? "Complete all the steps to reserve your product" : ""}>
            <span>
              <Button
                variant='outlined'
                size="small"
                disabled={activeStep === steps.length - 2 ? !reserveStepIsEnabled : activeStep === steps.length - 1}
                onClick={handleNext}>
                Next
              </Button>
            </span>
          </Tooltip>
        </Stack>
        {activeStep === 0 && <ProductGeneralStep setUpdateSideBar={setUpdate} />}
        {activeStep === 1 && <ProductViewer />}
        {activeStep === 2 && <ProductDrugSubstanceStep />}
        {activeStep === 3 && <ProductDrugProductStep />}
        {activeStep === 4 && <ProductReserveStep setUpdateSideBar={setUpdate} forManufacturer={forManufacturer} />}
      </Stack>
  )
}

export default ProductWindow
