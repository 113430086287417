import React from "react";
import { CircularProgress } from '@mui/material';

function Loader() {
    return (
        <div style={{position: 'relative'}}>
            <CircularProgress sx={{ml: "50%", mt: "25%"}}/>
        </div>
    );
}

export default Loader;
