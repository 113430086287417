import { useEffect, useMemo, useRef, useState } from "react";
import { Template } from "../../models/Template";
import { Box, BoxProps, Button, Modal, Stack, Typography } from '@mui/material';
import SeqViz from "seqviz";
import { Selection } from "seqviz/dist/selectionContext";
import { TemplateComponent } from "../../models/TemplateComponent";
import ComponentViewer from "./ComponentViewer";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 7,
    borderRadius: '16px'
};

interface TemplateViewerProps extends BoxProps {
    template: Template
    hideText?: boolean
}

const BP_BY_COMPONENT = 300

const TemplateViewer = ({ template, sx, hideText }: TemplateViewerProps) => {
    const [selectedAnnotation, setSelectedAnnotation] = useState<string | undefined>()
    const [selectedOption, setSelectedOption] = useState<string>("")
    const [openModal, setOpenModal] = useState<boolean>(false)

    const annotations = useMemo(() => {
        const annotations = template.components
            .sort((comp1, comp2) => comp1.position - comp2.position)
            .map((component, index) => {
                return {
                    start: index * BP_BY_COMPONENT,
                    end: (index + 1) * BP_BY_COMPONENT,
                    name: component.name,
                }
            })
        if (annotations.length === 1) {
            // Trick to make the annotation visible in the circular view.
            // It seems seqviz doesn't render a circular view with a single annotation that takes the full sequence.
            // This makes the single annotation leave one nucleotide out.
            annotations[0].end = annotations[0].end - 1
        }

        return annotations
    }, [template])


    const sequence = useMemo(() => {
        return 'x'.repeat(BP_BY_COMPONENT * template.components.length)
    }, [template])

    const onSelection = (selection: Selection) => {
        if (selection.type === 'ANNOTATION') {
            setSelectedAnnotation(selection.name)
            setSelectedOption("")
            setOpenModal(true)
        }
    }

    const selectedTemplateComponent: TemplateComponent | undefined = useMemo(() => {
        if (selectedAnnotation) return template.components.find(component => component.name === selectedAnnotation)
    }, [selectedAnnotation, template.components])

    const SequenceVisual = useMemo(() => (
        annotations.length > 0 ? (
            <SeqViz
                viewer="circular"
                style={{ width: '100%' }}
                seq={sequence}
                annotations={annotations}
                seqType="dna"
                onSelection={onSelection}
            />
        ) : (
            // When a template could not be visualized a create a empty space
            // with same height, so all the other component are aligned
            <Box sx={{ height: '100%' }}></Box>
        )
    ), [sequence, annotations])

    const [isOverflow, setIsOverflow] = useState<boolean | undefined>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const titleRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);

    useEffect(() => {
        if (titleRef.current || descriptionRef.current) {
            setIsOverflow(titleRef.current.scrollWidth > titleRef.current.clientWidth || descriptionRef.current.scrollWidth > descriptionRef.current.clientWidth)
        }
    }, []);

    return (
        <Box sx={sx}>
            <Stack direction='column' justifyContent='center' spacing={1} sx={{ height: '100%', p: 1 }}>
                {SequenceVisual}
                <Typography fontSize={10} textAlign='center'>
                    {annotations.length > 0 ? "Click on a component to view its details." : "No components defined for this template."}
                </Typography>
                {!hideText && <Stack direction='column' sx={{ p: 2 }} spacing={1}>
                    <Typography variant="body1" textAlign='center' ref={titleRef} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Template Name: {template.name}
                    </Typography>
                    <Typography variant="body2" textAlign='center' ref={descriptionRef} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Description: {template.description}
                    </Typography>
                    {isOverflow && 
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            sx={{ alignSelf: 'center' }}>View More</Button>
                        }
                    {isModalOpen && 
                     <Modal
                     open={isModalOpen}
                     onClose={() => setIsModalOpen(false)}
                 >
                     <Box sx={modalStyle}>
                     <Typography variant="body1" textAlign='center'>
                        Template Name: {template.name}
                    </Typography>
                    <Typography variant="body2" textAlign='center'>
                        Description: {template.description}
                    </Typography>
                        </Box>
                        </Modal>
                        }
                </Stack>}
                {selectedTemplateComponent &&
                    <ComponentViewer
                        open={openModal}
                        setOpen={setOpenModal}
                        component={selectedTemplateComponent}
                    />
                }
            </Stack>
        </Box>
    )
}

export default TemplateViewer;
