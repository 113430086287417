import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { ModifiedNucleotideOption, CapMethodOption} from "../../../models/Run";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";

interface IngredientTableProps {
  data: ModifiedNucleotideOption[] | CapMethodOption[]
}

export const IngredientTable = ({data}: IngredientTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Cost (USD)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow>
              <TableCell>{getConstantDisplayName(row.name)}</TableCell>
              <TableCell>${row.cost.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
