import { Box, Button, Tab, Tabs, Typography } from "@mui/material"
import BrokerDashboardPage from "../BrokersDashboardPage/BrokersDashboardPage"
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Run } from "../../../models/Run";
import { getRuns } from "../../../api/RunQueries";
import BrokerTable from "../BrokersDashboardPage/BrokerTable";
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom'

function AdminHomePage() {
    const [activeTab, setActiveTab] = useState(0);
    const [loadingRuns, setLoadingRuns] = useState(false);
    const [runs, setRuns] = useState<Run[]>([]);
    const { getAccessTokenSilently } = useAuth0();
  
    useEffect(() => {
        getAllRuns();
      }, []);
      
    const getAllRuns = async () => {
      setLoadingRuns(true);
      const accessToken = await getAccessTokenSilently();
      const runData: Run[] | undefined = await getRuns(accessToken);
      if(runData){
        setRuns(runData);
      }
      setLoadingRuns(false);
    };

    
    const handleTabChange = (event:any, newValue:number) => {
        setActiveTab(newValue);
      };
      
      const renderComponent = () => {
        switch (activeTab) {
          case 0:
            return <BrokerTable loadingRuns={loadingRuns} runs={runs} />;
          case 1:
            return <BrokerDashboardPage loadingRuns={loadingRuns} runProps={runs} />;
        }
      };

    return (
        <Box justifyContent='center'>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>Broker Home</Typography>
            <Typography sx={{ textAlign: 'center', mt: 5 }} variant="subtitle1">Run dashboard</Typography>
            <Box sx={{position: 'fixed', bottom: '20px', right: '20px'}}>
                <Link to="/create-run">
                <Button variant="contained">Create Run <AddIcon sx={{ml:1}} /></Button>
                </Link>
            </Box>
            <Box sx={{margin: 5, marginBottom: 10}}>
            <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="List View" />
                    <Tab label="Grid View" />
            </Tabs>
            {renderComponent()}
            </Box>
        </Box>
    )
}

export default AdminHomePage
