import React, { useEffect, useState } from "react";
import { Box, Button, Tabs, Tab, Stack, CircularProgress } from '@mui/material';
import { getInput, optimizeSequence } from "../../../api/OptimizeQueries"
import { useAuth0 } from "@auth0/auth0-react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { OptimizationInput, Parameter } from "../../../models/Optimization";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import OptimizationParametersComponent, { KentParameters } from "./OptimizationParametersComponent";
import OptimizationReportComponent from "./OptimizationReportComponent";
import OptimizationApplicationComponent from "./OptimizationApplicationComponent";
import OptimizationPresetsComponent, { OptimizationPresets } from "./OptimizationPresetsComponent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface OptimizationPageLocationState {
    defaultSequence: string
    productId?: string
}

function OptimizationPage() {
    const { id } = useParams();
    const location = useLocation()
    const navigate = useNavigate()
    const { defaultSequence } = location.state as OptimizationPageLocationState ?? { defaultSequence: "" }
    const { productId } = location.state as OptimizationPageLocationState ?? { productId: "" }
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState(0)
    const [presets, setPresets] = useState<OptimizationPresets[]>([])
    const [parameters, setParameters] = useState<Parameter[]>([])


    const getTabSelected = () => {
        const tabFromURL = searchParams.get('tab')
        if (!id || !tabFromURL) {
            return 0
        }
        const tab = tabFromURL === 'result' ? 1 : 0
        return tab
    }

    const { getAccessTokenSilently } = useAuth0();

    const [currentOptimization, setCurrentOptimization] = useState<OptimizationInput | undefined>()
    const [loadingOptimization, setLoadingOptimization] = useState(false)
    const [selectedTab, setSelectedTab] = useState(getTabSelected());


    const getSeqOptInput = async () => {
        setLoadingOptimization(true)
        const accessToken = await getAccessTokenSilently()
        const optimization: OptimizationInput = await getInput(accessToken, id!)
        if (optimization) {
            setCurrentOptimization(optimization);
            setCurrentStep(2)
        }
        setLoadingOptimization(false)
    }

    useEffect(() => {
        if (id) {
            getSeqOptInput()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const optimize = async (email: string, sequence: string, sequenceType: string, kentParameters: KentParameters) => {
        const accessToken = await getAccessTokenSilently()
        const date = new Date()
        const result = await optimizeSequence(
            productId ?? "",
            accessToken,
            email,
            sequence,
            sequenceType,
            date,
            kentParameters)
        if (result) {
            setCurrentOptimization(result)
            selectTab(1)
        }
    }

    const selectTab = (tab: number) => {
        if (tab === 0) {
            setSelectedTab(0)
            setSearchParams({ 'tab': 'input' })
        }
        if (tab === 1) {
            setSelectedTab(1)
            setSearchParams({ 'tab': 'result' })
        }
    }

    return (
        <Box>
            {loadingOptimization &&
                <Box display='flex' justifyContent='center'>
                    <CircularProgress />
                </Box>}
            {!loadingOptimization &&
                <Box sx={{ mt: 7 }}>
                    <Stack direction='row' justifyContent='space-between' alignItems="center" sx={{ pr: 10, pl: 10, pb: 2 }}>
                        <Tabs value={selectedTab} onChange={(e, v) => selectTab(v)} aria-label="basic tabs example">
                            <Tab label="Input" {...a11yProps(0)} />
                            {currentOptimization &&
                                <Tab label="Result" {...a11yProps(1)} />
                            }
                        </Tabs>
                        <Button
                            onClick={() => navigate('/optimization')}
                            sx={{ height: "90%" }}
                            variant="outlined"
                            startIcon={<ChevronLeftIcon />}
                        >
                            Back to list
                        </Button>
                    </Stack>
                    <TabPanel value={selectedTab} index={0}>
                        {currentStep === 0 && <OptimizationApplicationComponent setCurrentStep={setCurrentStep} setPresets={setPresets}/>}
                        {currentStep === 1 && <OptimizationPresetsComponent setCurrentStep={setCurrentStep} presets={presets} setParameters={setParameters}/>}
                        {currentStep === 2 && <OptimizationParametersComponent setCurrentStep={setCurrentStep} optimizationInput={currentOptimization} parameters={parameters} optimizeSequence={optimize} defaultSequence={defaultSequence} />}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <OptimizationReportComponent optimizationInput={currentOptimization!} />
                    </TabPanel>
                </Box>}
        </Box>
    );
}


export default OptimizationPage;
