import { KentParameters } from '../pages/DesignerPages/OptimizationPage/OptimizationParametersComponent'
import { OptimizationInput } from '../models/Optimization';
import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';
import { ApplicationInputs, OptimizationApplication } from '../pages/DesignerPages/OptimizationPage/OptimizationApplicationComponent';
import { OptimizationPresets, PresetInputs } from '../pages/DesignerPages/OptimizationPage/OptimizationPresetsComponent';
import { CodonSelectionInputs } from '../components/KentParamatersForm/KentParamatersForm';


export const optimizeSequence = async (
    productId: string,
    accessToken: string,
    email: string,
    sequence: string,
    sequenceType: string,
    date: Date,
    parameters: KentParameters): Promise<OptimizationInput | undefined> => {
    try {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/inputs`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    productId,
                    email,
                    sequence,
                    sequenceType,
                    date,
                    parameters
                }),
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while optimizaton your sequence', severity: 'error' }))
    }
}

export const getApplicationInputs = async (accessToken: string): Promise<ApplicationInputs | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/get-application-inputs`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while configuring your optimization', severity: 'error' }))
    }
}

export const getPresets = async (
    accessToken: string, applicationParameters: OptimizationApplication): Promise<OptimizationPresets[] | undefined> => {
    try {
        const { organism, application } = applicationParameters
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/get-presets`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    organism,
                    application,
                }),
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while configuring your optimization', severity: 'error' }))
    }
}

export const getParameters = async (
    accessToken: string, preset: OptimizationPresets) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/get-preset-parameters`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({ preset }),
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while configuring your optimization', severity: 'error' }))
    }
}

export const getAllOptimizationsForUser = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/inputs`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your optimizations', severity: 'error' }))
    }
}

export const getAllOptimizations = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/inputs/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while optimizations', severity: 'error' }))
    }
}

export const getInput = async (accessToken: string, inputId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/inputs/${inputId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your optimization', severity: 'error' }))
    }
}

export const getResult = async (accessToken: string, inputId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/result/${inputId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your optimization result', severity: 'error' }))
    }
}

export const getAllResults = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/result/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting optimization results', severity: 'error' }))
    }
}

export const deleteInput = async (accessToken: string, inputId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/inputs/${inputId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while deleting your optimization', severity: 'error' }))
    }
}

export const getCodonSelectionInputs = async (accessToken: string): Promise<CodonSelectionInputs | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/get-codon-selection-inputs`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while configuring your optimization', severity: 'error' }))
    }
}

export const getPresetInputs = async (accessToken: string): Promise<PresetInputs | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/optimization/get-preset-inputs`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while configuring your optimization', severity: 'error' }))
    }
}
