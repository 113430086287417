import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
} from '@mui/material';
import { getAllCQAReportsForUser } from "../../../api/ManufacturerQueries";
import { CQAReport } from "../../../models/CQAReport";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom'
import './AllReportsPage.css'


function ReportsPage() {
    const { getAccessTokenSilently, user } = useAuth0();
    const [records, setRecords] = useState<CQAReport[]>([])

    const navigate = useNavigate()


    const rows = records.map((record: CQAReport) => {
        return (
            { id: record.id, runId: record.runReference, date: new Date(record.date), userEmail: user?.email }
        )

    })


    const getReports = async () => {
        const accessToken = await getAccessTokenSilently()
        const records: CQAReport[] = await getAllCQAReportsForUser(accessToken)
        if (records) {
            setRecords(records)
        }
    }


    useEffect(() => {
        getReports()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Typography variant="h5" textAlign='center' sx={{ mt: 5 }}>Your CQA reports</Typography>
            <Stack direction='column' spacing={5} sx={{ m: 5, mt: 10 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table sx={{ pr: 5 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Run Id</TableCell>
                                <TableCell align="right">Date</TableCell>
                                <TableCell align="right">Manufacturer Name</TableCell>
                                <TableCell align="right">Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    className="report-row"
                                    onClick={() => navigate(`/reports/${row.id}`)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.runId}
                                    </TableCell>
                                    <TableCell align="right">{row.date.toISOString().split('T')[0]}</TableCell>
                                    <TableCell align="right">{row.userEmail}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Link to="/reports/create">
                    <Button variant='contained' sx={{ height: 'fit-content' }}>
                        Submit Report
                    </Button>
                </Link>
            </Stack>
        </>
    );
}

export default ReportsPage
