import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
    Grid,
} from '@mui/material';
import './AllReportsAdminPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import { getAllCQAReports } from "../../../api/ManufacturerQueries";
import { CQAReport } from "../../../models/CQAReport";


function AllReportsAdminPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const [reports, setReports] = useState<CQAReport[]>([])

    const getReports = async () => {
        const accessToken = await getAccessTokenSilently()
        const reports = await getAllCQAReports(accessToken)
        if (reports) {
            setReports(reports)
        }
    }

    useEffect(() => {
        getReports()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Grid
                sx={{ p: 5 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign='center' variant="h5">
                        All CQA Reports
                    </Typography>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Stack direction='column' sx={{ p: 5 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Id</TableCell>
                                <TableCell align="center">Manufacturer</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Run reference</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map((report, index) => (
                                <TableRow key={index} className="report-row" onClick={() => navigate(`/reports/${report.id}`)}>
                                    <TableCell align="center" component="th" scope="row">
                                        {report.id}
                                    </TableCell>
                                    <TableCell align="center">{report.manufacturer?.institutionName}</TableCell>
                                    <TableCell align="center">{new Date(report.date).toLocaleString()}</TableCell>
                                    <TableCell align="center">{report.description}</TableCell>
                                    <TableCell align="center">{report.runReference}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}

export default AllReportsAdminPage
