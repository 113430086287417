import React, { useState, useEffect } from "react";
import { OptimizationResult } from "../../models/Optimization";
import { getAllResults } from "../../api/OptimizeQueries"
import { useAuth0 } from "@auth0/auth0-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

function OptimizationsOverTimeGraph() {
    const { getAccessTokenSilently } = useAuth0();
    const [dates, setDates] = useState<string[]>([])
    const [numberOfOptimizations, setNumberOfOptimizations] = useState<number[]>([])

    const getOptimizatioResults = async () => {
        const accessToken = await getAccessTokenSilently()
        const results = await getAllResults(accessToken)
        if (results) {
            parseResults(results)
        }
    }

    const parseResults = (results: OptimizationResult[]) => {
        const uniqueDates: string[] = []
        const numberOfOptimizationsPerDay: number[] = []
        results.forEach((result) => {
            if (result.status === "SUCCESS" || result.status === 'FAILURE') {
                // This assumes the results came ordered by date, which they do
                const date = new Date(result.date_done).toLocaleString().split(',')[0];
                if (!uniqueDates.includes(date)) {
                    uniqueDates.push(date)                    
                    if (numberOfOptimizationsPerDay.length > 0) {
                        // Add 1 to the previous number of optimizations
                        numberOfOptimizationsPerDay.push(1 + numberOfOptimizationsPerDay[numberOfOptimizationsPerDay.length -1])
                    } else {
                        numberOfOptimizationsPerDay.push(1)
                    }
                } else {
                    numberOfOptimizationsPerDay[numberOfOptimizationsPerDay.length -1] = numberOfOptimizationsPerDay[numberOfOptimizationsPerDay.length -1] + 1
                }
            }
        })
        setDates(uniqueDates)
        setNumberOfOptimizations(numberOfOptimizationsPerDay)
    }

    useEffect(() => {
        getOptimizatioResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Number of optimizations over time',
          },
        },
      };
            
      const data = {
        labels: dates,
        datasets: [
          {
            data: numberOfOptimizations,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };
    
    return (
        <Line options={options} data={data} />
    )
}

export default OptimizationsOverTimeGraph
