import { Box, Button, FormControl, InputLabel, Link, MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link as LinkRouter, useParams, useSearchParams } from 'react-router-dom'
import seqparse, { Seq } from "seqparse"
import { ComponentType } from "../../models/Product";
import { Template } from "../../models/Template";
import { TemplateComponent } from "../../models/TemplateComponent";
import { TemplateComponentOption } from "../../models/TemplateComponentOption";
import debounce from 'lodash.debounce'
import hasInvalidCharacters from "../../utils/validateSequence";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductWindow/ProductsSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { updateProduct } from "../../api/ProductQueries";
import { showAlert } from "../Alert/alertSlice";
import './ProductComponentSelector.css'

interface ProductComponentSelectorProps {
  productId: string | undefined
  template: Template | null
  updateAutoSave: (autosave: boolean) => void
}

const CUSTOM_SEQUENCE = 'Custom Sequence'

function ProductComponentSelector({ template, updateAutoSave, productId }: ProductComponentSelectorProps) {
  const { id } = useParams()
  const product = useSelector(selectProduct)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { getAccessTokenSilently } = useAuth0()
  const [component, setComponent] = useState<ComponentType>();
  const [templateComponent, setTemplateComponent] = useState<TemplateComponent>();
  const [optionVal, setOptionVal] = useState<TemplateComponentOption>()
  const [customInput, setCustomInput] = useState<string>()
  const [errorMsg, setErrorMsg] = useState<string>('')

  useEffect(() => {
    if (component) {
      setCustomInput(component.sequence)
      setErrorMsg('')
      const templateFound = template?.components.find((templateComponent) => templateComponent.name === component.name)
      if (templateFound) {
        setTemplateComponent(templateFound)

        if (component.sequence.length > 0) {
          const componentOption = templateFound.options?.find((item: any) => item.value === component.sequence)
          if (componentOption) {
            setOptionVal(componentOption)
          } else {
            setOptionVal({ name: CUSTOM_SEQUENCE, value: component.sequence })
          }
        } else {
          setOptionVal(undefined)
        }
      }
    }
  }, [component, template])

  useEffect(() => {
    const componentName = searchParams.get('component')
    if (!componentName) {
      if (product?.components) {
        setComponent(product.components.at(0))
      }
    } else {
      const componentFound = product?.components.find((component) => component.name === componentName)
      if (componentFound) {
        setComponent(componentFound)
      }
    }

  }, [product, searchParams, id])

  const checkSequenceAndSet = (sequence: string) => {
    const invalidCharacters = hasInvalidCharacters(sequence, "dna")
    if (!invalidCharacters) {
      setCustomInput(sequence)
      setErrorMsg('')
      return true
    } else {
      setErrorMsg("Invalid sequence entered: " + invalidCharacters)
      return false
    }
  }

  const handleSequenceSet = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (checkSequenceAndSet(e.target.value)) {
      updateAutoSave(true)
      receiveCustomInput(component!.position, e.target.value)
    }
  }


  const updateProductComponent = async (position: number, option: any) => {
    if (id && Array.isArray(product?.components) && product?.components?.at(position)) {
      const components = product.components.map((element) => {
        if (element.position !== position) return element
        return {
          position,
          name: element.name,
          sequence: option.value
        }
      })
      setComponent(components[position])

      let prod = { ...product }
      prod.components = components as ComponentType[]
      dispatch(setProduct(prod))

      const accessToken = await getAccessTokenSilently()
      try {
        await updateProduct(accessToken, { components }, id)
      } catch (e) {
        dispatch(setProduct(product))
      }
    }
    updateAutoSave(false)
  }

  const receiveCustomInput = useCallback(
    debounce((position: number, option: any) => {
      updateProductComponent(position, { value: option })
    }, 500)
    , [product])

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (typeof file !== "undefined" && file) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async (e: any) => {
        const seqParsed = await seqparse(e.target.result);
        await updateUserInput(seqParsed)
        updateAutoSave(false)
      }
    }
    event.target.files = null;
  };

  const updateUserInput = async (seq: Seq) => {
    if (seq.type == 'unknown') {
      sendAlert("It seems an error occurred while parsing your file.", "error")
      return
    }
    if (seq.type != "dna") {
      sendAlert("You cannot use a non-DNA sequence as sequence input.", "error")
      return
    }
    if (checkEmptySequence(seq.seq)) {
      sendAlert("The sequence seems to be empty. Try again using a valid sequence.", "error")
      return
    }
    if (component) updateProductComponent(component.position, { value: seq.seq })
  }

  const checkEmptySequence = (sequence: string) => sequence.length === 0

  const sendAlert = (message: string, severity: "info" | "error" | "success") => dispatch(showAlert({ message, severity }))

  return (
    <Box sx={{ marginLeft: '15px', width: '90%' }}>
      {
        component && (
          <Stack direction="column" spacing={1}>
            {templateComponent?.type === "USER_INPUT" &&
              <>
                <Stack direction="row" justifyContent="end">
                  <Tooltip title="Enter sequence by uploading a genbank file (.gb, .gbk, .genbank, .ape).">
                    <label htmlFor="file-upload-button">
                      <input
                        type="file"
                        id="file-upload-button"
                        accept=".gb, .gbk, .genbank, .ape"
                        multiple={false}
                        style={{ display: "none" }}
                        onChange={handleFileInput}
                      />
                      <Button variant="outlined" size="small" component="span" disabled={product?.runIsReserved}>
                        Upload sequence
                      </Button>
                    </label>
                  </Tooltip>
                </Stack>
                <TextField
                  label={templateComponent.isOptional ? "This component is optional" : `Enter ${component.name} sequence`}
                  multiline
                  autoComplete="off"
                  value={customInput}
                  disabled={product?.runIsReserved}
                  onChange={(e) => handleSequenceSet(e)}
                  rows={8}
                  color={errorMsg ? 'error' : 'primary'}
                />
              {templateComponent.isOptimizable && !product?.runIsReserved &&
              <LinkRouter
                to="/optimization/new"
                state={{ defaultSequence: component.sequence, productId }}
                style={{ width: 'fit-content'}}
              >
                <Link sx={{ fontSize: 13 }} >
                  Run optimization
                </Link>
              </LinkRouter>
            }
              </>
            }
            {(templateComponent?.type === "OPTIONS_OR_USER_INPUT" || templateComponent?.type === "FIXED_OPTIONS") &&
              <>
                <Box sx={{ height: '300px' }}>
                  <Typography sx={{ color: 'primary.main', mb: 2 }} variant="body2">OPTIONS</Typography>
                  <FormControl sx={{ width: '100%' }} size="small">
                    <InputLabel size="small">{optionVal?.name}</InputLabel>
                    <Select
                      label={optionVal?.name}
                      size="small"
                      value={optionVal ?? null}
                      disabled={product?.runIsReserved}
                      onChange={(e: any) => {
                        updateAutoSave(true)
                        updateProductComponent(component.position, e.target.value)

                      }}
                      MenuProps={{
                        style: { width: 200 } // Value doesn't matter. This makes the menu item the same width as the select.
                      }}
                      renderValue={(option) => {
                        if (option) {
                          if (option.name === CUSTOM_SEQUENCE) {
                            return (
                              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                <span className="component-name">Custom Sequence</span>
                              </div>
                            )
                          }
                          return (
                            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                              <span className="component-name">{option.name}</span>
                              {option.description &&
                                <>
                                  <span className="separator">-</span>
                                  <span className="component-description">{option?.description}</span>
                                </>}
                            </div>

                          )
                        }
                        return (<div></div>)
                      }}
                    >
                      {templateComponent?.options?.map((option: any) => {
                        return (
                          <MenuItem sx={{ whiteSpace: 'normal' }} key={option.name} value={option} >

                            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                              <span className="component-name">{option.name}</span>
                              {option.description &&
                                <>
                                  <span className="separator">-</span>
                                  <span className="component-description">{option?.description}</span>
                                </>}
                            </div>

                          </MenuItem>

                        )
                      })}

                    </Select>
                  </FormControl>
                  <TextField
                    label={templateComponent.isOptional ? "This component is optional" : `${component.name} sequence`}
                    sx={{ mt: 2, width: '100%' }}
                    multiline
                    autoComplete="off"
                    value={customInput}
                    disabled={product?.runIsReserved || templateComponent.type === 'FIXED_OPTIONS'}
                    onChange={(e) => handleSequenceSet(e)}
                    minRows={6}
                    color={errorMsg ? 'error' : 'primary'}
                  />
                </Box>
              </>
            }
            {(templateComponent?.type === "FIXED" || templateComponent?.type === "SEQUENCE_TBD") &&
              <TextField
                sx={{ mt: 2 }}
                label={`${component.name} sequence`}
                multiline
                minRows={8}
                autoComplete="off"
                value={templateComponent.value}
                disabled
              />
            }
            <Typography variant="body2" color='error'>{errorMsg}</Typography>
            
          </Stack>

        )
      }
    </Box>
  )
}

export default ProductComponentSelector
