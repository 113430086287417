import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Box,
    Typography,
    Stack,
    Button,
    Link,
    Card,
    CardContent,
    Grid
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormInputCheckbox, FormInputSelect } from "../../../components/FormComponents";
import { getParameters, getPresetInputs } from "../../../api/OptimizeQueries";
import { Parameter } from "../../../models/Optimization";
import { showAlert } from "../../../components/Alert/alertSlice";
import store from "../../../store";

interface OptimizationPresetsCardProps {
    preset: OptimizationPresets,
    isSelected: boolean,
    setSelected: () => void
}

const parseParameters = (parameters: any): Parameter[] => {
    return parameters.map((parameter: any) => {
        if (parameter.name === "CodonSelectionCriteria") {
            return {
                name: parameter.name,
                weight: parameter.weight,
                mode: parameter.mode,
                organism: parameter.df_codons_parameters.organism,
                cell_type: parameter.df_codons_parameters.cell_type,
                parset_name: parameter.df_codons_parameters.parsetname,
            }
        } else {
            return parameter
        }
    })
}


const OptimizationPresetsCard = ({ preset, isSelected, setSelected }: OptimizationPresetsCardProps) => {
    const backgroundColor = isSelected ? "#f0f0f0" : "white"
    return (
        <Card
            sx={{ minHeight: 300, borderRadius: "16px", cursor: 'pointer', backgroundColor: backgroundColor }}
            onClick={setSelected}

        >
            <CardContent>
                {preset.description &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Description: </b>{preset.description}
                    </Typography>
                }
                {preset.organism &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Organism: </b>{preset.organism}
                    </Typography>
                }
                {preset.application &&
                    <Typography sx={{ fontSize: 14 }} className="text.secondary" color="text.secondary" gutterBottom>
                        <b>Application: </b>{preset.application}
                    </Typography>
                }
                {preset.delivery &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                       <b>Delivery: </b>{preset.delivery}
                    </Typography>
                }
                {preset.destination &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Cellular destination: </b>{preset.destination}
                    </Typography>
                }
                {preset.nucleotide_modifications &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Nucleotide modifications: </b>{preset.nucleotide_modifications}
                    </Typography>
                }
            </CardContent>
        </Card>
    )
}

export interface OptimizationPresets {
    appid?: number,
    application: string,
    delivery: string,
    destination: string,
    organism: string,
    presetid?: number
    description?: string
    nucleotide_modifications?: string
}



export interface PresetInputs {
    // {
    //     "applications": {
    //         "Human": [
    //             {
    //                 "appid": 1,
    //                 "name": "Vaccine",
    //                 "taxid": 9606
    //             },
    //         ]
    //     },
    //     "deliveries": [
    //         {
    //             "delivery": "IM",
    //             "deliveryid": 1
    //         },
    //     ],
    //     "destinations": [
    //         {
    //             "destination": "Lung Fibroblasts",
    //             "destinationid": 2
    //         }
    //     ]
    // }
    applications: { [organism: string]: { appId: number, name: string, taxId: number }[] },
    deliveries: { delivery: string, deliveryId: number }[],
    destinations: { destination: string, destinationId: number }[],
}

interface OptimizationParametersComponentProps {
    presets: OptimizationPresets[]
    setParameters: (parameters: Parameter[]) => void
    setCurrentStep: (step: number) => void
}

function OptimizationPresetsComponent({ presets, setParameters, setCurrentStep }: OptimizationParametersComponentProps) {
    const { getAccessTokenSilently } = useAuth0();
    const [gettingParameters, setGettingParameters] = useState(false)
    const [selectedPresetIndex, setSelectedPresetIndex] = useState<number | undefined>()
    const [usingCustomPresets, setUsingCustomPresets] = useState(false)
    const [applicationForOrganismMap, setApplicationForOrganismMap] = useState<{ [organism: string]: { appId: number, name: string, taxId: number }[] }>({})
    const [deliveryOptions, setDeliveryOptions] = useState<string[]>([])
    const [destinationOptions, setDestinationOptions] = useState<string[]>([])

    useEffect(() => {
        getPresetsOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPresetsOptions = async () => {
        const accessToken = await getAccessTokenSilently()
        const presetInputs: PresetInputs | undefined = await getPresetInputs(accessToken)
        if (presetInputs) {
            setApplicationForOrganismMap(presetInputs.applications)
            setDeliveryOptions(presetInputs.deliveries.map((delivery) => delivery.delivery))
            setDestinationOptions(presetInputs.destinations.map((destination) => destination.destination))
        }
    }

    const schema = z.object({
        organism: z.string().min(1, { message: "Organims is required" }),
        application: z.string().min(1, { message: "Application is required" }),
        delivery: z.string().min(1, { message: "Delivery is required" }),
        destination: z.string().min(1, { message: "Destination is required" }),
        avoidFrameshift: z.boolean().optional(),
        optimizeManufacturability: z.boolean().optional(),
    })

    type FormFields = z.infer<typeof schema>;

    const { control, handleSubmit, watch } = useForm<FormFields>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {},
    });

    const organismValue = watch("organism");

    const organismOptions = useMemo(() => applicationForOrganismMap ? Object.keys(applicationForOrganismMap).map(organism => ({ value: organism, label: organism })) : [], [applicationForOrganismMap])
    const applicationOptions = useMemo(() => applicationForOrganismMap[organismValue] ? applicationForOrganismMap[organismValue].map(application => ({ value: application.name, label: application.name })) : [], [applicationForOrganismMap, organismValue])



    const selectedPresetsNext = async () => {
        setGettingParameters(true)
        const accessToken = await getAccessTokenSilently()
        if (selectedPresetIndex !== undefined) {
            const selectedPreset = presets[selectedPresetIndex]
            const parameters = await getParameters(accessToken, selectedPreset)
            if (parameters) {
                setParameters(parseParameters(parameters))
                setCurrentStep(2)
            }
        }
        setGettingParameters(false)
    }

    const customPresetsNext = async (data: FormFields) => {
        setGettingParameters(true)
        const accessToken = await getAccessTokenSilently()
        const parameters = await getParameters(
            accessToken, {
            organism: data.organism,
            application: data.application,
            delivery: data.delivery,
            destination: data.destination
        })
        if (parameters) {
            if (parameters.length === 0) {
                store.dispatch(showAlert({ message: 'No parameters found for this configuration. Choose another one or go to advanced mode.', severity: 'error' }))
            } else {
                setParameters(parseParameters(parameters))
                setCurrentStep(2)
            }
        }
        setGettingParameters(false)
    }

    return (
        <>
            <Typography variant="h6" textAlign="center" sx={{ mt: 1 }}>
                Configure optimization
            </Typography>
            {!usingCustomPresets && <Typography variant="body1" textAlign="center" sx={{ mt: 2 }}>
                {presets.length ? "Select one of the following configurations" : "No pre-defined paramters for this configutation"}
            </Typography>
            }
            <Box display="flex" justifyContent='center' alignItems="center" sx={{ mt: 2, flexDirection: "column" }}>
                {!usingCustomPresets ?
                    <Stack direction="column" alignItems="center" sx={{ width: "90%" }}>
                        <Grid container rowSpacing={3} columnSpacing={3} sx={{ p: 5 }}>
                            {presets.map((preset, index) => {
                                return (
                                    <Grid item md={5}>
                                        <OptimizationPresetsCard
                                            preset={preset}
                                            isSelected={index === selectedPresetIndex}
                                            setSelected={() => setSelectedPresetIndex(index)} />
                                    </Grid>
                                )
                            })}
                            {/* <Grid item md={4}>
                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                                    <Button variant="outlined" onClick={() => setUsingCustomPresets(true)}>
                                        {presets.length ? "Or create your own" : "Create your own"}
                                    </Button>
                                </Stack>
                            </Grid> */}
                        </Grid>
                        <Stack direction='row' spacing={2}>
                            <Button
                                sx={{ width: 'fit-content' }}
                                variant="outlined"
                                onClick={() => setCurrentStep(0)}
                            >
                                Back
                            </Button>
                            <LoadingButton
                                sx={{ width: 'fit-content' }}
                                variant="contained"
                                onClick={selectedPresetsNext}
                                disabled={selectedPresetIndex === undefined}
                                loading={gettingParameters}
                            >
                                Next
                            </LoadingButton>
                        </Stack>
                    </Stack>
                    :
                    <form noValidate onSubmit={handleSubmit((data: FormFields) => customPresetsNext(data))} style={{ width: '30%' }}>
                        <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={2} >
                            <FormInputSelect
                                name="organism"
                                size="small"
                                control={control}
                                label="Organism"
                                required={true}
                                options={organismOptions}
                            />
                            <FormInputSelect
                                name="application"
                                size="small"
                                control={control}
                                label="Application"
                                required={true}
                                disabled={!organismValue}
                                options={applicationOptions}
                            />
                            <FormInputSelect
                                name="delivery"
                                size="small"
                                control={control}
                                label="Delivery"
                                required={true}
                                options={deliveryOptions.map((delivery) => ({ value: delivery, label: delivery }))}
                            />
                            <FormInputSelect
                                name="destination"
                                size="small"
                                control={control}
                                label="Destination"
                                required={true}
                                options={destinationOptions.map((destination) => ({ value: destination, label: destination }))}
                            />
                            <FormInputCheckbox
                                name="avoidFrameshift"
                                control={control}
                                label="Avoid frameshift"
                            />
                            <FormInputCheckbox
                                name="optimizeManufacturability"
                                control={control}
                                label="Optimize Manufacturability"
                            />
                            <Stack direction='row' spacing={2} justifyContent='center'>
                                <Button
                                    variant="outlined"
                                    onClick={() => setUsingCustomPresets(false)}
                                >
                                    Back
                                </Button>
                                <LoadingButton
                                    variant="contained"
                                    loading={gettingParameters}
                                    type="submit"
                                >
                                    Next
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </form>}
                <Link sx={{ fontSize: 13, textAlign: "center", mt: 3, cursor: 'pointer' }} onClick={() => setCurrentStep(2)}>
                    Skip configuration and go to advanced mode
                </Link>
            </Box>
        </>
    );
}

export default OptimizationPresetsComponent
