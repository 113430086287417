import { CQAReport } from "./CQAReport";
import { Designer } from "./Designer";
import { Manufacturer } from "./Manufacturer";
import { Process } from "./Process";
import { Product } from "./Product";

export enum RunStatus {
    PUBLISHED = "PUBLISHED",
    DRAFTED = "DRAFTED",
    RESERVED = "RESERVED",
    ON_REVIEW = "ON_REVIEW",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    ARCHIVED = "ARCHIVED",
}

export enum ModifiedNucleotides {
    PSEUDO_URIDINE = 'PSEUDO_URIDINE',
    METHYL_RNA = 'METHYL_RNA',
    LOCKED_NUCLEIC_ACID = 'LOCKED_NUCLEIC_ACID',
    PHOSPHOROTHIAOATE = 'PHOSPHOROTHIAOATE',
    FLUORO_RNA = 'FLUORO_RNA',
    MODIFIED_CYTOSINE = 'MODIFIED_CYTOSINE',
    NONE = 'NONE'
}

export enum CappingMethod {
    CLEAN_CAP_AG = 'CLEAN_CAP_AG',
    CLEAN_CAP_UG = 'CLEAN_CAP_UG',
    ARCA_CAP = 'ARCA_CAP',
    NONE = 'NONE'
}

type RangeFloat = {
    min: number;
    max: number;
};

export type ModifiedNucleotideOption = {
    name: ModifiedNucleotides;
    cost: number;
};

export type CapMethodOption = {
    name: CappingMethod;
    cost: number;
};

export interface Run {
    id?: string;
    name: string;
    status: RunStatus;
    description?: string;
    quantity: RangeFloat;
    baseCost: number;
    dateToSubmit: Date;
    dateToStart?: Date;
    dateToShip: Date;
    modifiedNucOptions: ModifiedNucleotideOption[];
    capMethodOptions: CapMethodOption[];
    formulation: boolean;
    formulationDetails?: string;
    dnaSynthesis: boolean;
    process?: Process;
    processId?: string;
    manufacturer?: Manufacturer;
    manufacturerId: string;
    designer?: Designer;
    products?: Product[];
    finalProductId?: string;
    cqaReport?: CQAReport
    requireApproval: boolean
}
