import {
    Card,
    CardContent,
    Typography,
    Stack,
    Button,
    Chip
  } from "@mui/material";
  import { Link } from "react-router-dom";
  import { Product } from "../../../models/Product";
  
  import { CapMethodOption, ModifiedNucleotideOption, Run, RunStatus } from "../../../models/Run";
import { useMemo } from "react";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";
import { runStatusColors } from "../../AdminPages/BrokersDashboardPage/RunCard";
  
  const getFormattedDate = (date: string) => {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleDateString("en-US");
  };


  export function RunCardDetails({ run, product }: { run: Run, product: Product }) {

    const totalCost = useMemo(() => {
      let cost = product!.run!.baseCost
      if (product?.cappingMethod) {
        product?.run?.capMethodOptions?.forEach((option: CapMethodOption) => {
          if (option.name === product?.cappingMethod) {
            cost += option.cost
          }
        })
      }
      if (product?.modifiedNucleotides) {
        product?.run?.modifiedNucOptions?.forEach((option: ModifiedNucleotideOption) => {
          if (option.name === product?.modifiedNucleotides) {
            cost += option.cost
          }
        })
      }
      return cost
    }, [product])

    return (
      <>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Total cost: ${totalCost.toFixed(2)} USD
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Date to submit by: {getFormattedDate(String(run.dateToSubmit))}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Shipping date: {getFormattedDate(String(run.dateToShip))}
        </Typography>
        <Typography 
          sx={{ fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} 
          color="text.secondary" 
          gutterBottom
        >
          Construct Name: {product.name}
        </Typography>
          <Link to={`/constructs/${product.id}`}>
            <Button sx={{mt:2}} variant='contained'>View Construct</Button>
          </Link>
      </>
    )
  
  }
  
  interface RunCardProps {
    run: Run;
    product: Product;
  }

  
  function DesignerRunCard({ run, product }: RunCardProps) {
  
    return (
      <Card
        sx={{
          minHeight: 230,
          m: 2,
          p: 3,
          borderRadius: "16px",
        }}
      >
        <CardContent>
          <Stack sx={{ mb: 1 }} direction="row" alignItems='center' justifyContent='space-between'>
            <Typography
              sx={{ 
                fontSize: 20, 
                fontWeight: 16, 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              color="text.primary"
              gutterBottom
              
            >
              {run.name}
            </Typography>
            <Chip 
              label={getConstantDisplayName(run.status)} 
              sx={{backgroundColor: runStatusColors[run.status as RunStatus], color: 'white'}}
            />
          </Stack>
          <RunCardDetails run={run} product={product} />
        </CardContent>
      </Card>
    );
  }
  
  export default DesignerRunCard;
  