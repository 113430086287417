import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
    Grid,
} from '@mui/material';
import './AllDesignersPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Designer } from "../../../models/Designer";
import { getAllDesigners } from "../../../api/DesignerQueries";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";


function AllDesignersPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const [designers, setDesigners] = useState<Designer[]>([])

    const getDesigners = async () => {
        const accessToken = await getAccessTokenSilently()
        const designers = await getAllDesigners(accessToken)
        if (designers) {
            setDesigners(designers)
        }
    }

    useEffect(() => {
        getDesigners()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Grid
                sx={{ p: 5 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign='center' variant="h5" sx={{ mt: 5 }}>
                        All Designers
                    </Typography>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Stack direction='column' sx={{ p: 5 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Phone number</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Institution</TableCell>
                                <TableCell align="center">Address</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {designers.map((designer, index) => (
                                <TableRow key={index} className="designer-row" >
                                    <TableCell component="th" scope="row">
                                        {`${designer.firstName} ${designer.lastName}`}
                                    </TableCell>
                                    <TableCell align="center">{designer.phoneNumber}</TableCell>
                                    <TableCell align="center">{designer.email}</TableCell>
                                    <TableCell align="center">{designer.institution}</TableCell>
                                    <TableCell align="center">{designer.address}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}

export default AllDesignersPage
