export enum ProductActions {
  SET_UPDATE = 'SET_UPDATE'
}

interface ProductState {
  update: boolean 
}

interface ProductPayload {
  update: boolean 
}

interface ProductAction {
  type: ProductActions;
  payload: ProductPayload
}

export function reducer(state: ProductState, action: ProductAction) {
  switch(action.type) {
    case ProductActions.SET_UPDATE:
      return {
        ...state,
        update: action.payload.update
      }
    default:
      return state
  }
}

