import { useAuth0 } from "@auth0/auth0-react";
import { Stack, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductsSlice";
import { updateProduct } from "../../../api/ProductQueries";
import { Product } from "../../../models/Product";
import './ProductGeneralStep.css'

interface ProductGeneralStepProps {
    setUpdateSideBar?: (update: boolean) => void
}

const ProductGeneralStep = ({ setUpdateSideBar }: ProductGeneralStepProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatchGlobal = useDispatch()

    const { id } = useParams()

    const product = useSelector(selectProduct)

    useEffect(() => {
        if (product) {
            setProductGeneralInfo({
                name: product?.name,
                description: product?.description,
                quantity: product?.quantity
            })
        }
    }, [product])

    const [productGeneralInfo, setProductGeneralInfo] = useState({
        name: product?.name,
        description: product?.description,
        quantity: product?.quantity
    })

    const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

    const handleInputChange = (field: string, value: string | undefined) => {

        setProductGeneralInfo((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));

        if (debounceTimeout !== null) clearTimeout(debounceTimeout);

        const newDebounceTimeout = setTimeout(async () => {
            await updateProductQuery({ ...productGeneralInfo, [field]: value });
        }, 500);

        setDebounceTimeout(newDebounceTimeout);
    };

    useEffect(() => {
        if (id && product?.id === id) {
            setProductGeneralInfo({
                name: product?.name,
                description: product?.description,
                quantity: product?.quantity
            })
        }
    }, [id, product?.id])

    const updateProductQuery = async (partialProduct: Partial<Product>) => {
        if (id && setUpdateSideBar) {
            const accessToken = await getAccessTokenSilently()
            const updatedProduct = await updateProduct(accessToken, partialProduct, id)
            if (updatedProduct) dispatchGlobal(setProduct(updatedProduct))

            if (partialProduct.name !== product?.name) {
                setUpdateSideBar(true)
            }
        }
    }

    const checkQuantityRange = useMemo(() => {
        const run = product?.run
        const quantity = productGeneralInfo.quantity
        if (run && quantity) {
            const { min, max } = run.quantity
            if (parseFloat(quantity) < min || parseFloat(quantity) > max) {
                return `Quantity (mg) should be greater than ${min} and less then ${max}.`
            }
        }
    }, [productGeneralInfo.quantity])

    return (
        <Stack direction='column' spacing={8} alignItems='center' sx={{ width: '100%', pb: 2 }}>
            <TextField
                label='Construct name'
                size="small"
                autoComplete="off"
                required={true}
                sx={{ width: '51%' }}
                value={productGeneralInfo.name ?? ''}
                disabled={product?.runIsReserved}
                onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <TextField
                label='Construct description'
                size="small"
                autoComplete="off"
                multiline
                rows={4}
                sx={{ width: '51%' }}
                value={productGeneralInfo.description ?? ''}
                disabled={product?.runIsReserved}
                onChange={(e) => handleInputChange("description", e.target.value)}
            />
            <TextField
                error={typeof checkQuantityRange !== 'undefined'}
                label='Quantity (mg)'
                required={true}
                size='small'
                type='number'
                sx={{ width: '51%' }}
                helperText={checkQuantityRange}
                value={productGeneralInfo.quantity ?? ""}
                disabled={product?.runIsReserved}
                onChange={(e) => handleInputChange("quantity", e.target.value)}
                autoComplete="off"
            />
        </Stack>
    )
}

export default ProductGeneralStep
