import { Grid } from '@mui/material';
import { useReducer } from 'react';
import ProductSidebar from "../../../components/ProductSidebar/ProductSidebar";
import ProductWindow from "../../../components/ProductWindow/ProductWindow";
import { ProductActions, reducer } from './productReducer';

function ProductPage() {
  const [state, dispatch] = useReducer(reducer, { update: false })

  const setUpdate = (update: boolean) => {
    dispatch({ type: ProductActions.SET_UPDATE, payload: { update } })
  }
  return (
    <Grid container sx={{ height: "100%", overflow: 'auto' }}>
      <Grid item md={2} sx={{ backgroundColor: 'secondary.main',  height: "100%", overflow: 'auto' }}>
        <ProductSidebar update={state.update} setUpdate={setUpdate} />
      </Grid>
      <Grid item md={10} sx={{ height: "100%", overflow: 'auto' }}>
        <ProductWindow update={state.update} setUpdate={setUpdate} />
      </Grid>
    </Grid>
  );
}



export default ProductPage
