import {
  Box,
  Typography,
} from '@mui/material';

export default function EmptyWorkspace() {
    return (
    <Box style={{ width: '100%' }}>
      <Typography variant="h4" textAlign="center" sx={{ mt: 5, p: 5 }}>
        Your workspace is empty!
      </Typography>
      <Typography variant="h6" textAlign="center" sx={{ mt: 5 }}>
        Please select an option from the toolbar and start working.
      </Typography>

    </Box>
  )
}
