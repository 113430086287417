
type CQA = {
  name: string,
  minimumThreshold: string
  analyticalMethods: string[],
}

export const CQAList: CQA[] =
  [
    {
      "name": "Total RNA conc (mg/ml)",
      "minimumThreshold": "The higher the better",
      "analyticalMethods": ["IEX HPLC", "IP RP HPLC"]
    },
    {
      "name": "Effective RNA conc (mg/ml)",
      "minimumThreshold": "The higher the better",
      "analyticalMethods": ["IP RP HPLC"]
    },
    {
      "name": "RNA integrity (%)",
      "minimumThreshold": "≥50%",
      "analyticalMethods": ["CE", "IP RP HPLC"]
    },
    {
      "name": "RNA purity (μM NTPs)",
      "minimumThreshold": "<50 μM total NTPs",
      "analyticalMethods": ["IEX HPLC"]
    },
    {
      "name": "Residual DNA (ng/μl)",
      "minimumThreshold": "",
      "analyticalMethods": ["IEX HPLC"]
    },
    {
      "name": "dsRNA content",
      "minimumThreshold": "<10 ng dsRNA/μg (<1%)",
      "analyticalMethods": ["ELISA assay"]
    },
    {
      "name": "Sequence identity (%)",
      "minimumThreshold": "80% seqeunce coverage, mapped sequences have 100% identity",
      "analyticalMethods": ["LC MS"]
    },
    {
      "name": "PolyA tail characterisation (nt)",
      "minimumThreshold": "Average length as close as possible to DNA template. Low variance.",
      "analyticalMethods": ["LC MS"]
    },
    {
      "name": "5' capping efficiency (%)",
      "minimumThreshold": ">90% CleanCap",
      "analyticalMethods": ["LC MS"]
    },
    {
      "name": "Residual T7 RNA polymerase activity",
      "minimumThreshold": "",
      "analyticalMethods": ["IVT from purified RNA, CE"]
    }
  ]