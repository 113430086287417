import { Box, IconButton, Stack, Tooltip } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info'
import { useEffect, useMemo, useState } from "react"
import { ComponentType } from "../../models/Product"
import { getComponentsColors } from "../../utils/colors"
import ProductComponent from "./ProductComponent"
import { useParams, useSearchParams } from "react-router-dom"
import { Template } from "../../models/Template"
import "./ComponentsSidebar.css"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ComponentsSidebarProps {
  components: Array<ComponentType>
  template: Template
}

const ComponentsSidebar = ({ components, template }: ComponentsSidebarProps) => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const [currentComponentName, setCurrentComponentName] = useState<string>("")

  const componentsColors = useMemo(() => {
    const names = components.map((component) => component.name)
    return getComponentsColors(names)
  }, [components])

  const getTemplateComponent = (component: ComponentType) => {
    return template.components.filter((element) => element.position === component.position).at(0)!
  }


  useEffect(() => {
    const componentName = searchParams.get('component')
    if (componentName) {
      if (componentName !== currentComponentName) setCurrentComponentName(componentName)
    } else {
      const firstComponentName = components.at(0)?.name
      if (firstComponentName) setCurrentComponentName(firstComponentName)
    }
  }, [searchParams, id])

  return (
    <Box sx={{ pt: 2 }}>
      {
        components.map((component: ComponentType) => (
          <Stack
            key={component.name}
            sx={currentComponentName === component.name ? { backgroundColor: 'primary.light' } : {}}
            direction="row"
            justifyContent="center"
            alignItems="center">

            <ProductComponent
              name={component.name}
              isEmpty={component.sequence.length === 0}
              componentColor={componentsColors.get(component.name)}
            />
            <>
              {(component.sequence.length > 0 || getTemplateComponent(component).isOptional) &&
                <IconButton size="small">
                  <CheckCircleIcon fontSize="small" color="success" />
                </IconButton>
              }
              <ProductDescription component={component} template={template} />
            </>
          </Stack>
        ))
      }
    </Box>
  )
}

interface ProductDescriptionProps {
  component: ComponentType
  template: Template
}
const ProductDescription = ({ component, template }: ProductDescriptionProps) => {

  const description = useMemo(() => {
    const templateElement = template.components.filter((element) => element.position === component.position).at(0)
    if (templateElement) return templateElement.description
    return null
  }, [component, template])

  return (
    <Tooltip title={description ?? 'Not available'}>
      <InfoIcon className="tooltip" sx={{ cursor: "pointer", float: "right" }} />
    </Tooltip>
  )
}



export default ComponentsSidebar
