import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    TablePagination,
    TextField,
    TableSortLabel
  } from '@mui/material';
import { Product } from '../../../models/Product';
import { useState } from 'react';


  const RunsTable = ({loadingRuns, products}: {loadingRuns: boolean, products: Product[]}) => {

    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(5); 
    const [filterText, setFilterText] = useState(''); 
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<'dateToSubmit' | 'dateToShip'>('dateToSubmit');

    const handleSortRequest = (property: 'dateToSubmit' | 'dateToShip') => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getFormattedDate = (date: string) => {
      const convertedDate = new Date(date);
      return convertedDate.toLocaleDateString("en-US");
    };

    const handleChangePage = (event:any, newPage:any) => {
        setPage(newPage);
      };

    const handleChangeRowsPerPage = (event:any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };


    const filteredRows = products.filter(row => row.name.toLowerCase().includes(filterText.toLowerCase()));
    
    const sortedRows = filteredRows.sort((a, b) => {
      if (a.run && b.run){
        const dateA = new Date(a.run[orderBy]);
        const dateB = new Date(b.run[orderBy]);
        return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      }

      return 0;
    });

    const currentRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    
    return (
      <Paper>
        <TextField
        label="Search Runs"
        variant="outlined"
        margin="normal"
        sx={{margin:2, width: '50%'}}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <TableContainer component={Paper}>
        {!loadingRuns && <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Construct Name</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'dateToSubmit'} 
                  direction={order}          
                  onClick={() => handleSortRequest('dateToSubmit')}  
                >
                  Date to Submit By
                </TableSortLabel>
                </TableCell>
              <TableCell>
              <TableSortLabel
                  active={orderBy === 'dateToShip'} 
                  direction={order}          
                  onClick={() => handleSortRequest('dateToShip')}  
                >
                Shipping Date
                </TableSortLabel>
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.run?.name}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>${product.run?.baseCost} USD</TableCell>
                <TableCell>{getFormattedDate(String(product.run?.dateToSubmit))}</TableCell>
                <TableCell>{getFormattedDate(String(product.run?.dateToShip))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </TableContainer>
      <TablePagination
        component="div"
        count={products.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
   </Paper>
    );
  };
  
  export default RunsTable;