import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    TablePagination,
    TextField,
    TableSortLabel
  } from '@mui/material';
import { useState } from 'react';
import { Run } from '../../../models/Run';


  const BrokerTable = ({loadingRuns, runs}: {loadingRuns: boolean, runs: Run[]}) => {

    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(5); 
    const [filterText, setFilterText] = useState(''); 
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<'dateToSubmit' | 'dateToShip'>('dateToSubmit');

    const handleSortRequest = (property: 'dateToSubmit' | 'dateToShip') => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const getFormattedDate = (date: string) => {
      const convertedDate = new Date(date);
      return convertedDate.toLocaleDateString("en-US");
    };

    const handleChangePage = (event:any, newPage:any) => {
        setPage(newPage);
      };

    const handleChangeRowsPerPage = (event:any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };


    const filteredRows = runs.filter(row => row.name.toLowerCase().includes(filterText.toLowerCase()));
    const sortedRows = filteredRows.sort((a, b) => {
        const dateA = new Date(a[orderBy]);
        const dateB = new Date(b[orderBy]);
        return order === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      });

    const currentRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    
    return (
      <Paper>
        <TextField
        label="Search Runs"
        variant="outlined"
        margin="normal"
        sx={{margin:2, width: '50%'}}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <TableContainer component={Paper}>
        {!loadingRuns && <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Total Cost</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'dateToSubmit'} 
                  direction={order}          
                  onClick={() => handleSortRequest('dateToSubmit')}  
                >
                  Date to Submit By
                </TableSortLabel>
                </TableCell>
              <TableCell>
              <TableSortLabel
                  active={orderBy === 'dateToShip'} 
                  direction={order}          
                  onClick={() => handleSortRequest('dateToShip')}  
                >
                Shipping Date
                </TableSortLabel>
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((run, index) => (
              <TableRow key={index}>
                <TableCell>{run.name}</TableCell>
                <TableCell>${run.baseCost} USD</TableCell>
                <TableCell>{getFormattedDate(String(run.dateToSubmit))}</TableCell>
                <TableCell>{getFormattedDate(String(run.dateToShip))}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </TableContainer>
      <TablePagination
        component="div"
        count={runs.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
   </Paper>
    );
  };
  
  export default BrokerTable;