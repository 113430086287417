import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    TextField,
    Button,
    Grid,
    Link,
    Slider,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SpliceSitePredicitonResult } from "../../../models/SpliceSitePrediction";
import { LoadingButton } from "@mui/lab";
import { predictSpliceSitePredictions } from "../../../api/SpliceSitePrediction";
import { showAlert } from "../../../components/Alert/alertSlice";
import store from "../../../store";


function SpliceSitePredictorPage() {
    const { getAccessTokenSilently } = useAuth0();
    const [result, setResult] = useState<SpliceSitePredicitonResult | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const [sequence, setSequence] = useState("")
    const [reliability, setReliability] = useState(90)

    const DEFAULT_SIZE = 200


    const findSpliceSites = async () => {
        if (sequence.length < DEFAULT_SIZE) {
            store.dispatch(showAlert({ message: `Your sequence should contain at least ${DEFAULT_SIZE} bps.`, severity: 'error' }))
            return
        }
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        const input = {
            sequence,
            donorSize: DEFAULT_SIZE,
            acceptorSize: DEFAULT_SIZE,
            donorReliability: reliability,
            acceptorReliability: reliability
        }

        const response = await predictSpliceSitePredictions(accessToken, input)
        if (response) {
            setResult(response)
        }
        setLoading(false)
    }

    return (
        <>
            <Typography textAlign='center' variant="h5" sx={{ m: 5 }}>
                Splice site predictor
            </Typography>
            {result === undefined ?
                <Stack direction='column' spacing={5} sx={{ pl: 10, pr: 10 }} alignItems='center'>
                    <TextField
                        value={sequence}
                        onChange={(e) => setSequence(e.target.value)}
                        className="text-box"
                        label="Enter your sequence here"
                        autoComplete="off"
                        multiline
                        fullWidth
                        minRows={5}
                        variant="outlined" />
                    <Stack direction='row' spacing={3} sx={{ minWidth: '40%' }}>
                        <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                            Confidence threshold
                        </Typography>
                        <Slider
                            defaultValue={reliability}
                            min={50}
                            valueLabelDisplay="on"
                            onChange={(e, value) => { setReliability(value as number) }}
                        />
                    </Stack>
                    <LoadingButton
                        sx={{ width: '50%' }}
                        loading={loading}
                        onClick={() => findSpliceSites()}
                        variant='contained'
                    >
                        Predict splice sites
                    </LoadingButton>
                    <Typography variant="subtitle1" textAlign="left" sx={{ p: 5 }}>
                        Under the hood the splice site predictor utilizes SPLICEATOR, an eukaryotic splice sites prediction program. It is based on convolutional neural network architecture trained on a multi-species dataset. <b>Reference:</b> <i>Scalzitti, N., Kress, A., Orhand, R. et al.</i> <Link href="https://doi.org/10.1186/s12859-021-04471-3">Spliceator: multi-species splice site prediction using convolutional neural networks. BMC Bioinformatics 22, 561 (2021).</Link>
                    </Typography>
                </Stack>
                :
                result.acceptors.length > 0 && result.donors.length > 0 ? <Stack direction='column' spacing={5} sx={{ m: 5 }} alignItems='center'>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TableContainer component={Paper}>
                                <Table sx={{ pr: 5 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Donor</TableCell>
                                            <TableCell align="center">Position</TableCell>
                                            <TableCell align="center">Probability</TableCell>
                                            <TableCell align="center">Sequence</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result!.donors.map((site, index) => (
                                            <TableRow key={index} >
                                                <TableCell align="center">{`Donor #${index + 1}`}</TableCell>
                                                <TableCell align="center">{site.position}</TableCell>
                                                <TableCell align="center">{site.probability}</TableCell>
                                                <TableCell align="center">{site.sequence}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item md={6}>
                            <TableContainer component={Paper}>
                                <Table sx={{ pr: 5 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Acceptor</TableCell>
                                            <TableCell align="center">Position</TableCell>
                                            <TableCell align="center">Probability</TableCell>
                                            <TableCell align="center">Sequence</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result!.acceptors.map((site, index) => (
                                            <TableRow key={index} >
                                                <TableCell align="center">{`Acceptor #${index + 1}`}</TableCell>
                                                <TableCell align="center">{site.position}</TableCell>
                                                <TableCell align="center">{site.probability}</TableCell>
                                                <TableCell align="center">{site.sequence}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Button
                        sx={{ width: '50%' }}
                        onClick={() => setResult(undefined)}
                        variant='contained'
                    >
                        Run again
                    </Button>
                </Stack>
               : 
               <Stack direction='column' spacing={5} sx={{ m: 5, marginTop: '10%'}} alignItems='center'>
                    <Typography variant="h4">No splice sites found</Typography>
                    <Button
                        sx={{ width: '10%' }}
                        onClick={() => setResult(undefined)}
                        variant='contained'
                    >
                        Run again
                    </Button>
                </Stack>
            }   
        </>
    );
}

export default SpliceSitePredictorPage
