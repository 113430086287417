import { Box, Grid, Stack, Typography, Tabs, Tab, Button} from "@mui/material"
import ProfilePage from "../ProfilePage/ProfilePage"
import RunsDash from "../RunsPage/RunsDash"
import { useEffect, useState } from "react";
import { Product } from "../../../models/Product";
import { getProducts } from "../../../api/ProductQueries";
import { useAuth0 } from "@auth0/auth0-react";
import RunsTable from "../RunsPage/RunsTable";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

function DesignerHomePage() {
    const [activeTab, setActiveTab] = useState(0);
    const [loadingRuns, setLoadingRuns] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const { getAccessTokenSilently } = useAuth0();
  
    useEffect(() => {
        getAllRuns();
      }, []);
      
    const getAllRuns = async () => {
      setLoadingRuns(true);
      const accessToken = await getAccessTokenSilently();
      const productData: Product[] | undefined = await getProducts(accessToken);
  
      if (productData) {
        const filteredProducts = productData.filter((product: Product) => product.runIsReserved)
        setProducts(filteredProducts)
      }
  
      setLoadingRuns(false);
    };

    const handleTabChange = (event:any, newValue:number) => {
        setActiveTab(newValue);
      };
      
      const renderComponent = () => {
        switch (activeTab) {
          case 0:
            return <RunsTable loadingRuns={loadingRuns} products={products} />;
          case 1:
            return <RunsDash loadingRuns={loadingRuns} products={products} />;
        }
      };
    
    return (
        <Box justifyContent='center' sx={{ mb: 10 }}>
            <Typography textAlign='center' variant="h5" sx={{ mt: 5, mb: 2}}>
                Designer Home</Typography>
            <Grid container spacing={2}>
            <Box sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
              <Link to="/constructs">
              <Button variant="contained">Reserve a Run <AddIcon sx={{ ml: 1 }} /></Button>
              </Link>
              </Box>
                <Grid item xs={4}>
                    <ProfilePage />
                </Grid>
                <Grid item xs={8}>
                <Typography variant="h6" textAlign="center" sx={{ mt: 5, ml:5}}>Runs Dashboard</Typography>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="List View" />
                    <Tab label="Grid View" />
                </Tabs>
                <Box sx={{ marginTop: 2, width: '90%'}}>
                    {renderComponent()}
                </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DesignerHomePage