import { useState } from "react";
import { Typography, Stack, Button, IconButton, TextField, Modal, Box, FormControl, InputLabel, Select, MenuItem, AccordionSummary, Accordion, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';
import './TemplateComponents.css';
import { TemplateComponent, TemplateComponentType } from "../../models/TemplateComponent";
import { Template } from "../../models/Template";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { TemplateComponentOption } from "../../models/TemplateComponentOption";
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from '@hello-pangea/dnd';
import hasInvalidCharacters from "../../utils/validateSequence";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';


interface TemplateComponentRowProps {
    templateComponent: TemplateComponent,
    deleteComponent: (component: TemplateComponent) => void
    updateTemplateComponent: (component: TemplateComponent) => void
    editOption: (component: TemplateComponent, optionId: string) => void
    removeOption: (component: TemplateComponent, optionId: string) => void
    viewAddOptionModal: (component: TemplateComponent) => void
}

export function TemplateComponentRow(props: TemplateComponentRowProps) {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={props.templateComponent.id}>
                <Stack direction="row" spacing={5} alignItems="center">
                    {props.templateComponent.name}
                    <IconButton size="small" onClick={() => props.deleteComponent(props.templateComponent)} sx={{ ml: 2 }}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {(props.templateComponent?.type === "USER_INPUT" || props.templateComponent?.type === "SEQUENCE_TBD") &&
                        <UserInputComponentView
                            templateComponent={props.templateComponent}
                            updateTemplateComponent={props.updateTemplateComponent}
                        />
                    }
                    {props.templateComponent?.type === "OPTIONS_OR_USER_INPUT" &&
                        <OptionsComponentView
                            templateComponent={props.templateComponent}
                            viewAddOptionModal={props.viewAddOptionModal}
                            updateTemplateComponent={props.updateTemplateComponent}
                            editOption={props.editOption}
                            removeOption={props.removeOption}
                        />
                    }
                    {props.templateComponent?.type === "FIXED" &&
                        <FixedComponentView
                            templateComponent={props.templateComponent}
                            updateTemplateComponent={props.updateTemplateComponent}
                        />
                    }
                    {props.templateComponent?.type === "FIXED_OPTIONS" &&
                        <OptionsComponentView
                            templateComponent={props.templateComponent}
                            viewAddOptionModal={props.viewAddOptionModal}
                            updateTemplateComponent={props.updateTemplateComponent}
                            editOption={props.editOption}
                            removeOption={props.removeOption}
                        />
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

interface ComponentViewProps {
    templateComponent: TemplateComponent,
    updateTemplateComponent: (component: TemplateComponent) => void
}

function UserInputComponentView(props: ComponentViewProps) {
    return (
        <Stack direction="column" alignItems="flex-start" spacing={4} sx={{ p: 1 }}>
            {props.templateComponent.type === 'USER_INPUT' &&
            <FormControl>
                <FormControlLabel control={<Checkbox checked={props.templateComponent.isOptimizable} onChange={(e:any) => props.updateTemplateComponent({ ...props.templateComponent, isOptimizable: e.target.checked })} />} label="Component is optimizable" />
                <FormControlLabel control={<Checkbox onChange={(e:any) => props.updateTemplateComponent({ ...props.templateComponent, isOptional: e.target.checked })} />} label="Component is optional" />
            </FormControl>
            }
            <TextField
                sx={{ minWidth: 300 }}
                label="Component name"
                size="small"
                variant="outlined"
                autoComplete="off"
                value={props.templateComponent.name}
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, name: e.target.value })}
            />
            <TextField
                sx={{ minWidth: 300 }}
                label="Component description"
                size="small"
                multiline
                minRows={2}
                variant="outlined"
                value={props.templateComponent.description}
                autoComplete="off"
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, description: e.target.value })}
            />
        </Stack>
    )
}

interface FixedComponentViewProps {
    templateComponent: TemplateComponent,
    updateTemplateComponent: (component: TemplateComponent) => void
}

function FixedComponentView(props: FixedComponentViewProps) {
    return (
        <Stack direction="column" alignItems="flex-start" spacing={4} sx={{ p: 1 }}>
            <TextField
                sx={{ minWidth: 300 }}
                label="Component name"
                size="small"
                variant="outlined"
                autoComplete="off"
                value={props.templateComponent.name}
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, name: e.target.value })}
            />
            <TextField
                sx={{ minWidth: 300 }}
                label="Component description"
                size="small"
                multiline
                minRows={2}
                variant="outlined"
                value={props.templateComponent.description}
                autoComplete="off"
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, description: e.target.value })}
            />
            <TextField
                sx={{ minWidth: 300 }}
                label="Sequence"
                size="small"
                variant="outlined"
                autoComplete="off"
                multiline
                minRows={3}
                value={props.templateComponent.value}
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, value: e.target.value })}
            />
        </Stack>
    )
}

interface OptionComponentViewProps {
    templateComponent: TemplateComponent,
    updateTemplateComponent: (component: TemplateComponent) => void
    editOption: (component: TemplateComponent, optionId: string) => void
    removeOption: (component: TemplateComponent, optionId: string) => void
    viewAddOptionModal: (component: TemplateComponent) => void
}

function OptionsComponentView(props: OptionComponentViewProps) {
    return (
        <Stack direction="column" alignItems="flex-start" spacing={4} sx={{ p: 1 }}>
            {props.templateComponent.type === "OPTIONS_OR_USER_INPUT" &&
                <FormControlLabel
                    label="Component is optional"
                    control={
                        <Checkbox
                            checked={props.templateComponent.isOptional}
                            onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, isOptional: e.target.checked })}
                        />}
                />
            }
            <TextField
                sx={{ minWidth: 300 }}
                label="Component name"
                size="small"
                variant="outlined"
                autoComplete="off"
                value={props.templateComponent.name}
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, name: e.target.value })}
            />
            <TextField
                sx={{ minWidth: 300 }}
                label="Component description"
                size="small"
                multiline
                minRows={2}
                variant="outlined"
                value={props.templateComponent.description}
                autoComplete="off"
                onChange={(e) => props.updateTemplateComponent({ ...props.templateComponent, description: e.target.value })}
            />
            <Stack direction="row" alignItems="center" spacing={3}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Options:
                </Typography>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => props.viewAddOptionModal(props.templateComponent)}
                >
                    +
                </Button>
            </Stack>

            {props.templateComponent.options?.map(option => {
                return (
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography variant="body2" sx={{ mr: 2 }}>
                            {option.name}
                        </Typography>
                        <IconButton size="small" onClick={() => props.editOption(props.templateComponent, option.id!)}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" onClick={() => props.removeOption(props.templateComponent, option.id!)}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        {option.isDefault && 
                            <Typography variant="body2" fontSize={10}>
                                Default option
                            </Typography>
                        }

                    </Stack>
                )
            })}
        </Stack>
    )
}


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6,
    borderRadius: '16px'
};


interface TemplateComponentsViewProps {
    template: Template,
    updateAllComponents: (components: TemplateComponent[]) => void
    updateTemplateComponent: (component: TemplateComponent) => void
    removeTemplateComponent: (componentId: string) => void
    updateTemplateComponentOption: (component: TemplateComponent, option: TemplateComponentOption) => void
    removeTemplateComponentOption: (component: TemplateComponent, optionId: string) => void
}


function TemplateComponentsView(props: TemplateComponentsViewProps) {
    const [currentComponentId, setCurrentComponentId] = useState<string | undefined>();
    const [currentOptionId, setCurrentOptionId] = useState<string | undefined>();
    const [openOptionModal, setOpenOptionModal] = useState(false)
    const [openAddComponentModal, setOpenAddComponentModal] = useState(false)
    const [openAddOptionModal, setOpenAddOptionModal] = useState(false)
    const [newComponentName, setNewComponentName] = useState("")
    const [newComponentDescription, setNewComponentDescription] = useState("")
    const [newComponentType, setNewComponentType] = useState<TemplateComponentType | undefined>()
    const [newComponentValue, setNewComponentValue] = useState("")
    const [newComponentIsOptional, setNewComponentIsOptional] = useState(false)
    const [newOptionName, setNewOptionName] = useState("")
    const [newOptionDescription, setNewOptionDescription] = useState("")
    const [newOptionValue, setNewOptionValue] = useState("")
    const [newOptionIsDefault, setNewOptionIsDefault] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [newComponentIsOptimizable, setNewComponentIsOptimizable] = useState(false)

    const viewAddComponentModal = () => {
        setNewComponentType(undefined)
        setNewComponentName("")
        setNewComponentDescription("")
        setNewComponentValue("")
        setNewComponentIsOptional(false)
        setOpenAddComponentModal(true)
    }

    const saveComponent = () => {
        let position = 0
        if (props.template.components.length !== 0) {
            const sortedComps = props.template.components.sort((a, b) => a.position - b.position)
            position = sortedComps[sortedComps.length - 1].position + 1
        }
        const newComponent: TemplateComponent = {
            id: uuidv4(),
            name: newComponentName,
            description: newComponentDescription,
            position: position,
            type: newComponentType!
        }
        if (newComponentType === "OPTIONS_OR_USER_INPUT" || newComponentType === "FIXED_OPTIONS") {
            newComponent["options"] = []
        }
        if (newComponentType === "FIXED") {
            newComponent["value"] = newComponentValue
        }
        if (newComponentType === "SEQUENCE_TBD") {
            newComponent["value"] = 'x'.repeat(20)
        }
        if (newComponentType === "OPTIONS_OR_USER_INPUT" || newComponentType === "USER_INPUT") {
            newComponent["isOptional"] = newComponentIsOptional
            newComponent["isOptimizable"] = newComponentIsOptimizable
        }
        

        props.updateTemplateComponent(newComponent)
        setOpenAddComponentModal(false)
    }

    const removeComponent = (component: TemplateComponent) => {
        props.removeTemplateComponent(component.id!)
        setCurrentComponentId("")
        setOpenAddComponentModal(false)
    }

    const viewAddOptionModal = (currentComponent: TemplateComponent) => {
        setCurrentComponentId(currentComponent.id)
        setNewOptionName("")
        setNewOptionIsDefault(false)
        setNewOptionDescription("")
        setNewOptionValue("")
        setOpenAddOptionModal(true)
    }

    const saveOption = () => {
        const newOption: TemplateComponentOption = {
            id: uuidv4(),
            name: newOptionName,
            isDefault: newOptionIsDefault,
            description: newOptionDescription,
            value: newOptionValue
        }
        const currentComponent = getComponentById(currentComponentId)
        props.updateTemplateComponentOption(currentComponent!, newOption)
        setOpenAddOptionModal(false)
    }


    const getComponentById = (id: string | undefined) => {
        if (!id) {
            return undefined
        }
        return props.template.components.find(component => component.id === id)
    }

    const currentComponent = getComponentById(currentComponentId)

    const getOptionById = (id: string | undefined) => {
        if (!id || !currentComponent || !(currentComponent.type === "OPTIONS_OR_USER_INPUT" || currentComponent.type === "FIXED_OPTIONS")) {
            return undefined
        }
        return currentComponent.options!.find(option => option.id === id)
    }

    const currentOption = getOptionById(currentOptionId)


    const editOption = (component: TemplateComponent, optionId: string) => {
        setCurrentComponentId(component.id)
        setCurrentOptionId(optionId)
        setOpenOptionModal(true)
    }

    const checkSequenceAndCallFunction = (sequence: string, functionToCall: (sequence: string) => void) => {
        const invalidCharacters = hasInvalidCharacters(sequence, "dna")
        if (!invalidCharacters) {
            functionToCall(sequence)
            setErrorMsg('')
        } else {
            setErrorMsg("Invalid sequence entered: " + invalidCharacters)
        }
    }

    const checkSequenceAndEdit = (sequence: string) => {
        checkSequenceAndCallFunction(sequence, () => props.updateTemplateComponentOption(currentComponent!, { ...currentOption!, value: sequence }))
    }

    const checkSequenceAndCreate = (sequence: string) => {
        checkSequenceAndCallFunction(sequence, () => setNewOptionValue(sequence))
    }

    const checkSequenceAndSetValue = (sequence: string) => {
        checkSequenceAndCallFunction(sequence, () => setNewComponentValue(sequence))
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return; // If dropped outside the list, do nothing

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        // Reorder the components in the list
        const reoreredComponents = Array.from(props.template.components);
        const [removed] = reoreredComponents.splice(startIndex, 1);
        reoreredComponents.splice(endIndex, 0, removed);

        // Asign the index of each component to the position
        reoreredComponents.forEach((component, index) => {
            component.position = index
        })

        // Update the state with the new list of components
        props.updateAllComponents(reoreredComponents);
    };


    return (
        <>
            <Stack>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    COMPONENTS
                </Typography>
                <Typography variant="body2" sx={{ mb: 3, fontSize: 12 }}>
                    Click on a component to edit it. Drag and drop to reorder.
                </Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided: DroppableProvided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {props.template.components.sort((a, b) => a.position - b.position).map((component, index) => (
                                    <Draggable key={component.id} draggableId={component.id!} index={index}>
                                        {(provided: DraggableProvided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{ ...provided.draggableProps.style, marginBottom: 10 }}
                                            >
                                                <TemplateComponentRow
                                                    templateComponent={component}
                                                    viewAddOptionModal={viewAddOptionModal}
                                                    deleteComponent={removeComponent}
                                                    updateTemplateComponent={props.updateTemplateComponent}
                                                    editOption={editOption}
                                                    removeOption={props.removeTemplateComponentOption}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Stack direction="row" spacing={2} justifyContent="start" sx={{ mt: 3 }} >
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={viewAddComponentModal}
                        startIcon={<AddIcon />}>
                        Add
                    </Button>
                </Stack>
            </Stack >
            {openAddComponentModal &&
                <Modal
                    open={openAddComponentModal}
                    onClose={() => setOpenAddComponentModal(false)}
                >
                    <Box sx={modalStyle}>
                        <CloseIcon sx={{ cursor: "pointer", float: "right" }} onClick={() => setOpenAddComponentModal(false)} />
                        <Stack direction="column" spacing={3}>
                            <Typography variant="h6" textAlign="center">
                                Create new component
                            </Typography>
                            <TextField
                                fullWidth
                                label="Component name"
                                size="small"
                                variant="outlined"
                                autoComplete="off"
                                value={newComponentName}
                                onChange={(e) => setNewComponentName(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Component description"
                                size="small"
                                multiline
                                minRows={2}
                                variant="outlined"
                                value={newComponentDescription}
                                autoComplete="off"
                                onChange={(e) => setNewComponentDescription(e.target.value)}
                            />
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel size="small" id="type-select">Component type</InputLabel>
                                <Select
                                    size="small"
                                    labelId="type-select"
                                    label="Component type"
                                    value={newComponentType}
                                    onChange={(e) => setNewComponentType(e.target.value as TemplateComponentType)}
                                >
                                    <MenuItem value="USER_INPUT">
                                        User defined sequence
                                    </MenuItem>
                                    <MenuItem value="OPTIONS_OR_USER_INPUT">
                                        User defined sequence or one from a set of options
                                    </MenuItem>
                                    <MenuItem value="FIXED">
                                        A fixed sequence
                                    </MenuItem>
                                    <MenuItem value="FIXED_OPTIONS">
                                        A sequence from a set of options
                                    </MenuItem>
                                    <MenuItem value="SEQUENCE_TBD">
                                        A sequence to be determined later
                                    </MenuItem>
                                </Select>
                            </FormControl>
                   
                            {(newComponentType === "OPTIONS_OR_USER_INPUT" || newComponentType === "USER_INPUT") &&
                                <FormControl>
                                <FormControlLabel control={<Checkbox onChange={(e:any) => setNewComponentIsOptimizable(e.target.checked)} />} label="Component is optimizable" />
                                <FormControlLabel control={<Checkbox onChange={(e:any) => setNewComponentIsOptional(e.target.checked)} />} label="Component is optional" />
                                </FormControl>
                            }
                            {newComponentType === "FIXED" &&
                                <TextField
                                    fullWidth
                                    label="Sequence"
                                    size="small"
                                    multiline
                                    minRows={2}
                                    variant="outlined"
                                    value={newComponentValue}
                                    autoComplete="off"
                                    onChange={(e) => checkSequenceAndSetValue(e.target.value)}
                                />
                            }
                            {errorMsg && <Typography variant="body2" color='error'>{errorMsg}</Typography>}
                            <Button
                                variant="contained"
                                disabled={!newComponentName || !newComponentType || (newComponentType === "FIXED" && !newComponentValue)}
                                onClick={() => saveComponent()}
                            >
                                Create
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            }
            {
                openOptionModal &&
                <Modal
                    open={openOptionModal}
                    onClose={() => { setOpenOptionModal(false); setErrorMsg('') }}
                >
                    <Box sx={modalStyle}>
                        <TextField
                            sx={{ minWidth: 100 }}
                            label="Name"
                            size="small"
                            variant="outlined"
                            value={currentOption?.name}
                            autoComplete="off"
                            onChange={(e) => props.updateTemplateComponentOption(currentComponent!, { ...currentOption!, name: e.target.value })}
                        />
                        <TextField
                            sx={{ minWidth: 100, mt: 3 }}
                            label="Description"
                            size="small"
                            variant="outlined"
                            value={currentOption?.description}
                            autoComplete="off"
                            onChange={(e) => props.updateTemplateComponentOption(currentComponent!, { ...currentOption!, description: e.target.value })}
                        />
                        <FormControlLabel
                            label="Default option"
                            control={
                                <Checkbox
                                    checked={currentOption?.isDefault}
                                    onChange={(e) => props.updateTemplateComponentOption(currentComponent!, { ...currentOption!, isDefault: e.target.checked })}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                        />
                        <TextField
                            sx={{ mt: 3 }}
                            multiline
                            fullWidth
                            minRows={5}
                            label="Sequence"
                            size="small"
                            variant="outlined"
                            value={currentOption?.value}
                            autoComplete="off"
                            onChange={(e) => checkSequenceAndEdit(e.target.value)}
                        />
                        {errorMsg && <Typography variant="body2" color='error'>{errorMsg}</Typography>}

                    </Box>
                </Modal>
            }
            {
                openAddOptionModal &&
                <Modal
                    open={openAddOptionModal}
                    onClose={() => { setOpenAddOptionModal(false); setErrorMsg('') }}
                >
                    <Box sx={modalStyle}>
                        <CloseIcon sx={{ cursor: "pointer", float: "right" }} onClick={() => { setOpenAddOptionModal(false); setErrorMsg('') }} />
                        <Stack direction="column" spacing={3}>
                            <Typography variant="h6" textAlign="center">
                                Create new option
                            </Typography>
                            <TextField
                                fullWidth
                                label="Option name"
                                size="small"
                                variant="outlined"
                                value={newOptionName}
                                autoComplete="off"
                                onChange={(e) => setNewOptionName(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label="Option description"
                                size="small"
                                variant="outlined"
                                value={newOptionDescription}
                                autoComplete="off"
                                onChange={(e) => setNewOptionDescription(e.target.value)}
                            />
                            <FormControlLabel
                                label="Default option"
                                control={
                                    <Checkbox
                                        checked={newOptionIsDefault}
                                        onChange={(e) => setNewOptionIsDefault(e.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />}
                            />
                            <TextField
                                label="Option value"
                                multiline
                                minRows={5}
                                fullWidth
                                type="number"
                                size="small"
                                variant="outlined"
                                value={newOptionValue}
                                autoComplete="off"
                                onChange={(e) => checkSequenceAndCreate(e.target.value)}
                            />
                            {errorMsg && <Typography variant="body2" color='error'>{errorMsg}</Typography>}
                            <Button
                                variant="contained"
                                disabled={!newOptionName || !newOptionValue}
                                onClick={() => saveOption()}
                            >
                                Create
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            }

        </>

    );
}

export default TemplateComponentsView
