import { useAuth0 } from "@auth0/auth0-react";
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductsSlice";
import { updateProduct } from "../../../api/ProductQueries";
import { Product } from "../../../models/Product";
import { CapMethodOption, ModifiedNucleotideOption } from "../../../models/Run";

const ProductDrugSubstanceStep = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatchGlobal = useDispatch()

  const { id } = useParams()

  const product = useSelector(selectProduct)

  const [drugSubstanceInfo, setDrugSubstanceInfo] = useState({
    capping: product?.capping,
    modifiedNucleotides: product?.modifiedNucleotides,
    cappingMethod: product?.cappingMethod
  })


  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);

  const handleInputChange = (field: string, value: string | null) => {
    setDrugSubstanceInfo((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));

    if (debounceTimeout !== null) clearTimeout(debounceTimeout);

    const newDebounceTimeout = setTimeout(async () => {
      await updateProductQuery({ ...drugSubstanceInfo, [field]: value });
    }, 500);

    setDebounceTimeout(newDebounceTimeout);
  };

  useEffect(() => {
    if (id && product?.id === id) {
      setDrugSubstanceInfo({
        capping: product?.capping,
        modifiedNucleotides: product?.modifiedNucleotides,
        cappingMethod: product?.cappingMethod
      })
    }
  }, [id, product?.id])


  const updateProductQuery = async (partialProduct: Partial<Product>) => {
    if (id) {
      const accessToken = await getAccessTokenSilently()
      const updatedProduct = await updateProduct(accessToken, partialProduct, id)
      if (updatedProduct) dispatchGlobal(setProduct(updatedProduct))
    }
  }

  const disabledSelectModifiedNucleotides = useMemo(() => {
    const options = product?.run?.modifiedNucOptions
    return Array.isArray(options) && options.length === 0
  }, [product?.run?.modifiedNucOptions])

  const disabledSelectCappingMethods = useMemo(() => {
    const options = product?.run?.capMethodOptions
    return Array.isArray(options) && options.length === 0
  }, [product?.run?.capMethodOptions])


  const getCostMenuItem = (option: ModifiedNucleotideOption | CapMethodOption) => {
    return (
      <MenuItem key={option.name} value={option.name}>
        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          <span className="component-name">{option.name.split("_").map((word) => word.at(0) + word.slice(1).toLowerCase()).join(" ")}</span>
          <span> - ${option.cost}.00 (USD)</span>
        </div>
      </MenuItem>
    )
  }
  

  return (
    <Stack direction='column' spacing={8} alignItems='center' sx={{ width: '50%' }}>
      <Stack direction='column' spacing={1} alignItems='left' sx={{ width: '100%' }}>
        <FormControl disabled={disabledSelectModifiedNucleotides} required={true}>
          <InputLabel size="small" id="run-select">
            {
              disabledSelectModifiedNucleotides ?
                "No modified nucleotides available" :
                "Select modified nucleotides"
            }
          </InputLabel>
          <Select
            label="Select modified nucleotides"
            labelId="mod-nuc-select"
            size="small"
            value={drugSubstanceInfo.modifiedNucleotides}
            disabled={product?.runIsReserved}
            onChange={(e) => handleInputChange("modifiedNucleotides", e.target.value)}
          >
            {product?.run?.modifiedNucOptions.length !== 0 && product?.run?.modifiedNucOptions.map((option: ModifiedNucleotideOption) => (
              getCostMenuItem(option)
            ))
            }
          </Select>
        </FormControl>
      </Stack>

      <Stack direction='column' spacing={1} alignItems='left' sx={{ width: '100%' }}>
        <FormControl disabled={disabledSelectCappingMethods} required={true}>
          <InputLabel size="small" id="run-select">
            {
              disabledSelectCappingMethods ?
                "No capping methods available" :
                "Select capping method"
            }
          </InputLabel>
          <Select
            label="Select capping methods"
            labelId="cap-methods-select"
            size="small"
            disabled={product?.runIsReserved}
            value={drugSubstanceInfo.cappingMethod}
            onChange={(e) => handleInputChange("cappingMethod", e.target.value)}
          >
            {product?.run?.capMethodOptions.length !== 0 && product?.run?.capMethodOptions.map((option: CapMethodOption) => (
              getCostMenuItem(option)
            ))
            }
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default ProductDrugSubstanceStep
