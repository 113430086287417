import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from '@mui/material';
import { Link } from "react-router-dom";

interface NumberOfEntitiesCardProps {
    entitiesName: string
    queryToGetEntities: (accessToken: string) => Promise<any[] | undefined>;
    detailsLinkURL: string
}

function NumberOfEntitiesCard({entitiesName, queryToGetEntities, detailsLinkURL}: NumberOfEntitiesCardProps) {
    const { getAccessTokenSilently } = useAuth0();

    const [entities, setEntities] = useState<any[]>([])

    const getEntities = async () => {
        const accessToken = await getAccessTokenSilently()
        const entities = await queryToGetEntities(accessToken)
        if (entities) {
            setEntities(entities)
        }
    }

    useEffect(() => {
        getEntities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontSize: 14, mb: 3 }} gutterBottom>
                    {entitiesName}
                </Typography>
                <Typography variant="h5" component="div">
                    {entities.length}
                </Typography>

            </CardContent>
            <CardActions>
                <Link to={detailsLinkURL}>
                    <Button size="small">View details</Button>
                </Link>

            </CardActions>
        </Card>
    )
}

export default NumberOfEntitiesCard
