import { Annotation } from "../models/Product";
import { getComponentsColors } from "./colors";
import { getComponentDisplayName } from "./getDisplayNames";

export interface SequenceAndAnnotations {
  sequence: string
  annotations: Array<Annotation>
}

export default function createSequenceAndAnnotations(components: Record<string, any>): SequenceAndAnnotations {
  var completeSequence = ""
  const annotations: Annotation[]  = []
  const names = components.map((component:any) => component.name)
  const colors = getComponentsColors(names) ?? "#FAA88790"
  components.forEach((item:any) => {
    const sequence = item.sequence;
    if (sequence !== "") {
      
      const annotation: Annotation = {
        start: completeSequence.length, 
        end: completeSequence.length + sequence.length, 
        name: getComponentDisplayName(item.name), 
        color: colors.get(item.name)!,
        direction: 0,
      }
      annotations.push(annotation)
      completeSequence += sequence
    }   
  })
  
  return {
    sequence: completeSequence,
    annotations: annotations
  }
}
