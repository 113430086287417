import { useEffect, useMemo, useState } from "react";
import { Box, Button, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from "@auth0/auth0-react";
import { FormInputSelect, FormInputCheckbox, FormInputText, FormInputTextType, FormHeader } from "../../../components/FormComponents";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Manufacturer } from "../../../models/Manufacturer";
import { getManufacturers } from "../../../api/ManufacturerQueries";
import LoadingButton from "@mui/lab/LoadingButton";
import { CappingMethod, ModifiedNucleotides, Run, RunStatus } from "../../../models/Run";
import { createRun } from "../../../api/RunQueries";
import { useNavigate } from "react-router-dom";
import { showAlert } from "../../../components/Alert/alertSlice";
import store from "../../../store";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
}

const schema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    description: z.string().optional(),
    requireApproval: z.boolean().optional(),
    manufacturer: z.string().min(1, { message: "Manufacturer is required" }),
    process: z.string().min(1, { message: "Process is required" }),
    formulation: z.boolean().optional(),
    formulationDetails: z.string().optional(),
    dnaSynthesis: z.boolean().optional(),
    numberOfRunsToCreate: z.number().min(1, { message: "Number of runs must be greater than 0" }),
    pseudoU: z.object({
        available: z.boolean().optional(),
        cost: z.number().min(0, { message: "Cost can't be negative" }).optional(),
    }),
    cleanCap: z.object({
        available: z.boolean().optional(),
        cost: z.number().min(0, { message: "Cost can't be negative" }).optional(),
    }),
    arcaCap: z.object({
        available: z.boolean().optional(),
        cost: z.number().min(0, { message: "Cost can't be negative" }).optional(),
    }),
    quantity: z.object({
        min: z.number().min(0, { message: 'Min quantity must be greater than 0' }),
        max: z.number().min(0, { message: 'Max quantity must be greater than 0' }),
    }).refine(
        (values) => {
            return values.min <= values.max;
        },
        {
            message: "Max quantity has to be greater or equal than Min quantity",
            path: ["max"],
        }
    ),
    baseCost: z.number().min(0, { message: "Cost must be greater than 0" }),
    dates: z.object({
        runOrder: z.date().min(new Date(), { message: "You must choose a date in the future" }),
        runStart: z.date().min(new Date(), { message: "You must choose a date in the future" }).optional(),
        runShipping: z.date().min(new Date(), { message: "You must choose a date in the future" })
    }).refine(
        (values) => {
            if (values.runStart) return values.runOrder <= values.runStart;
            return true
        },
        {
            message: "Run start date must be later than run order date",
            path: ["runStart"],
        }
    )
        .refine(
            (values) => {
                if (values.runStart) return values.runStart <= values.runShipping;
                return values.runOrder <= values.runShipping;
            },
            {
                message: "Run shipping must be later than run order and start date",
                path: ["runShipping"],
            }
        ),
})

type FormFields = z.infer<typeof schema>;


function CreateRunPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const { control, handleSubmit, watch } = useForm<FormFields>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {},
    });

    const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
    const [creatingRun, setCreatingRun] = useState(false)
    const [creatingAsDraft, setCreatingAsDraft] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [formData, setFormData] = useState<FormFields>()

    const getAllManufacturers = async () => {
        const accessToken = await getAccessTokenSilently()
        let manufacturers: Manufacturer[] = await getManufacturers(accessToken)
        if (manufacturers) {
            manufacturers = manufacturers.filter((manufacturer: Manufacturer) => manufacturer.processes && manufacturer.processes.length > 0)
            setManufacturers(manufacturers)
        }
    }

    const manufacturerOptions = useMemo(() => (
        manufacturers
            .filter(manufacturer => manufacturer.institutionName)
            .map((manufacturer) => (
                {
                    value: manufacturer.id,
                    label: manufacturer.institutionName
                }
            ))
    ), [JSON.stringify(manufacturers)])

    useEffect(() => {
        getAllManufacturers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createRunRequest = async (asDraft: boolean) => {
        setCreatingRun(true)
        setCreatingAsDraft(asDraft)

        const accessToken = await getAccessTokenSilently()

        const createRunDTO: Run = {
            status: asDraft ? RunStatus.DRAFTED : RunStatus.PUBLISHED,
            manufacturerId: formData!.manufacturer,
            requireApproval: formData!.requireApproval ?? false,
            processId: formData!.process,
            name: formData!.name,
            description: formData!.description,
            quantity: { min: formData!.quantity.min, max: formData!.quantity.max },
            baseCost: formData!.baseCost,
            dateToSubmit: formData!.dates.runOrder,
            dateToStart: formData!.dates.runStart,
            dateToShip: formData!.dates.runShipping,
            dnaSynthesis: formData!.dnaSynthesis ?? false,
            formulation: formData!.formulation ?? false,
            formulationDetails: formData!.formulationDetails,
            modifiedNucOptions: [],
            capMethodOptions: [],
        }
        if (formData!.pseudoU.available) {
            createRunDTO.modifiedNucOptions.push({
                name: ModifiedNucleotides.PSEUDO_URIDINE,
                cost: formData!.pseudoU.cost ?? 0
            })
        }
        if (formData!.cleanCap.available) {
            createRunDTO.capMethodOptions.push({
                name: CappingMethod.CLEAN_CAP_AG,
                cost: formData!.cleanCap.cost ?? 0
            })
        }
        if (formData!.arcaCap.available) {
            createRunDTO.capMethodOptions.push({
                name: CappingMethod.ARCA_CAP,
                cost: formData!.arcaCap.cost ?? 0
            })
        }

        createRunDTO.modifiedNucOptions.push({
            name: ModifiedNucleotides.NONE,
            cost: 0
        })

        createRunDTO.capMethodOptions.push({
            name: CappingMethod.NONE,
            cost: 0
        })


        const result = await createRun(accessToken, createRunDTO, formData!.numberOfRunsToCreate)
        if (result) {
            store.dispatch(showAlert({ message: 'Run created successfully!', severity: 'success' }))
            navigate("/broker")
        }
        setCreatingRun(false)
        setShowModal(false)
    }


    const submitForm = async (data: FormFields) => {
        setFormData(data)
        setShowModal(true)
    }

    const formulationValue = watch("formulation");
    const pseudoUValue = watch("pseudoU.available");
    const cleanCapValue = watch("cleanCap.available");
    const arcaCapValue = watch("arcaCap.available");
    const selectedManufacturerId = watch("manufacturer");
    const dnaSynthesisValue = watch("dnaSynthesis");

    const processesOptions = useMemo(() => {
        const processes = manufacturers.find((manufacturer) => manufacturer.id === selectedManufacturerId)?.processes ?? []
        return processes.map((process) => ({
            value: process.id!,
            label:
                <>
                    <span className="component-name" > {process.name}</span >
                    <span className="separator">-</span>
                    <span className="component-description">{process.description}</span>
                </>


        }))
    }, [selectedManufacturerId])

    return (
        <Box sx={{
            px: 10,
            pt: 5,
            pb: 5,
            maxWidth: 750
        }}>
            <FormHeader title="Create a Run" subtitle="Fill in the parameters for your run and create it. You can then use the dashboard to change its status." />
            <form noValidate onSubmit={handleSubmit((data: FormFields) => submitForm(data))}>
                <Typography variant='subtitle1' textAlign="left" sx={{ pt: 0 }}>
                    Basic information
                </Typography>
                <Box sx={{ pl: 3 }}>
                    <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={2} >
                        <FormInputText
                            name="name"
                            size="small"
                            control={control}
                            label="Run name"
                            required={true}
                            type={FormInputTextType.Text}
                        />
                        <FormInputText
                            name="description"
                            size="small"
                            control={control}
                            label="Run description"
                            required={false}
                            type={FormInputTextType.Text}
                            multiline={true}
                            rows={4}
                        />
                        <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                            <FormInputText
                                name="quantity.min"
                                size="small"
                                control={control}
                                label="Min quantity (mg)"
                                required={true}
                                type={FormInputTextType.Number}
                            />
                            <FormInputText
                                name="quantity.max"
                                size="small"
                                control={control}
                                label="Max quantity (mg)"
                                required={true}
                                type={FormInputTextType.Number}
                            />
                        </Stack>
                        <FormInputText
                            name="baseCost"
                            size="small"
                            control={control}
                            label="Base cost (USD)"
                            required={true}
                            type={FormInputTextType.Number}
                        />
                        <Stack direction='row' spacing={2} sx={{ width: '100%' }}>
                            <FormInputText
                                name="dates.runOrder"
                                size="small"
                                control={control}
                                label="Run order date"
                                required={true}
                                type={FormInputTextType.Date}
                            />
                            <FormInputText
                                name="dates.runStart"
                                size="small"
                                control={control}
                                label="Run start date"
                                required={false}
                                type={FormInputTextType.Date}
                            />
                            <FormInputText
                                name="dates.runShipping"
                                size="small"
                                control={control}
                                label="Run shipping date"
                                required={true}
                                type={FormInputTextType.Date}
                            />
                        </Stack>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '28%' },
                                label: { width: '79%' }
                            }}
                            name="requireApproval"
                            control={control}
                            label="Require Approval"
                        />
                    </Stack>
                </Box>
                <Typography variant='subtitle1' textAlign="left" sx={{ pt: 3 }}>
                    Manufacturer selection
                </Typography>
                <Box sx={{ pl: 3 }}>
                    <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={2} >
                        <FormInputSelect
                            name="manufacturer"
                            size="small"
                            control={control}
                            label="Manufacturer"
                            required={true}
                            options={manufacturerOptions}
                        />
                        <FormInputSelect
                            name="process"
                            size="small"
                            control={control}
                            label="Process"
                            required={true}
                            options={processesOptions}
                            disabled={selectedManufacturerId === undefined}
                        />
                    </Stack>
                </Box>
                <Typography variant='subtitle1' textAlign="left" sx={{ pt: 4 }}>
                    Process options
                </Typography>
                <Box sx={{ pl: 3 }}>
                    <Stack direction='row' alignItems='left' spacing={2} sx={{ width: '100%' }}>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '24%' },
                                label: { width: '79%' }
                            }}
                            name="dnaSynthesis"
                            control={control}
                            label="DNA synthesis"
                        />
                        {!dnaSynthesisValue &&
                            <Typography variant='body2' sx={{ color: '#fc9803', width: '70%' }}>
                                If you leave this box unchecked, the customer will need to provide a DNA sample to the vendor.
                            </Typography>
                        }
                    </Stack>
                    <Stack direction='column' alignItems='left' spacing={1} sx={{ width: '100%' }}>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '24%' },
                                label: { width: '79%' }
                            }}
                            name="formulation"
                            control={control}
                            label="Formulation"
                        />

                        {formulationValue && (
                            <FormInputText
                                multiline={true}
                                rows={4}
                                name="formulationDetails"
                                control={control}
                                label="Formulation details"
                                required={false}
                                type={FormInputTextType.Text}
                                disabled={!Boolean(formulationValue)}
                            />
                        )}
                    </Stack>
                </Box>
                <Typography variant='subtitle1' textAlign="left" sx={{ pt: 4 }}>
                    Modified nucleotides
                </Typography>

                <Box sx={{ pl: 3 }}>
                    <Stack direction='row' alignItems='center' spacing={2} sx={{ width: '100%' }}>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '24%' },
                                label: { width: '79%' }
                            }}
                            name="pseudoU.available"
                            control={control}
                            label="Pseudo-uridine"
                        />
                        {pseudoUValue && (
                            <FormInputText
                                childrenSx={{
                                    formControl: { width: '70%' },
                                    textField: { width: '40%' }
                                }}
                                name="pseudoU.cost"
                                control={control}
                                label="Extra cost (USD)"
                                required={false}
                                type={FormInputTextType.Number}
                            />
                        )}
                    </Stack>
                </Box>
                <Typography variant='subtitle1' textAlign="left" sx={{ pt: 4 }}>
                    Capping methods
                </Typography>
                <Box sx={{ pl: 3 }}>
                    <Stack direction='row' alignItems='center' spacing={2} sx={{ width: '100%' }}>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '24%' },
                                label: { width: '79%' }
                            }}
                            name="cleanCap.available"
                            control={control}
                            label="CleanCap"
                        />
                        {cleanCapValue && (
                            <FormInputText
                                childrenSx={{
                                    formControl: { width: '70%' },
                                    textField: { width: '40%' }
                                }}
                                name="cleanCap.cost"
                                control={control}
                                label="Extra cost (USD)"
                                required={false}
                                type={FormInputTextType.Number}
                            />
                        )}
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={2} sx={{ width: '100%' }}>
                        <FormInputCheckbox
                            childrenSx={{
                                parentStack: { width: '24%' },
                                label: { width: '79%' }
                            }}
                            name="arcaCap.available"
                            control={control}
                            label="ARCA Cap"
                        />
                        {arcaCapValue && (
                            <FormInputText
                                childrenSx={{
                                    formControl: { width: '70%' },
                                    textField: { width: '40%' }
                                }}
                                name="arcaCap.cost"
                                control={control}
                                label="Extra cost (USD)"
                                required={false}
                                type={FormInputTextType.Number}
                            />
                        )}
                    </Stack>
                </Box>
                <Stack direction='row' spacing={2} alignItems='center' sx={{ mt: 4 }}>
                    <Typography variant='subtitle1' textAlign="left">
                        Number of runs to create:
                    </Typography>
                    <Box sx={{width: 100}}>
                        <FormInputText
                            name="numberOfRunsToCreate"
                            size="small"
                            control={control}
                            label=""
                            required={true}
                            type={FormInputTextType.Number}
                        />
                    </Box>
                </Stack>
                <Button
                    sx={{ mt: 5 }}
                    variant='contained'
                    type="submit"
                >
                    Create
                </Button>
            </form >
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <Box sx={modalStyle}>
                    <Stack direction="column" spacing={5} alignItems='center' sx={{ maxHeight: '100%' }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ width: '100%' }}
                        >
                            <Typography variant="h6" component="h2" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                Publish run
                            </Typography>
                            <IconButton onClick={() => setShowModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Typography variant="body1" textAlign='center'>
                            Do you want to publish this run or keep it as a draft?.
                            You can always change its status later in the dashboard.
                        </Typography>
                        <Stack direction="row" spacing={2} sx={{ width: '100%' }} justifyContent='space-evenly'>
                            <LoadingButton
                                loading={creatingRun && !creatingAsDraft}
                                disabled={creatingRun && creatingAsDraft}
                                variant='contained'
                                onClick={() => createRunRequest(false)}
                            >
                                Publish
                            </LoadingButton>
                            <LoadingButton
                                loading={creatingRun && creatingAsDraft}
                                disabled={creatingRun && !creatingAsDraft}
                                variant='outlined'
                                onClick={() => createRunRequest(true)}
                            >
                                Keep as draft
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </Box >
    );
}

export default CreateRunPage;
