import { Button, List, Stack } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react"
import { Product } from "../../models/Product"
import ProductItem from './ProductItem'
import CreateModal from "./CreateModal";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteProduct, getProducts } from "../../api/ProductQueries";
import "./ProductSidebar.css"


interface ProductSidebarProps {
  update: boolean
  setUpdate: (update: boolean) => void
}

const ProductSidebar = ({ update, setUpdate }: ProductSidebarProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [products, setProducts] = useState<Product[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams()
  const navigate = useNavigate()

  const getProductsForUser = async () => {
    const accessToken = await getAccessTokenSilently()
    const products = await getProducts(accessToken)
    if (products) {
      setProducts(products);
    }
  }

  useEffect(() => {
    if (update) {
      getProductsForUser()
      setUpdate(false)
    }
  }, [update])

  useEffect(() => {
    if (!id) getProductsForUser()
  }, [id])

  useEffect(() => {
    getProductsForUser()
  }, [])

  const reloadProducts = () => {
    getProductsForUser()
  }


  const onDelete = async (productId: string) => {
    const accessToken = await getAccessTokenSilently()
    await deleteProduct(accessToken, productId)
    if (!id) {
      getProductsForUser()
    }
    navigate(`/constructs`)
  }

  return (
    <Stack direction='column' sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          size='small'
          onClick={() => setShowModal(true)}
          sx={{ m: 2 }} startIcon={<AddIcon />}>
          New Construct
        </Button>
      </Stack>
      <List className="product-sidebar-list"
        sx={(theme) => ({
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.secondary.dark} transparent`
        })}
      >
        {products.map((product, index) => <ProductItem key={index} product={product} onDelete={() => onDelete(product.id!)} />)}
        {/* {loading &&
          <Box sx={{ display: 'flex', mt: 2 }} justifyContent="center">
            <CircularProgress />
          </Box>} */}
      </List>
      <CreateModal showModal={showModal} closeModal={() => setShowModal(false)} reloadProducts={reloadProducts} />
    </Stack>

  )
}

export default ProductSidebar
