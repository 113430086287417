import React from "react";
import {
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { getAllDesigners } from "../../../api/DesignerQueries";
import { getAllProducts } from "../../../api/ProductQueries";
import NumberOfEntitiesCard from "../../../components/NumberOfEntitiesCard/NumberOfEntitiesCard";
import { getAllOptimizations } from "../../../api/OptimizeQueries";
import Carousel from "../../../components/Carousel/Carousel";
import OptimizationsOverTimeGraph from "../../../components/Graphs/OptimizationsOverTimeGraph";
import ProductTypesGraphs from "../../../components/Graphs/ProductTypesGraph";


function DesignersDashboardPage() {

    return (
        <Stack direction="column" alignItems="center">
            <Typography variant="h5" textAlign="center" sx={{ pt: 5 }}>
                Designers Dashboard
            </Typography>
            <Grid container spacing={2} sx={{ p: 5 }}>
                <Grid item md={4}>
                    <NumberOfEntitiesCard
                        entitiesName="Designers"
                        queryToGetEntities={getAllDesigners}
                        detailsLinkURL="/broker-designers"
                    />
                </Grid>
                <Grid item md={4}>
                    <NumberOfEntitiesCard
                        entitiesName="Constructs"
                        queryToGetEntities={getAllProducts}
                        detailsLinkURL="/broker-constructs"
                    />
                </Grid>
                <Grid item md={4}>
                    <NumberOfEntitiesCard
                        entitiesName="Optimizations"
                        queryToGetEntities={getAllOptimizations}
                        detailsLinkURL="/broker-optimizations"
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" textAlign="center" sx={{ p: 2 }}>
                Graphs
            </Typography>
            <Carousel components={[
                <OptimizationsOverTimeGraph />,
                <ProductTypesGraphs />
            ]} />
        </Stack>
    );
}

export default DesignersDashboardPage
