import { showAlert } from "../components/Alert/alertSlice";
import { Process } from "../models/Process";
import store from '../store/';

export const getProcesses = async (accessToken: string): Promise<Array<Process> | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/processes/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting available processes', severity: 'error' }))
    }
}

export const getProcess = async (id: string, accessToken: string): Promise<Process | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/processes/${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting available processes', severity: 'error' }))
    }
}

export const createProcess = async (accessToken: string, processDTO: Process): Promise<Process | undefined> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/processes`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(processDTO)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while creating your process', severity: 'error' }))
    }
}

export const updateProcess = async (accessToken: string, product: Partial<Process>, processId: String) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/processes/${processId}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(product)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while updating your process', severity: 'error' }))
    }
}

export const deleteProcess = async (accessToken: string, processId: String) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/processes/${processId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            console.log(data)
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while deleting a process', severity: 'error' }))
    }
}
