import {
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import DesignerRunCard from "./DesignerRunCard";
import { Product } from "../../../models/Product";

function RunsDash({loadingRuns, products}: {loadingRuns: boolean, products: Product[]}) {


  const skeletons = useMemo(() => {
    return Array.from(new Array(2)).map((_, index) => (
      <Grid item xs={6} key={index}>
        <Skeleton sx={{ m: 2 }} variant="rectangular" height={'300px'} />
      </Grid>
    ))
  }, [])

  return (
    <Stack direction="column" alignItems="center">
      <Grid sx={{ p: 5 }} container direction="row" alignItems="center">
        {loadingRuns ? (
          skeletons
        ) : Array.isArray(products) && products.length > 0 ? (
          products.map((product: Product, index: number) => {
            if (product.run) {
              return (
                <Grid item sm={12} md={6} key={index}>
                  <DesignerRunCard
                    run={product.run}
                    product={product}
                  />
                </Grid>
              );
            }
          })
        ) :
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body1">
              No runs found. Reserve a run to see it below.
            </Typography>
          </Grid>
        }
      </Grid>
    </Stack>
  );
}

export default RunsDash;
