import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Box,
    Typography,
    Stack,
    Link
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { FormInputSelect } from "../../../components/FormComponents";
import { getApplicationInputs, getPresets } from "../../../api/OptimizeQueries";
import { OptimizationPresets } from "./OptimizationPresetsComponent"

export interface OptimizationApplication {
    organism: string,
    application: string,
}

export interface ApplicationInputs {
    [organism: string]: { appId: number, name: string, taxId: number }[]
}

interface OptimizationApplicationComponentProps {
    setPresets: (presets: OptimizationPresets[]) => void
    setCurrentStep: (step: number) => void
}

function OptimizationApplicationComponent({ setPresets, setCurrentStep }: OptimizationApplicationComponentProps) {
    const { getAccessTokenSilently } = useAuth0();
    const [gettingPresets, setGettingPresets] = useState(false)
    const [applications, setApplications] = useState<ApplicationInputs>({})

    const schema = z.object({
        organism: z.string().min(1, { message: "Organims is required" }),
        application: z.string().min(1, { message: "Application is required" }),
    })

    type FormFields = z.infer<typeof schema>;

    const { control, handleSubmit, watch } = useForm<FormFields>({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: zodResolver(schema),
        defaultValues: {},
    });

    const organismValue = watch("organism");

    useEffect(() => {
        getApplications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getApplications = async () => {
        const accessToken = await getAccessTokenSilently()
        const applicationInputs: ApplicationInputs | undefined = await getApplicationInputs(accessToken)
        if (applicationInputs) {
            setApplications(applicationInputs)
        }
    }

    const next = async (data: FormFields) => {
        setGettingPresets(true)
        const accessToken = await getAccessTokenSilently()
        const presets = await getPresets(
            accessToken, {
            organism: data.organism,
            application: data.application,
        })
        if (presets) {
            setPresets(presets)
            setCurrentStep(1)
        }
        setGettingPresets(false)
    }

    const organismOptions = useMemo(() => applications ? Object.keys(applications).map(organism => ({ value: organism, label: organism })) : [], [applications])
    const applicationOptions = useMemo(() => applications[organismValue] ? applications[organismValue].map(application => ({ value: application.name, label: application.name })) : [], [applications, organismValue])
    
    return (
        <>
            <Typography variant="h6" textAlign="center" sx={{ mt: 5 }}>
                Configure optimization
            </Typography>
            <Box display="flex" justifyContent='center' sx={{ mt: 2 }}>
                <form noValidate onSubmit={handleSubmit((data: FormFields) => next(data))} style={{ width: '30%' }}>
                    <Stack sx={{ mt: 1 }} direction='column' alignItems='left' spacing={4} >
                        <FormInputSelect
                            name="organism"
                            size="small"
                            control={control}
                            label="Organism"
                            required={true}
                            options={organismOptions}
                        />
                        <FormInputSelect
                            name="application"
                            size="small"
                            control={control}
                            label="Application"
                            required={true}
                            disabled={!organismValue}
                            options={applicationOptions}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 3 }}>
                            <LoadingButton
                                variant="contained"
                                loading={gettingPresets}
                                type="submit"
                            >
                                Next
                            </LoadingButton>
                        </Box>
                        <Link sx={{ fontSize: 13, textAlign: "center", cursor: 'pointer' }} onClick={() => setCurrentStep(2)}>
                            Skip configuration and go to advanced mode
                        </Link>
                    </Stack>
                </form>
            </Box>
        </>
    );
}

export default OptimizationApplicationComponent
