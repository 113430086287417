import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    TextField,
    Box,
    Stack,
    CircularProgress,
    Button,
    Typography,
    Grid,
    Card,
    CardContent
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateTemplate, getTemplateById } from "../../api/TemplateQueries";
import { Template } from "../../models/Template";
import TemplateComponentsView from "../../components/TemplateComponent/TemplateComponents";
import { TemplateComponent } from "../../models/TemplateComponent";
import { TemplateComponentOption } from "../../models/TemplateComponentOption";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showAlert } from "../../components/Alert/alertSlice";
import TemplateViewer from "../../components/TemplateViewer/TemplateViewer";

function TemplatesPage() {
    const { id } = useParams()
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch()

    const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>()
    const [templateName, setTemplateName] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [savingTemplate, setSavingTemplate] = useState(false)
    const [loadingTemplate, setLoadingTemplate] = useState(true)

    const getCurrentTemplate = async () => {
        setLoadingTemplate(true)
        const accessToken = await getAccessTokenSilently()
        const template = await getTemplateById(accessToken, id!)
        if (template) {
            setCurrentTemplate(template);
            setTemplateDescription(template.description)
            setTemplateName(template.name)
        }
        setLoadingTemplate(false)
    }

    useEffect(() => {
        getCurrentTemplate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const saveTemplate = async () => {
        setSavingTemplate(true)

        const template: Template = {
            "name": templateName,
            "description": templateDescription,
            "components": currentTemplate ? currentTemplate.components : []
        }

        const accessToken = await getAccessTokenSilently()
        const newTemplate = await updateTemplate(accessToken, id!, template) as Template
        if (newTemplate) {
            setCurrentTemplate(newTemplate)
            dispatch(showAlert({ message: "Template successfully updated", severity: "success" }))
        }

        setSavingTemplate(false);
    }

    const updateTemplateComponent = (componentToUpdate: TemplateComponent) => {
        if (currentTemplate) {
            const newComponents = currentTemplate.components.filter(component => component.id !== componentToUpdate.id)
            newComponents.push(componentToUpdate)
            const newTemplate = { ...currentTemplate, components: newComponents }
            setCurrentTemplate(newTemplate)
        }
    }

    const updateAllTemplateComponents = (components: TemplateComponent[]) => {
        if (currentTemplate) {
            const newTemplate = { ...currentTemplate, components }
            setCurrentTemplate(newTemplate)
        }
    }

    const removeTemplateComponent = (componentId: string) => {
        if (currentTemplate) {
            const newComponents = currentTemplate.components.filter(component => component.id !== componentId)
            const newTemplate = { ...currentTemplate, components: newComponents }
            setCurrentTemplate(newTemplate)
        }
    }

    const updateTemplateComponentOption = (componentToUpdate: TemplateComponent, optionToUpdate: TemplateComponentOption) => {
        if (currentTemplate) {
            const newComponents = currentTemplate.components.filter(component => component.id !== componentToUpdate.id)

            const newComponent = { ...componentToUpdate }
            let optionExists = false
            const newOptions = newComponent.options!.map((option) => {
                if (option.id === optionToUpdate.id) {
                    optionExists = true
                    return optionToUpdate
                }
                return option
            })
            if (!optionExists) {
                newOptions.push(optionToUpdate)
            }
            newComponent.options = newOptions

            newComponents.push(newComponent)
            const newTemplate = { ...currentTemplate, components: newComponents }
            setCurrentTemplate(newTemplate)
        }
    }



    const removeTemplateComponentOption = (componentToUpdate: TemplateComponent, optionId: string) => {
        if (currentTemplate) {
            const newComponents = currentTemplate.components.filter(component => component.id !== componentToUpdate.id)

            const newComponent = { ...componentToUpdate }
            const newOptions = newComponent.options!.filter((option) => option.id !== optionId)
            newComponent.options = newOptions

            newComponents.push(newComponent)
            const newTemplate = { ...currentTemplate, components: newComponents }
            setCurrentTemplate(newTemplate)
        }
    }


    return (
        <Stack direction='column' spacing={3} justifyContent='center' sx={{ p: 5 }}>
            {loadingTemplate ?
                <Box display='flex' justifyContent='center'>
                    <CircularProgress />
                </Box>
                :
                <Stack direction='column' spacing={3}>
                    <Grid container flex={1} sx={{ pt: 4 }}>
                        <Grid item md={6}>
                            <Stack direction='column' spacing={3}>
                                <Typography variant="h5">Edit template</Typography>
                                <TextField
                                    id="outlined-basic"
                                    label="Name"
                                    size="small"
                                    variant="outlined"
                                    autoComplete="off"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Description"
                                    size="small"
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                    autoComplete="off"
                                    value={templateDescription}
                                    onChange={(e) => setTemplateDescription(e.target.value)}
                                />
                                <TemplateComponentsView
                                    template={currentTemplate!}
                                    updateAllComponents={updateAllTemplateComponents}
                                    updateTemplateComponent={updateTemplateComponent}
                                    removeTemplateComponent={removeTemplateComponent}
                                    updateTemplateComponentOption={updateTemplateComponentOption}
                                    removeTemplateComponentOption={removeTemplateComponentOption}
                                />
                            </Stack>
                        </Grid>
                        <Grid item md={6}>
                            <Stack direction='column' spacing={3} justifyContent='start' alignItems='center'>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                    PREVIEW
                                </Typography>
                                {currentTemplate?.components.length === 0 ?
                                    <Typography variant="body2" sx={{ width: "60%" }} textAlign='center'>
                                        Define at least 1 component using the menu on the left to see the preview.
                                    </Typography> :
                                    <Card sx={{ width: 'fit-content', maxWidth: '50%', borderRadius: '16px'}}>
                                        <CardContent sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                            <TemplateViewer template={currentTemplate!} sx={{ height: '400px' }} />
                                        </CardContent>
                                    </Card>
                                }
                            </Stack>
                        </Grid>
                    </Grid >
                    <Stack direction="row" spacing={3} sx={{ width: '100%' }} justifyContent='center'>
                        <LoadingButton
                            variant="contained"
                            loading={savingTemplate}
                            disabled={!templateName || !templateDescription}
                            onClick={saveTemplate}
                        >
                            Save
                        </LoadingButton>
                        <Link to="/templates">
                            <Button variant="outlined">
                                Back
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            }
        </Stack>
    )
}

export default TemplatesPage
