import { Template } from "../models/Template";
import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';

export const createTemplate = async (accessToken: string, template: Template) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(template)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while creating template', severity: 'error' }))
    }
}

export const getTemplatesForUser = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting construct templates', severity: 'error' }))
    }
}

export const getAvailableTemplatesForUser = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/available`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting construct templates', severity: 'error' }))
    }
}


export const getTemplates = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting construct templates', severity: 'error' }))
    }
}

export const getTemplateById = async (accessToken: string, templateId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/${templateId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting template', severity: 'error' }))
    }
}

export const updateTemplate = async (accessToken: string, id: string, template: Template) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/${id}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(template)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while updating template', severity: 'error' }))
    }
}

export const deleteTemplate = async (accessToken: string, templateId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/${templateId}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while deleting template', severity: 'error' }))
    }
}

export const duplicateTemplate = async (accessToken: string, templateId: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/templates/duplicate/${templateId}`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while duplicating template', severity: 'error' }))
    }
}

