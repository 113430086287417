import { Tooltip } from "@mui/material";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { BranchProps } from "../../utils/toolbarTreeByRole";
import "./Branch.css"


export default function Branch({ label, value, Icon, href }: BranchProps) {
  const location = useLocation();

  const isActive = useMemo(() => {
    return location.pathname === href ? "block active" : "block"
  }, [location, href])

  return (
    <Tooltip className="branch" title={label} placement="right-start">
      <Link className={isActive} to={href}>
        <Icon fontSize="medium" sx={{color: 'secondary.contrastText'}}/>
      </Link>
    </Tooltip>

  )
}
