import { ListItemButton, Card, Stack } from "@mui/material";
import List from '@mui/material/List';
import { useState, useMemo } from "react";
import SeqViz from "seqviz";
import { TemplateComponentOption } from "../../models/TemplateComponentOption";

interface OptionViewerProps {
    options?: TemplateComponentOption[]
}

export default function OptionViewer({ options }: OptionViewerProps) {
    const [selectedItem, setSelectedItem] = useState(options?.at(0));
    const annotations = useMemo(() => {
        if (selectedItem && typeof selectedItem?.value === 'string') return [{
            start: 0,
            end: selectedItem.value.length,
            name: selectedItem.name
        }]
        return []
    }, [selectedItem])

    return (
        <Card sx={{ width: '100%', marginTop: '1rem !important' }}>
            <Stack direction='row' justifyContent={'start'} alignItems='start' sx={{ width: '100%', height: 300 }}>
                <List
                    sx={{ width: '100%', height: 250, bgcolor: 'background.paper', margin: '1rem', overflow: 'auto' }}
                    component="nav"
                >
                    {Array.isArray(options) &&
                        options.map((option) => (
                            <ListItemButton
                                key={option.id!}
                                selected={selectedItem?.id == option.id}
                                onClick={() => setSelectedItem(option)}
                            >
                                {option.name}
                            </ListItemButton>
                        ))
                    }
                </List>
                <Stack sx={{ width: '100%', height: '100%' }}>
                    <SeqViz
                        seqType="dna"
                        style={{ marginBottom: '2rem', margin: '1rem 0 2rem 0' }}
                        viewer="linear"
                        seq={selectedItem?.value}
                        annotations={annotations}
                    />
                </Stack>
            </Stack>
        </Card >
    )
}
