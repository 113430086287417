import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Modal,
  IconButton,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Process } from "../../../models/Process";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";
import { Template } from "../../../models/Template";
import { deleteProcess } from "../../../api/ProcessQueries";
import './ProcessCard.css'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import TemplateViewer from "../../../components/TemplateViewer/TemplateViewer";

interface ProcessCardProps {
  currentProcess: Process;
  processes: Process[];
  setProcesses: (processes: Process[]) => void;
  template: Template | undefined;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 10,
  borderRadius: "16px",
  p: 6,
};

function ProcessCard({
  currentProcess,
  processes,
  setProcesses,
  template,
}: ProcessCardProps) {
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState<string | undefined>();

  const { getAccessTokenSilently } = useAuth0();

  const handleDeleteProcess = async () => {
    if (currentProcess?.id) {
      setDeletingId(currentProcess.id);
      const accessToken = await getAccessTokenSilently();
      const deletedProcess = await deleteProcess(accessToken, currentProcess.id);
      if (deletedProcess) {
        const newProcesses = processes.filter((process) => process.id !== currentProcess.id);
        setProcesses(newProcesses);
      }
      setDeleteModal(false);
      setDeletingId(undefined);
    }
  };

  return (
    <Card
      sx={{
        minHeight: 175,
        m: 2,
        p: 5,
        borderRadius: "16px",

      }}
    >
      <CardContent>
        <Stack
          sx={{ mb: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            color="secondary.contrastText"
            gutterBottom
            sx={{ fontSize: 22 }}
          >
            {currentProcess.name}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <EditIcon
              onClick={() => navigate(`/process/${currentProcess.id}`)}
              sx={{ ml: 2, width: 25, height: 25, ":hover": { cursor: "pointer" } }}
            />
            <DeleteIcon
              onClick={() => setDeleteModal(true)}
              sx={{ ml: 2, width: 25, height: 25, ":hover": { cursor: "pointer" } }}
            />
          </Stack>
        </Stack>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Output: {getConstantDisplayName(currentProcess.output)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} className="description-card-text" color="text.secondary" gutterBottom>
          Description: {currentProcess.description}
        </Typography>
        <Typography sx={{ fontSize: 14 }} className="description-card-text" color="text.secondary" gutterBottom>
          Template Name: {template?.name}
        </Typography>
        <Typography sx={{ fontSize: 14 }} className="description-card-text" color="text.secondary" gutterBottom>
          Template Description: {template?.description}
        </Typography>
        <TemplateViewer template={template!} sx={{ height: '350px' }} hideText={true} />
      </CardContent>

      {deleteModal && (
        <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
          <Box sx={style}>
            <Stack direction='column' alignItems='center' spacing={5}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                  Are you sure you want to delete {currentProcess.name}?
                </Typography>
                <IconButton onClick={() => setDeleteModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <LoadingButton
                variant='contained'
                color='error'
                loading={!!deletingId && deletingId === currentProcess.id}
                onClick={handleDeleteProcess}
              >
                Delete
              </LoadingButton>
            </Stack>
          </Box>
        </Modal>
      )}
    </Card>
  );
}

export default ProcessCard;
