import React, { useState, useMemo } from "react";
import { Grid, Typography, TextField, Stack, FormControl, RadioGroup, FormControlLabel, Radio, Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth0 } from "@auth0/auth0-react";
import { OptimizationInput, Parameter, SequenceType } from "../../../models/Optimization";
import { DEFAULT_PARAMETERS } from "./constants";
import KentParametersForm from "../../../components/KentParamatersForm/KentParamatersForm";

// These parameters will be sent to the KentParamtersForm
export type SequenceParameter = {
    weight: number,
    codon_subseq: string,
    name: "Sequence",
    id: number,
    mode: "forbidden" | "enforce"
}

export type CodonSelectionCriteriaParameter = {
    weight: number,
    organism: string,
    cell_type: string,
    parset_name: string,
    name: "CodonSelectionCriteria",
    mode: "min" | "max" | string
}

export type GcContentParameter = {
    weight: number,
    target: "min" | "max" | string,
    name: "GcContent"
}

export type FrameshiftParameter = {
    weight: number,
    target: "min" | "max" | string,
    name: "Frameshift"
}

export type NucleotideRepeatsParameter = {
    id: number
    weight: number,
    target: "min" | "max" | string,
    name: "NucleotideRepeats",
    nucleotide: "A" | "T" | "C" | "G",
    repeats: number
}
export type UTRParameter = {
    name: "UTRs",
    '3_UTR': string,
    '5_UTR': string,
    weight: number
}

export type KentParameters = (SequenceParameter | CodonSelectionCriteriaParameter | GcContentParameter | NucleotideRepeatsParameter | FrameshiftParameter | UTRParameter)[]

interface OptimizationInputComponentProps {
    setCurrentStep: (step: number) => void
    defaultSequence?: string
    parameters: Parameter[]
    optimizationInput: OptimizationInput | undefined
    optimizeSequence: (email: string, sequence: string, sequenceType: string, kentParameters: KentParameters) => Promise<void>
}

const parseKentParametersFromInput = (parameters: Parameter[]) => {
    const kentParameters: KentParameters = []
    parameters.forEach(parameter => {
        if (parameter.name === "Sequence") {
            const kentParameter: SequenceParameter = {
                weight: parameter.weight,
                codon_subseq: parameter.codon_subseq!,
                name: "Sequence",
                id: parameter.id!,
                mode: parameter.mode! as 'enforce' | 'forbidden'
            }
            kentParameters.push(kentParameter)
        }
        else if (parameter.name === "CodonSelectionCriteria") {
            const kentParameter: CodonSelectionCriteriaParameter = {
                weight: parameter.weight,
                organism: parameter.organism!,
                cell_type: parameter.cell_type!,
                parset_name: parameter.parset_name!,
                name: "CodonSelectionCriteria",
                mode: parameter.mode!
            }
            kentParameters.push(kentParameter)
        }
        else if (parameter.name === "GcContent") {
            const kentParameter: GcContentParameter = {
                weight: parameter.weight,
                target: String(parameter.target!) as "min" | "max" | string,
                name: "GcContent"
            }
            kentParameters.push(kentParameter)
        }
        else if (parameter.name === "Frameshift") {
            const kentParameter: FrameshiftParameter = {
                weight: parameter.weight,
                target: String(parameter.target!) as "min" | "max" | string,
                name: "Frameshift"
            }
            kentParameters.push(kentParameter)
        }
        else if (parameter.name === "NucleotideRepeats") {
            const kentParameter: NucleotideRepeatsParameter = {
                id: parameter.id!,
                weight: parameter.weight,
                target: String(parameter.target! as "min" | "max" | string),
                name: "NucleotideRepeats",
                nucleotide: parameter.nucleotide as "A" | "T" | "C" | "G",
                repeats: parameter.repeats!
            }
            kentParameters.push(kentParameter)
        } else if (parameter.name === "UTRs") {
            // If a preset was used, this parameter comes, but we shouldn't show it. Just pass it back to the backend.
            const kentParameter: UTRParameter = {
                name: "UTRs",
                '3_UTR': parameter['3_UTR']!,
                '5_UTR': parameter['5_UTR']!,
                weight: parameter.weight
            }
            kentParameters.push(kentParameter)
        }
    })
    return kentParameters;
}


function OptimizationParametersComponent({ setCurrentStep, optimizationInput, parameters, optimizeSequence, defaultSequence = "" }: OptimizationInputComponentProps) {
    const { user } = useAuth0();

    const [kentParameters, updateKentParameters] = useState<KentParameters>(
        optimizationInput ? parseKentParametersFromInput(optimizationInput.parameters) :
            parameters ? parseKentParametersFromInput(parameters) :
                DEFAULT_PARAMETERS)
    const [sequence, setSequence] = useState(optimizationInput ? optimizationInput.sequence : defaultSequence)
    const [loading, setLoading] = useState(false)
    const [sequenceType, setSequenceType] = useState(optimizationInput ? optimizationInput.sequenceType : "dna")
    const [viewAdvancedParameters, setViewAdvancedParameters] = useState(false)

    const readOnly = optimizationInput === undefined ? false : true

    const isValidDNA = useMemo(() => {
        if (sequence.length % 3 !== 0 && sequenceType === 'dna') {
            return true
        }
        else {
            return false
        }
    }, [sequence, sequenceType]);


    const handleSequenceTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSequenceType(event.target.value as SequenceType);
    };

    const optimize = async () => {
        setLoading(true)
        if (user && user.email) {
            await optimizeSequence(user.email, sequence, sequenceType, kentParameters)
        }
        setLoading(false)
    }

    return (
        <Box sx={(theme) => ({
            overflowY: 'auto',
            maxHeight: '70vh',
            pr: 10, pl: 10,
            scrollbarWidth: 'thin',
            scrollbarColor: `${theme.palette.secondary.light} transparent`
        })}>
            <TextField
                sx={{ mt: 1 }}
                value={sequence}
                disabled={readOnly}
                onChange={(e) => setSequence(e.target.value)}
                className="text-box"
                label="Paste your sequence here"
                multiline
                fullWidth
                minRows={5}
                autoComplete="off"
                variant="outlined" />
            {isValidDNA &&
                <div>
                    <Typography sx={{ mt: 1 }} variant='body2' color='red'>DNA sequence must be a multiple of 3. {sequence.length % 3 === 2 ? 'Subtract two or add one nucleotide' : 'Subtract one or add two nucleotides'}</Typography>
                </div>
            }
            <Typography variant="body1" sx={{ mt: 4, mb: 4 }}>
                Parameters:
            </Typography>
            <Grid container alignItems="center">
                <Grid item md={3}>Sequence type</Grid>
                <Grid item md={3}>
                    <FormControl>
                        <RadioGroup row value={sequenceType} onChange={handleSequenceTypeChange}>
                            <FormControlLabel disabled={readOnly} value={"dna"} control={<Radio />} label="DNA" />
                            <FormControlLabel disabled={readOnly} value={"aa"} control={<Radio />} label="AA" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Button variant="outlined" size="small" sx={{ mt: 3, mb: 3 }} onClick={() => setViewAdvancedParameters(!viewAdvancedParameters)}>
                {viewAdvancedParameters ? "Hide advanced parameters" : "View advanced parameters"}
            </Button>
            {viewAdvancedParameters &&
                <KentParametersForm
                    readOnly={readOnly}
                    kentParameters={kentParameters}
                    updateKentParameters={updateKentParameters}
                />
            }
            {!readOnly &&
                <Stack className="optimize-button" direction="row" justifyContent="center" spacing={2}>
                    <Button
                        sx={{ width: 'fit-content' }}
                        variant="outlined"
                        onClick={() => setCurrentStep(parameters.length === 0 ? 0 : 1)}
                    >
                        Back
                    </Button>
                    <LoadingButton
                        loading={loading}
                        sx={{ m: 4 }}
                        color="primary"
                        variant="contained"
                        onClick={optimize}
                    >
                        Optimize!
                    </LoadingButton>
                </Stack>}
        </Box>
    )
}

export default OptimizationParametersComponent
