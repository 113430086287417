import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';

export const getSecondaryStructure = async ( accessToken: string, sequence: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/secondary-structure/`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({sequence})
            }
        );
        const data = await response.json();

        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting secondary structure', severity: 'error' }))
    }
}
