import { Box, Button, Card, CardActions, CardContent, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { createProduct } from "../../api/ProductQueries";
import { Product } from "../../models/Product";
import { Template } from "../../models/Template";
import { ProcessOutput } from "../../models/Process";
import { Run } from "../../models/Run";
import { getAvailableRuns } from "../../api/RunQueries";
import TemplateViewer from "../TemplateViewer/TemplateViewer";
import { LoadingButton } from "@mui/lab";
import ReactCardFlip from "react-card-flip";
import CloseIcon from '@mui/icons-material/Close';
import { RunCardDetails } from "../../pages/AdminPages/BrokersDashboardPage/RunCardDetail";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};


interface RunSelectionCardProps {
  run: Run
  create: (run: Run, runTemplate: Template) => void
  selectedRunId: string | undefined
}

const RunSelectionCard = ({ run, create, selectedRunId }: RunSelectionCardProps) => {
  const [showTemplate, setShowTemplate] = useState<boolean>(true)

  // Need to define the content explicitly because otherwise the card flip won't work
  const runHeight = '550px'
  const runContentHeight = '400px'
  return (
    <Card sx={{ height: runHeight }}>
      <Typography variant="h6" textAlign="center" fontSize={16} sx={{ paddingTop: '20px' }}>
        {run.name}
      </Typography>
      <CardContent sx={{ height: { runContentHeight } }}>
        <ReactCardFlip isFlipped={!showTemplate} flipDirection="horizontal" >
          <TemplateViewer template={run.process!.template!} sx={{ height: runContentHeight }} key="front" />

          <Stack direction='column' justifyContent='left' alignItems='left' sx={{ height: runContentHeight, overflowY: 'auto' }} key="back">
            <RunCardDetails run={run} />
          </Stack>
        </ReactCardFlip>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          sx={{ marginLeft: '20px' }}
          onClick={() => setShowTemplate(!showTemplate)}>{showTemplate ? "View details" : "View template"}
        </Button>
        <LoadingButton
          onClick={() => create(run, run.process!.template!)}
          variant='contained'
          size="small"
          loading={selectedRunId === run.id}
          disabled={selectedRunId !== undefined && selectedRunId !== run.id}
        >
          Select
        </LoadingButton>
      </CardActions>
    </Card>
  )
}

interface RunSelectionBodyProps extends Omit<RunSelectionCardProps, 'run'> {
  filteredRuns: Run[]
}

const RunSelectionBody = ({ filteredRuns, create, selectedRunId }: RunSelectionBodyProps) => {
  return (
    filteredRuns.length === 0 ?
      <Typography variant="body2" textAlign="center" sx={{ width: '100%' }}>
        No runs available for this output type
      </Typography>
      :
      <>
        {
          filteredRuns.map((run: Run, index: number) => {
            return (
              <Grid item sm={6} md={4} key={index}>
                <RunSelectionCard
                  run={run}
                  create={create}
                  selectedRunId={selectedRunId}
                />
              </Grid>
            );
          })
        }
      </>
  )
}

interface CreateModalProps {
  showModal: boolean
  closeModal: () => void
  reloadProducts: () => void
}

const CreateModal = ({ showModal, closeModal, reloadProducts }: CreateModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate()

  const [selectedOutput, setSelectedOutput] = useState<ProcessOutput>('DRUG_SUBSTANCE' as ProcessOutput)
  const [runs, setRuns] = useState<Run[]>([]);
  // const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedRunId, setSelectedRunId] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)

  const grabRuns = async () => {
    const accessToken = await getAccessTokenSilently();
    const runData = await getAvailableRuns(accessToken);
    if (Array.isArray(runData)) {
      setRuns(runData);
    }
  };

  const getRunsAndTemplates = async () => {
    setLoading(true)
    await grabRuns();
    setLoading(false)
  }

  useEffect(() => {
    getRunsAndTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const create = async (run: Run, runTemplate: Template) => {
    setSelectedRunId(run.id)

    // Default components. For the fixed component already set the value, for the fixed options, set the first.
    // TODO: not sure why this is done in the frontend, should probably be done by the backend endpoint.
    const components = runTemplate.components.map((component) => {
      if (component.type === 'FIXED' || component.type === 'SEQUENCE_TBD') {
        return {
          name: component.name,
          position: component.position,
          sequence: component.value!
        }
      } else if (component.type === 'FIXED_OPTIONS') {
        return {
          name: component.name,
          position: component.position,
          sequence: component.options!.find(option => option.isDefault)?.value ?? component.options![0].value
        }
      } else if (component.type === 'OPTIONS_OR_USER_INPUT') {
        return {
          name: component.name,
          position: component.position,
          sequence: component.options?.find(option => option.isDefault)?.value ?? ''
        }
      } else {
        return {
          name: component.name,
          position: component.position,
          sequence: ''
        }
      }
    })

    let product: Product = {
      name: 'Unnamed Construct',
      runId: run.id,
      components,
      formulation: run.formulation,
      formulationDetails: run.formulationDetails,
      annotations: []
    }

    createProductAndRedirect(product)
  }

  const createProductAndRedirect = async (product: Product) => {
    const accessToken = await getAccessTokenSilently()
    const result = await createProduct(accessToken, product)

    // Insert a delay so it doesn't look like a glitch
    await new Promise(resolve => setTimeout(resolve, 1000))

    setSelectedRunId(undefined)
    closeModal()

    if (result) {
      reloadProducts()
      navigate(`/constructs/${result.id}`)
    }
  }


  const filteredRunCards = useMemo(() => {
    const filteredRuns = runs.filter(run => run.process!.output === selectedOutput).filter(run => run.status === 'PUBLISHED')
    return (
      <RunSelectionBody
        filteredRuns={filteredRuns}
        // templates={templates}
        create={create}
        selectedRunId={selectedRunId}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runs, selectedOutput, selectedRunId])


  const skeletons = useMemo(() => {
    return Array.from(new Array(3)).map((_, index) => (
      <Grid item xs={4} key={index}>
        <Skeleton sx={{ m: 2 }} variant="rectangular" height={'400px'} />
      </Grid>
    ))
  }, [])


  return (
    <Modal open={showModal} onClose={() => closeModal()}>
      <Box sx={style}>
        <Stack direction="column" spacing={4} alignItems='center' sx={{ maxHeight: '100%' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant="h6" component="h2" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Select a Run
            </Typography>
            <IconButton onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <FormControl>
            <FormLabel id="radio-buttons-group-label">Output</FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setSelectedOutput(e.target.value as ProcessOutput)}
              value={selectedOutput}
            >
              <FormControlLabel value="DRUG_SUBSTANCE" control={<Radio />} label="Purified drug substance" />
              <FormControlLabel value="FORMULATED_DRUG_PRODUCT" control={<Radio />} label="Formulated drug substance" />
            </RadioGroup>
          </FormControl>
          <Grid container columnSpacing={{ md: 3 }} rowSpacing={{ md: 2 }} sx={{ paddingBottom: '20px' }}>
            {loading
              ?
              skeletons
              :
              filteredRunCards
            }
          </Grid>
        </Stack>
      </Box>
    </Modal>
  )
}

export default CreateModal
