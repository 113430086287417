import { Box } from "@mui/material"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import toolbarTreeByRole, { BranchProps } from "../../utils/toolbarTreeByRole"
import { selectRole } from "../NavBar/navBarSlice"
import Branch from "./Branch"
import "./Toolbar.css"

/*
 * The Toolbar is made of two segments: primary and secundary
 * segments. The primary segment is the most simple one, with
 * a set of icons representing actions (defined by role).
 * 
 * Some actions may have a expandable and secondary segment,
 * representing the options available for a given action. An
 * example would be select design action would open up a
 * secondary segment of the toolbar with the list of designs
 * created by the user.
 */

function Toolbar() {
  const role = useSelector(selectRole)
  const tree = useMemo(() => toolbarTreeByRole(role), [role])

  return(
    <Box className="toolbar" sx={{ backgroundColor: 'secondary.dark'}}>
      { 
        tree.map((branchProps: BranchProps) => 
          <Branch key={branchProps.value} { ...branchProps} />
        )
      }
    </Box>
  )
}

export default Toolbar
