import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, SxProps } from "@mui/material";
import { FieldSize } from ".";
import { ReactNode } from "react";

interface ComponentSx {
    formControl?: SxProps
    inputLabel?: SxProps
    select?: SxProps
}

interface FormInputSelectOptions {
    value: string;
    label: string | ReactNode;
}
interface FormInputSelectProps {
    childrenSx?: ComponentSx;
    name: string;
    size?: FieldSize;
    control: any;
    label: string;
    required: boolean;
    disabled?: boolean;
    options: FormInputSelectOptions[];
}

export const FormInputSelect = ({ childrenSx, name, size, control, label, required, options, disabled }: FormInputSelectProps) => (
    <Controller
        name={name}
        control={control}
        render={({
            field: { value, onChange, onBlur, ref },
            fieldState: { error },
        }) => (
            <FormControl sx={{ width: '100%', ...childrenSx?.formControl }} required={required}>
                <InputLabel sx={childrenSx?.inputLabel} id={label} size='small'>{label}</InputLabel>
                <Select
                    sx={childrenSx?.select}
                    size={size}
                    disabled={disabled}
                    labelId={label}
                    value={value}
                    label={label}
                    onChange={onChange}
                    inputRef={ref}
                    onBlur={onBlur}
                    error={Boolean(error)}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
    />
);
