import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    TextField,
    Box,
    Button,
    Grid,
    Stack,
    Typography,
    Skeleton,
    CardContent,
    Card,
    CardActions,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { createTemplate, deleteTemplate, duplicateTemplate, getTemplatesForUser } from "../../api/TemplateQueries";
import { Template } from "../../models/Template";
import Modal from '@mui/material/Modal';
import TemplateViewer from "../../components/TemplateViewer/TemplateViewer";
import { useNavigate, useSearchParams } from "react-router-dom";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function AllTemplatesPage() {
    const { getAccessTokenSilently } = useAuth0();
    const [newTemplateName, setNewTemplateName] = useState("");
    const [newTemplateDescription, setNewTemplateDescription] = useState("");
    const [templates, setTemplates] = useState<Template[]>([]);
    const [creatingTemplate, setCreatingTemplate] = useState(false);
    const [loadingTemplates, setLoadingTemplates] = useState(true)
    const [deletingTemplateId, setDeletingTemplateId] = useState<string | undefined>()
    const [duplicatingTemplateId, setDuplicatingTemplateId] = useState<string | undefined>()

    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    const getAllTemplates = async () => {
        setLoadingTemplates(true)
        const accessToken = await getAccessTokenSilently()
        const templates = await getTemplatesForUser(accessToken)
        if (templates) {
            setTemplates(templates);
        }
        setLoadingTemplates(false)
    }

    useEffect(() => {
        getAllTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const createNewTemplate = async () => {
        setCreatingTemplate(true)

        const template: Template = {
            "name": newTemplateName,
            "description": newTemplateDescription,
            "components": []
        }

        const accessToken = await getAccessTokenSilently()

        const result = await createTemplate(accessToken, template)
        if (result) {
            navigate(`/templates/${result.id}`)
        }

        setCreatingTemplate(false);
    }

    const duplicate = async (templateId: string) => {
        setDuplicatingTemplateId(templateId)
        const accessToken = await getAccessTokenSilently()
        const result = await duplicateTemplate(accessToken, templateId)
        if (result) {
            setTemplates([...templates, result])
        }
        setDuplicatingTemplateId(undefined)
    }

    const removeTemplate = async (id: string) => {
        setDeletingTemplateId(id)
        const accessToken = await getAccessTokenSilently()
        const response = await deleteTemplate(accessToken, id)
        if (response) {
            const newTemplates = templates.filter(template => template.id !== id)
            setTemplates(newTemplates)
        }
        setDeletingTemplateId(undefined)
    }

    const editTemplate = async (id: string) => navigate(`/templates/${id}`)

    const openModal = useMemo(() => searchParams.get('create') === 'true', [searchParams])

    const templateCards = useMemo(() => {
        return templates.map((template, index) => (
            <Grid item md={3} sm={6} key={index}>
                <Card sx={{ m: 2, borderRadius: '16px', p:1, pb:2}}>
                    <CardContent>
                        <TemplateViewer template={template} sx={{ height: '380px' }} />
                    </CardContent>
                    <CardActions>
                        <Grid container direction="row" justifyContent='center' columnSpacing={1} rowSpacing={1} sx={{p:1}}>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => editTemplate(template.id!)}
                                >
                                    Edit
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    onClick={() => removeTemplate(template.id!)}
                                    variant='contained'
                                    color='error'
                                    size="small"
                                    loading={deletingTemplateId === template.id}
                                >
                                    Delete
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    onClick={() => duplicate(template.id!)}
                                    variant='outlined'
                                    color='primary'
                                    size="small"
                                    loading={duplicatingTemplateId === template.id}
                                >
                                    Duplicate
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Grid>
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates, deletingTemplateId, duplicatingTemplateId])

    const skeletons = useMemo(() => {
        return Array.from(new Array(3)).map((_, index) => (
            <Grid item xs={3} key={index}>
                <Skeleton sx={{ m: 2 }} variant="rectangular" height={'400px'} />
            </Grid>
        ))
    }, [])


    return (
        <Stack direction='column' spacing={5} alignItems='center'>
            <Typography variant="h5" sx={{ mt: 5 }}>Your templates</Typography>
            <Button
                onClick={() => navigate('/templates?create=true')}
                variant="outlined"
                startIcon={<AddIcon />}>
                Create new template
            </Button>
            <Grid container sx={{alignContent: 'center', justifyContent: 'center'}} rowSpacing={1}>
                {loadingTemplates
                    ?
                    skeletons
                    :
                    templateCards
                }
            </Grid>

            <Modal
                open={openModal}
                onClose={() => navigate('/templates')}
            >
                <Box sx={modalStyle}>
                    <CloseIcon sx={{ cursor: "pointer", float: "right" }} onClick={() => navigate('/templates')} />
                    <Stack direction="column" spacing={4}>
                        <Typography variant="h6" textAlign="center" sx={{ mt: 3 }}>
                            Create new template
                        </Typography>
                        <TextField
                            sx={{ minWidth: 300 }}
                            id="outlined-basic"
                            label="Template name"
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            value={newTemplateName}
                            onChange={(e) => setNewTemplateName(e.target.value)}
                        />
                        <TextField
                            sx={{ minWidth: 300 }}
                            id="outlined-basic"
                            multiline
                            minRows={3}
                            label="Template description"
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            value={newTemplateDescription}
                            onChange={(e) => setNewTemplateDescription(e.target.value)}
                        />
                        <LoadingButton
                            sx={{ ml: 2 }}
                            variant="contained"
                            loading={creatingTemplate}
                            disabled={!newTemplateName || !newTemplateDescription}
                            onClick={() => createNewTemplate()}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </Stack>
    );
}

export default AllTemplatesPage
