import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getAllCQAReports } from "../../api/ManufacturerQueries";
import { CQAReport } from "../../models/CQAReport";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CQAReportsOverTimeGraph() {
  const { getAccessTokenSilently } = useAuth0();
  const [dates, setDates] = useState<string[]>([])
  const [numberOfReports, setNumberOfReports] = useState<number[]>([])

  const getCQAReports = async () => {
    const accessToken = await getAccessTokenSilently()
    const reports = await getAllCQAReports(accessToken)
    if (reports) {
      parseResults(reports)
    }
  }

  const parseResults = (reports: CQAReport[]) => {
    const uniqueDates: string[] = []
    const numberOfReportsPerDay: number[] = []
    reports.forEach((report) => {
      // This assumes the results came ordered by date, which they do
      const date = new Date(report.date).toLocaleString().split(',')[0];
      if (!uniqueDates.includes(date)) {
        uniqueDates.push(date)
        if (numberOfReportsPerDay.length > 0) {
          // Add 1 to the previous number of reports
          numberOfReportsPerDay.push(1 + numberOfReportsPerDay[numberOfReportsPerDay.length - 1])
        } else {
          numberOfReportsPerDay.push(1)
        }
      } else {
        numberOfReportsPerDay[numberOfReportsPerDay.length - 1] = numberOfReportsPerDay[numberOfReportsPerDay.length - 1] + 1
      }

    })
    setDates(uniqueDates)
    setNumberOfReports(numberOfReportsPerDay)
  }

  useEffect(() => {
    getCQAReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Number of CQA reports over time',
      },
    },
  };

  const data = {
    labels: dates,
    datasets: [
      {
        data: numberOfReports,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };


  return (
    <Line options={options} data={data} />
  )
}

export default CQAReportsOverTimeGraph
