import { Manufacturer } from "../models/Manufacturer";
import { CQAReport } from "../models/CQAReport";
import { showAlert } from "../components/Alert/alertSlice";
import store from '../store/';

export const updateMe = async (accessToken: string, manufacturer: Partial<Manufacturer>) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/manufacturers/me`,
            {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(manufacturer)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while updating your user', severity: 'error' }))
    }

}

export const getManufacturers = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/manufacturers`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting manufacturers', severity: 'error' }))
    }

}

export const getMe = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/manufacturers/me`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your user', severity: 'error' }))
    }

}

export const createCQAReport = async (accessToken: string, record: CQAReport) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/cqa-report`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(record)
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while creating your report', severity: 'error' }))
    }
}

export const getAllCQAReportsForUser = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/cqa-report`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your reports', severity: 'error' }))
    }
}

export const getAllCQAReports = async (accessToken: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/cqa-report/all`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your reports', severity: 'error' }))
    }
}

export const getCQAReport = async (accessToken: string, id: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/cqa-report/${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-type': 'application/json; charset=UTF-8'
                }
            }
        );
        const data = await response.json();
        if (response.ok) {
            return data
        } else {
            const message = await data["message"]
            store.dispatch(showAlert({ message, severity: 'error' }))
        }
    } catch (e) {
        store.dispatch(showAlert({ message: 'Something went wrong while getting your report', severity: 'error' }))
    }
}
