import { useAuth0 } from "@auth0/auth0-react";
import { Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct, setProduct } from "../ProductsSlice";
import { reserveProduct, reviewProduct } from "../../../api/ProductQueries";
import { CapMethodOption, ModifiedNucleotideOption, RunStatus } from "../../../models/Run";
import { getConstantDisplayName } from "../../../utils/getDisplayNames";
import { LoadingButton } from "@mui/lab";
import store from "../../../store";
import { showAlert } from "../../Alert/alertSlice";

interface ProductReserveStepProps {
  setUpdateSideBar?: (update: boolean) => void
  forManufacturer?: boolean
}

const ProductReserveStep = ({ setUpdateSideBar, forManufacturer }: ProductReserveStepProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatchGlobal = useDispatch()

  const { id } = useParams()
  const product = useSelector(selectProduct)
  const [reserving, setReserving] = useState(false)

  const reserve = async () => {
    setReserving(true)
    if (id && setUpdateSideBar) {
      const accessToken = await getAccessTokenSilently()
      const updatedProduct = await reviewProduct(accessToken, id)
      if (updatedProduct) {
        dispatchGlobal(setProduct(updatedProduct))
        setUpdateSideBar(true)
        if (updatedProduct.isRunReserved) {
          store.dispatch(showAlert({ message: 'Congratulations! Your run has reserved your product. You will receive an email with more information', severity: 'success' }))
        } else {
          store.dispatch(showAlert({ message: 'Congratulations! Your run has been requested to review. You will receive an email with more information', severity: 'success' }))
        }
      }
    }
    setReserving(false)
  }

  const shippingDate = useMemo(() => {
    const convertedDate = new Date(String(product?.run?.dateToShip));
    return convertedDate.toLocaleDateString("en-US");
  }, []);

  const startingDate = useMemo(() => {
    if (!product?.run?.dateToStart) return ''
    const convertedDate = new Date(String(product?.run?.dateToStart));
    return convertedDate.toLocaleDateString("en-US");
  }, []);

  const totalCost = useMemo(() => {
    let cost = product!.run!.baseCost
    if (product?.cappingMethod) {
      product?.run?.capMethodOptions?.forEach((option: CapMethodOption) => {
        if (option.name === product?.cappingMethod) {
          cost += option.cost
        }
      })
    }
    if (product?.modifiedNucleotides) {
      product?.run?.modifiedNucOptions?.forEach((option: ModifiedNucleotideOption) => {
        if (option.name === product?.modifiedNucleotides) {
          cost += option.cost
        }
      })
    }
    return cost
  }, [product])

  const synthesisWarning = useMemo(() => {
    if (forManufacturer) {
      return product?.run?.dnaSynthesis ? "This run requires DNA synthesis.  The designer will not be providing a DNA sample." : "This run does not require DNA synthesis.  The designer will provide a DNA sample."
    }
    return product?.run?.dnaSynthesis ? "This run includes DNA synthesis. You won't need to provide a DNA sample to the vendor." : "Warning: This run does not include DNA synthesis. A DNA sample will need to be sent to the vendor."
  }, [])


  if (product) return (
    <Stack direction='column' spacing={3} alignItems='center' sx={{ width: '50%' }}>
      <Typography variant='h6'>Summary</Typography>
      <Typography variant='body1'>Quantity: {product?.quantity}mg</Typography>
      <Typography variant='body1'>Modified nucleotides: {product?.modifiedNucleotides ? getConstantDisplayName(product?.modifiedNucleotides) : 'None'}</Typography>
      <Typography variant='body1'>Capping method: {product?.cappingMethod ? getConstantDisplayName(product?.cappingMethod) : 'None'}</Typography>
      <Typography variant='body1'>Formulation: {product?.formulation ? 'Yes' : 'No'}</Typography>
      {product?.formulation && <Typography variant='body1'>Formulation details from manufacturer: {product?.formulationDetails}</Typography>}
      {product?.formulationDetailsDesigner && <Typography variant='body1'>Formulation details from designer: {product?.formulationDetailsDesigner}</Typography>}
      {startingDate && <Typography variant='body1'>Starting date: {startingDate}</Typography>}
      <Typography variant='body1'>Shipping date: {shippingDate}</Typography>
      {totalCost && (
        <Typography variant='body1'>Total cost: {totalCost} USD</Typography>
      )}
      <Typography variant='body2' sx={{ color: '#fc9803' }} textAlign='center'>
        {synthesisWarning}
      </Typography>
      <LoadingButton
        loading={reserving}
        variant="contained"
        color="success"
        size="large"
        disabled={product?.runIsReserved || product?.run?.status !== RunStatus.PUBLISHED}
        onClick={reserve}
        sx={{ '&&': { m: 3 } }}>
        {product?.runIsReserved ? "This product has already been reserved" : "Submit to review"}
      </LoadingButton>
    </Stack>
  )

  return (
    <Stack direction='column' spacing={3} alignItems='center' sx={{ width: '50%' }}>
    </Stack>
  )
}

export default ProductReserveStep
