export enum ProductViewerActions {
  SET_AUTO_SAVE = 'SET_AUTO_SAVE'
}

interface ProductViewerState {
  autosave: boolean
}

interface ProductViewerPayload {
  autosave: boolean
}

interface ProductViewerAction {
  type: ProductViewerActions;
  payload: ProductViewerPayload
}

function reducer(state: ProductViewerState, action: ProductViewerAction) {
  switch (action.type) {
    case ProductViewerActions.SET_AUTO_SAVE:
      return {
        ...state,
        autosave: action.payload.autosave
      }
    default:
      return state
  }
}

export default reducer
