import { Stack, Typography } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { Run } from "../../../models/Run";
import { getFormattedDate } from "../../../utils/date";
import { IngredientTable } from "./IngredientTable";

interface RunCardDetailsProps {
  run: Run;
  forManufacturers?: boolean;
  children?: ReactNode
}

export function RunCardDetails({ run, forManufacturers, children }: RunCardDetailsProps) {
  const dnaSynthesisText = useMemo(() => (dnaSynthesis: boolean, forManufacturers: boolean | undefined) => {
    if (forManufacturers) {
      return dnaSynthesis ? "This run requires DNA synthesis.  The designer will not be providing a DNA sample." : "This run does not require DNA synthesis.  The designer will provide a DNA sample."
    }
    return dnaSynthesis ? "Yes - A DNA sample will not be provided to the vendor" : "No -  A DNA sample will be provided to the vendor."
  }, [])

  return (
    <>
      <Typography
        sx={{ fontSize: 14, fontWeight: 16 }}

        gutterBottom
      >
        Name: {run.name}
      </Typography>
      <Typography
        sx={{ fontSize: 14, fontWeight: 16 }}
        gutterBottom
      >
        Description: {run.description}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Quantity: {String(run.quantity.min)} - {String(run.quantity.max)} mg
      </Typography>
      {!forManufacturers && run.manufacturer &&
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          Manufacturer: {run.manufacturer.institutionName ?? "Not available"}
        </Typography>
      }
      {forManufacturers && run.designer &&
        <>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            Designer: {run.designer.firstName + " " + run.designer.lastName}
          </Typography>
          <Typography sx={{ fontSize: 14 }} gutterBottom>
            Designer institution: {run.designer.institution ?? "Not available"}
          </Typography>
        </>
      }
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Process: {run.process?.name ?? "None"}
      </Typography>
      {run.process?.description &&
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          Process description: {run.process.description}
        </Typography>
      }
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Base cost: ${run.baseCost.toFixed(2)} (USD)
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Date to submit by: {getFormattedDate(String(run.dateToSubmit))}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Shipping date: {getFormattedDate(String(run.dateToShip))}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        DNA synthesis: {dnaSynthesisText(run.dnaSynthesis, forManufacturers)}
      </Typography>
      <Typography sx={{ fontSize: 14 }} gutterBottom>
        Formulation: {run.formulation ? "Yes" : "No"}
      </Typography>
      {run.formulation &&
        <Typography sx={{ fontSize: 14 }}>
          Formulation details: {run.formulationDetails ?? "Not provided"}
        </Typography>
      }
      <Stack direction="column" spacing={1}>
        {run.modifiedNucOptions.length > 0 ? (
          <>
            <Typography sx={{ fontSize: 14, pt: 2, pb: 2 }}>
              Modified nucleotides:
            </Typography>
            <IngredientTable data={run.modifiedNucOptions} />
          </>
        ) : (
          <Typography sx={{ fontSize: 14 }}>
            No modified nucleotides
          </Typography>
        )}
        {run.capMethodOptions.length > 0 ? (
          <>
            <Typography sx={{ fontSize: 14, pt: 2, pb: 2 }}>
              Capping methods:
            </Typography>
            <IngredientTable data={run.capMethodOptions} />
          </>
        ) : (
          <Typography sx={{ fontSize: 14 }}>
            No capping
          </Typography>
        )}
      </Stack>
      {children && children}
    </>
  )
}
