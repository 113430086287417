import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Typography,
    Stack,
    Button,
    Grid,
} from '@mui/material';
import './AllOptimizationsPage.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
import { OptimizationInput } from "../../../models/Optimization";
import { getAllOptimizations } from "../../../api/OptimizeQueries";


function AllOptimizationsPage() {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate()

    const [optimizations, setOptimizations] = useState<OptimizationInput[]>([])

    const getOptimizations = async () => {
        const accessToken = await getAccessTokenSilently()
        const optimizations = await getAllOptimizations(accessToken)
        if (optimizations) {
            setOptimizations(optimizations)
        }
    }

    useEffect(() => {
        getOptimizations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Grid
                sx={{ p: 5 }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={4}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        startIcon={<ChevronLeftIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign='center' variant="h5">
                        All Optimizations
                    </Typography>
                </Grid>
                <Grid item xs={4} />
            </Grid>
            <Stack direction='column' sx={{ p: 5 }} alignItems='center'>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Id</TableCell>
                                <TableCell align="center">Sequence type</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Designer</TableCell>
                                <TableCell align="center">Designer institution</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {optimizations.map((optimization, index) => (
                                <TableRow key={index} className="optimization-row" onClick={() => navigate(`/optimization/${optimization.id}`)}>
                                    <TableCell align="center" component="th" scope="row">
                                        {optimization.id}
                                    </TableCell>
                                    <TableCell align="center">{optimization.sequenceType}</TableCell>
                                    <TableCell align="center">{new Date(optimization.date).toLocaleString()}</TableCell>
                                    <TableCell align="center">{`${optimization.designer?.firstName} ${optimization.designer?.lastName}`}</TableCell>
                                    <TableCell align="center">{optimization.designer?.institution}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
}

export default AllOptimizationsPage
