import { useEffect, useState } from "react";
import { Grid, Typography, Stack, Box, Button, IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ComponentType, Highlight } from "../../models/Product";
import { Template } from "../../models/Template";
import { Footer } from "../Footer/Footer";

import { useAuth0 } from "@auth0/auth0-react";
import { getTemplateById } from "../../api/TemplateQueries";
import ProductComponentSelector from "./ProductComponentSelector";
import ComponentsSidebar from "./ComponentsSidebar";
import { useParams, useSearchParams } from "react-router-dom";
import SequenceViewer from "./SequenceViewer";
import { useSelector } from "react-redux";
import { selectProduct } from "../ProductWindow/ProductsSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './ProductComponents.css'

interface ProductComponentsProps {
  updateAutoSave: (autosave: boolean) => void 
  highlights: Array<Highlight> | null
}

function ProductComponents({ updateAutoSave, highlights }: ProductComponentsProps) {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const product = useSelector(selectProduct)

    const { getAccessTokenSilently } = useAuth0()

    const componentName = searchParams.get('component')

    const [currentComponent, setCurrentComponent] = useState<ComponentType>();
    const [template, setTemplate] = useState<Template>()
    const [warningModal, setWarningModal] = useState(false);
    const [componentsAreVisible, setComponentsAreVisible] = useState(true);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 7,
        borderRadius: '16px'
    };

    const getTemplate = async (templateId: string): Promise<Template> => {
      const accessToken = await getAccessTokenSilently()
      const template = await getTemplateById(accessToken, templateId)

      return template.at(0)
    }

    const setCurrentProductById = async (currentComponentName?: string | null) => {
      if (product) {
        if (product.run?.process?.template) {
          setTemplate(product.run?.process?.template)
        }
      
        if (currentComponentName) {
          const componentFound = product.components.find((component) => component.name == currentComponentName)
          if (componentFound) setCurrentComponent(componentFound)
        } else {
          setCurrentComponent(product.components[0])
        }
      }

    }

    useEffect(() => {
      if(id) setCurrentProductById(componentName)
    }, [id, componentName, product])
      
    return (
        <>
            <Stack direction="column" sx={{ height: '100%', justifyContent: "space-between", backgroundColor: 'white'}}>
                {
                  (product && product.components.length > 0) && (
                    <Stack direction="row" sx={{ borderRight: 1, borderColor: 'grey.200', pb: 3 }}>
                        <Grid item>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ pl: 3}}>
                              <Typography variant="body2" fontWeight='bold'>
                                  COMPONENTS
                              </Typography>
                              <IconButton onClick={() => setComponentsAreVisible(!componentsAreVisible)}>
                                 {componentsAreVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </Stack>
                            { currentComponent &&
                                <Modal
                                    open={warningModal}
                                    onClose={() => setWarningModal(false)}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Delete component: {currentComponent.name}?
                                        </Typography>
                                        <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2 }}>
                                            <Button sx={{ mr: 2 }} variant='outlined'>Yes</Button>
                                            <Button onClick={() => setWarningModal(false)} variant='outlined'>No</Button>
                                        </Stack>
                                    </Box>
                                </Modal>
                            }
                            { template && product && componentsAreVisible && <ComponentsSidebar components={product.components} template={template} /> }
                        </Grid>
                        <Grid item md={9}>
                            { template && componentsAreVisible &&
                                <ProductComponentSelector
                                    productId={product.id}
                                    template={template}
                                    updateAutoSave={updateAutoSave}
                                />
                            }
                        </Grid>
                    </Stack>
                  )
                }
              
                    <Stack direction="column" sx={{ height: '100%', justifyContent: "flex-end"}}>
                      <SequenceViewer product={product} highlights={highlights} />
                      <Footer product={product} />
                    </Stack>
            </Stack>
        </>

    );
}

export default ProductComponents
