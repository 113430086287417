import React, { useMemo, useState } from "react";
import {
  Box,
  Stack,
  Grid,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./NavBar.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { selectRole, selectRoles, setSelectedRole } from "./navBarSlice";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { Roles } from "../../models/UserPreferences";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import { getPathByRole } from "../../utils/path";

function NavBar() {
  const dispatch = useDispatch();
  const currentContext = useSelector(selectRole);
  const userRoles = useSelector(selectRoles);
  const { logout, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [anchorLogout, setAnchorLogout] = useState<any | null>(null);

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openLogout = Boolean(anchorLogout);

  const switchContext = (role: Roles) => {
    dispatch(setSelectedRole(role));
    setAnchorEl(false);
    const name = role.split(":")[1]
    navigate(getPathByRole(name))
  };

  const getRoleTitle = useMemo(() => {
    switch (currentContext) {
      case "manage:designer":
        return "Designer";
      case "manage:manufacturer":
        return "Manufacturer";
      case "manage:admin":
        return "Broker";
      default:
        return currentContext;
    }
  }, [currentContext]);

  const getSwitchToRoleTitle = (role: string) => {
    switch (role) {
      case "manage:designer":
        return "Designer";
      case "manage:manufacturer":
        return "Manufacturer";
      case "manage:admin":
        return "Broker";
      default:
        return `Broker`;
    }
  };

  return (
    <div>
      <Box
        sx={{
          bgcolor: "secondary.dark",
          overflow: "hidden",
          borderRadius: "0px",
          boxShadow: "0px 3px 6px black",
          fontWeight: "bold",
          height: "36px",
          textAlign: "right",
          justifyContent: "center",
          display: "flex",
          padding: "8px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs>
            <Stack
              direction="row"
              spacing={10}
              alignItems="center"
              justifyContent="flex-start"
              sx={{ ml: 10 }}
            >
              <Stack direction="row" spacing={2}>
                <img
                  src="/portal-logo.png"
                  alt="Portal logo"
                  style={{ height: "25px" }}
                />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="white"
                  sx={{ cursor: "default" }}
                >
                  R3 Portal
                </Typography>
              </Stack>
              {currentContext && userRoles.length > 1 ? (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      backgroundColor: anchorEl
                        ? "rgba(255,255,255,0.1)"
                        : "secondary.dark",
                      cursor: "pointer",
                      p: 1,
                      borderRadius: "10px",
                      ":hover": { backgroundColor: "rgba(255,255,255,0.1)" },
                    }}
                    alignItems="center"
                    onClick={handleClick}
                  >
                    <Typography
                      fontWeight="light"
                      color="secondary.contrastText"
                      variant="subtitle2"
                    >
                      {getRoleTitle}
                    </Typography>
                    <ArrowDropDownIcon sx={{ color: "white" }} />
                  </Stack>

                  <Popover
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{ width: "100%" }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                  >
                    <List>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, mb: 1, textAlign: "center" }}
                      >
                        Switch role
                      </Typography>
                      <Box sx={{ borderBottom: 1 }}></Box>
                      {userRoles.length > 1 && (
                        <>
                          {(
                            [
                              "manage:designer",
                              "manage:manufacturer",
                              "manage:admin",
                            ] as Roles[]
                          ).map((role) => {
                            return (
                              userRoles?.includes(role) && (
                                <ListItem
                                  disablePadding
                                  sx={{ width: "200px" }}
                                >
                                  <ListItemButton
                                    onClick={() => switchContext(role)}
                                  >
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color:
                                              currentContext === role
                                                ? "secondary.contrastText"
                                                : "black",
                                          }}
                                        >
                                          {getSwitchToRoleTitle(role)}
                                        </Typography>
                                      }
                                    />
                                    {currentContext === role && (
                                      <ListItemIcon>
                                        <CheckIcon
                                          sx={{
                                            color: "secondary.contrastText",
                                            ml: 5,
                                          }}
                                        />
                                      </ListItemIcon>
                                    )}
                                  </ListItemButton>
                                </ListItem>
                              )
                            );
                          })}
                        </>
                      )}
                    </List>
                  </Popover>
                </>
              ) : (
                <Typography
                  fontWeight="light"
                  color={anchorEl ? "white" : "secondary.contrastText"}
                  variant="subtitle2"
                >
                  {getRoleTitle}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs>
            {isAuthenticated && (
              <Stack direction="row" spacing={2} sx={{ float: "right", mr: 2 }}>
                <Typography
                  color="white"
                  variant="subtitle2"
                  fontWeight="normal"
                >
                  {user?.email}
                </Typography>
                <AccountCircleIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={(event: any) => setAnchorLogout(event.currentTarget)}
                />
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{ width: "100%" }}
                  open={openLogout}
                  anchorEl={anchorLogout}
                  onClose={() => setAnchorLogout(null)}
                >
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() =>
                          logout({
                            returnTo: process.env.REACT_APP_FRONTEND_URI,
                          })
                        }
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Log out</Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Popover>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default NavBar;
