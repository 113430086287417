import { createTheme } from '@mui/material/styles';


const AppTheme = createTheme({
    typography: {
      fontFamily: [
        'Roboto Mono',
        'sans-serif'
      ].join(','),
    },
    palette: {
      primary: {
        main: '#00A5D8',
        light: '#8ad4eb',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#454A4D',
        dark: '#30373A',
        light: '#c5c6c7',
        contrastText: '#00A5D8'
      },
    }
  });

  export default AppTheme;
  
