import { configureStore } from "@reduxjs/toolkit";
import navBarReducer from '../components/NavBar/navBarSlice'
import alertReducer from '../components/Alert/alertSlice'
import productsReducer from '../components/ProductWindow/ProductsSlice'


const store = configureStore({
  reducer: {
    navBar: navBarReducer,
    alert: alertReducer,
    productPage: productsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
