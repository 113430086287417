import React from "react";
import {
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import NumberOfEntitiesCard from "../../../components/NumberOfEntitiesCard/NumberOfEntitiesCard";
import Carousel from "../../../components/Carousel/Carousel";
import { getAllCQAReports, getManufacturers } from "../../../api/ManufacturerQueries";
import CQAReportsOverTimeGraph from "../../../components/Graphs/CQAReportsOverTimeGraph";


function ManufacturersDashboardPage() {

    const getServicesWithCapacity = async (accessToken: string) => {
        return await getManufacturers(accessToken)
    }

    return (
        <Stack direction="column" alignItems="center">
            <Typography variant="h5" textAlign="center" sx={{ pt: 5 }}>
                Manufacturers Dashboard
            </Typography>
            <Grid container spacing={2} sx={{ p: 5 }}>
                <Grid item md={4}>
                    <NumberOfEntitiesCard
                        entitiesName="Manufacturers"
                        queryToGetEntities={getManufacturers}
                        detailsLinkURL="/broker-manufacturers"
                    />
                </Grid>
                <Grid item md={4}>
                    <NumberOfEntitiesCard
                        entitiesName="CQA Reports"
                        queryToGetEntities={getAllCQAReports}
                        detailsLinkURL="/broker-reports"
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" textAlign="center" sx={{ p: 2 }}>
                Graphs
            </Typography>
            <Carousel components={[
                <CQAReportsOverTimeGraph />
            ]} />
        </Stack>
    );
}

export default ManufacturersDashboardPage
