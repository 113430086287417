import { Controller } from "react-hook-form";
import { Checkbox, FormControl, FormHelperText, FormLabel, Stack, SxProps } from "@mui/material";
import { ChangeEvent, useMemo } from "react";

interface ComponentSx {
    parentStack?: SxProps
    label?: SxProps
    formControl?: SxProps
    checkbox?: SxProps
    helper?: SxProps
}

interface FormInputCheckbox {
    childrenSx?: ComponentSx;
    name: string;
    control: any;
    label: string;
}

export const FormInputCheckbox = ({ childrenSx, name, control, label }: FormInputCheckbox) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        return event.target.checked;
    };

    const labelStruct = useMemo(() => ({ inputProps: { 'aria-label': label } }), [label]);

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
            }) => (
                <Stack sx={childrenSx?.parentStack} direction="row" alignItems='center'>
                    <FormLabel sx={childrenSx?.label} component="legend">{label}</FormLabel>
                    <FormControl sx={childrenSx?.formControl} component="fieldset" >
                        <Checkbox
                            sx={childrenSx?.checkbox}
                            checked={value}
                            onChange={(event) => onChange(handleChange(event))}
                            onBlur={onBlur}
                            {...labelStruct}
                        />
                        <FormHelperText sx={childrenSx?.helper}>
                            {error?.message ?? ''}
                        </FormHelperText>
                    </FormControl>
                </Stack>

            )}
        />
    );
};
