import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertState, closeAlert } from "./alertSlice";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';

const AlertWithProps = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Alert() {
    const dispatch = useDispatch()
    const state = useSelector(alertState)

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        dispatch(closeAlert())
    };


    return (
        <Snackbar
            open={state.open}
            autoHideDuration={state.duration}
            onClose={handleAlertClose}
        >
            <AlertWithProps severity={state.severity}>
                {state.message}
            </AlertWithProps>
        </Snackbar>
    );
}

export default Alert