import { LoadingButton } from "@mui/lab"
import { Stack } from "@mui/material"

interface RunReviewProps {
  loading: boolean
  review: () => void
  reject: () => void
}

const RunReview = ({ loading, review, reject }: RunReviewProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <LoadingButton loading={loading} variant="contained" size="small" onClick={review}>Reserve</LoadingButton>
      <LoadingButton loading={loading} variant="contained" color="error" size="small" onClick={reject}>Reject</LoadingButton>
    </Stack>
  )
}

export default RunReview
