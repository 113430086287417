import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllProducts } from "../../api/ProductQueries";
import { Product } from "../../models/Product";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function ProductTypesGraphs() {
  const { getAccessTokenSilently } = useAuth0();
  const [numberOfGenbankProducts, setNumberOfGenbankProducts] = useState(0)
  const [numberOfComponentProducts, setNumberOfComponentProducts] = useState(0)

  const getProducts = async () => {
    const accessToken = await getAccessTokenSilently()
    const products = await getAllProducts(accessToken)
    if (products) {
      parseProducts(products)
    }
  }

  const parseProducts = (products: Product[]) => {
    let genbankCount = 0
    let componentsCount = 0
    products.forEach((product) => {
      if (product.fullSequence) {
        genbankCount += 1
      } else {
        componentsCount += 1
      }
    })
    setNumberOfGenbankProducts(genbankCount)
    setNumberOfComponentProducts(componentsCount)
  }

  useEffect(() => {
    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Product types',
      },
    },
  };

  const data = {
    labels: ['Genbank products', 'Component products'],
    datasets: [
      {
        label: 'Product types',
        data: [numberOfGenbankProducts, numberOfComponentProducts],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }


  return (
    <Doughnut data={data} options={options}/>
  )
}

export default ProductTypesGraphs
